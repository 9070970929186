import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../Tabs";
import language from "../../../../language";
import { withRouter } from "../../../../utils/withRouter";
import { getStorage } from "../../../../utils/storage";
import accessMenu from "../../../../utils/accessMenu";
import ReceptionSingle from "./ReceptionSingle";
import ReceptionBulk from "./ReceptionBulk";
import CheckboxQuestionnaire from "../../../Input/CheckboxQuestionnaire";

class Reception extends React.Component {
  state = {
    user: {},
    data: [],
    type: "bulk",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    return (
      <div className="card-body">
        <CheckboxQuestionnaire
          answers={[
            {
              key: "type",
              value: "bulk",
              onPress: () => this.handleCheck("type", "bulk"),
            },
            {
              key: "type",
              value: "product",
              onPress: () => this.handleCheck("type", "product"),
            },
          ]}
          checked={this.state.type}
        />
        {this.state.type === "bulk" && (
          <ReceptionBulk
            shipmentNumber={this.props.shipmentNumber}
            getData={this.props.getData}
          />
        )}
        {this.state.type === "product" && <ReceptionSingle />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(Reception));
