import React, { Component } from "react";
import language from "../../../../language";
import { connect } from "react-redux";
import { Button } from "../../../Button";
import api from "../../../../utils/api";
import toastMessage from "../../../../utils/toastMessage";
import { Loading } from "../../../Loader";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import { getStorage } from "../../../../utils/storage";
import { Input } from "../../../Input";

class OtherTest extends Component {
  state = {
    error: {},
    requiredFields: [],
    data: {},
    user: {},
    result: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    if (this.props._id) {
      this.setState({
        ...this.props,
      });
    }
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    let inputValue = e && e?.target ? e.target.value : e;

    // Remove field error
    delete error[field];
    this.setState({ [field]: inputValue, error });
  };

  validateForm = () => {
    const { error, result } = this.state;

    if (result === "") {
      error.result = language[this.props.defaultLanguage].result_required;
    }

    this.setState({ error });
  };

  onSubmit = async () => {
    try {
      await this.validateForm();

      const { error, result } = this.state;
      const { requestId, patientId, _id, exam } = this.props;

      if (Object.keys(error).length > 0) return;

      this.setState({ isSubmitting: true });

      let method = "POST",
        requestBody = {
          request: requestId,
          patient: patientId,
          type: exam,
          result,
        };

      if (_id) {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/sample-collected-result`,
        data: requestBody,
      };

      await api(options);
      this.setState({
        isSubmitting: false,
      });
      toastMessage(
        "success",
        language[this.props.defaultLanguage][
          method === "PUT" ? "update_data_success" : "add_data_success"
        ]
      );

      this.props.handleCloseModal();
      this.props.getData(true);
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };
  render() {
    const canCreateSampleCollection = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "read,create,update",
    });

    return (
      <div>
        {this.state.isLoading ? (
          <center>
            <Loading />
          </center>
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <Input
                  label={language[this.props.defaultLanguage].exam}
                  placeholder={
                    language[this.props.defaultLanguage].exam_placeholder
                  }
                  required
                  value={this.props.exam}
                />
              </div>
              <div className="col-md-12">
                <Input
                  label={language[this.props.defaultLanguage].result}
                  placeholder={
                    language[this.props.defaultLanguage].result_placeholder
                  }
                  required
                  value={this.state.result}
                  error={this.state.error.result}
                  onChange={(e) => this.onChangeText("result", e)}
                />
              </div>
            </div>
            {canCreateSampleCollection && (
              <div className="d-flex">
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(OtherTest);
