import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import api from "../../../utils/api";
import Input from "../../Input/Input.js";
import Button from "../../Button/Button.js";
import Modal from "../../Modal/Modal.js";
import { getStorage } from "../../../utils/storage.js";
import { fetchCurrentInventoriesPerProductType } from "../../../utils/queries/production/inventoryQuery.js";
import Products from "../requestForm/product/Products.js";
import icons from "../../../constants/icons.js";
import RespondRequest from "../requestForm/respondRequest.js";
import Select from "../../Input/Select.js";
import bloodGroups from "../../../constants/bloodGroups.js";
import Table from "../../Table/Table.js";
import CardHeader from "../../Header/CardHeader.js";
import formatSelectData from "../../../utils/formatSelectData.js";

let copyData = [];

class CurrentStock extends React.Component {
  state = {
    error: {},
    data: [],
    csvData: [],
    packages: [],
    user: {},
    error: {},
    productInStock: [],
    requestStock: "",
    currentStock: "",
    data: [],
    modifieProductIndex: null,
    productTypes: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
    this.getCurrentStock(true);
  };

  getCurrentStock = async (isLoading) => {
    try {
      this.setState({
        isLoading,
      });

      const { data } = await fetchCurrentInventoriesPerProductType(
        this.props.defaultLanguage,
        {
          productTypes: this.props.productType,
        }
      );

      let productInStock = [],
        uniqueName = [],
        _data = [],
        productTypes = [],
        productTypeIds = [];

      data.forEach((el) => {
        if (!productTypeIds.includes(el.productType._id)) {
          productTypes.push({
            label: el.productType.name,
            value: el.productType._id,
          });
          productTypeIds.push(el.productType._id);
        }

        let diff = el.control.maxStock - el.currentStock;
        if (el.control.maxStock < el.currentStock) {
          diff = 0;
        }

        _data.push({
          ...el,
          diff,
        });

        if (!uniqueName.includes(el.productType.code)) {
          productInStock.push({
            ...el,
            label: el.productType.code + " " + el.productType.name,
            value: el.productType._id,
            diff,
          });
        }

        uniqueName.push(el.productType.code);
      });

      copyData = _data.slice(0);

      this.setState({
        productInStock,
        // data: _data,
        isLoading: false,
        productTypes,
      });
    } catch (error) {
      this.setState({ isLoading: false });

      toastMessage("error", error);
    }
  };

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(field, e) {
    // Destructure state variables
    const { error, data: currentData, needproductType = [] } = this.state;

    // Remove any existing error for the field
    const updatedError = { ...error };
    delete updatedError[field];

    // Get the new value from the event
    const value = e.target ? e.target.value : e;

    // Update the state with the new value and cleared error
    this.setState({ [field]: value, error: updatedError }, () => {
      // Trigger search if the field is "productType" or "bloodGroup"
      if (field === "productType" || field === "bloodGroup") {
        this.handleSearch();
      }
    });

    // Handle "needproductType" field separately
    if (field === "needproductType") {
      // Extract product type IDs from the selected options
      const prdTypeIds = e.map((protype) => protype.value);

      // Find removed product types
      const removedType = needproductType.filter((el) => !e.includes(el));

      // Create a new array for updated data
      let updatedData = [...currentData];

      // Add new items to the data array
      for (let el of copyData) {
        const index = updatedData.findIndex((pt) => pt._id === el._id);

        if (prdTypeIds.includes(el.productType._id) && index === -1) {
          updatedData = [...updatedData, el]; // Add new item immutably
        }
      }

      // Remove items from the data array
      // updatedData = updatedData.filter(
      //   (el) => !removedType.some((rt) => rt.value === el.productType._id)
      // );

      // Update the state with the new data
      this.setState({ data: updatedData });
    }
  }

  handleSearch() {
    let { data, bloodGroup, productType } = this.state;

    if (!bloodGroup) return;
    if (!productType) return;

    let currentStock = "",
      requestStock = "",
      modifieProductIndex;

    for (let [i, el] of data.entries()) {
      if (
        el.productType._id === productType.value &&
        el.bloodGroup === bloodGroup.value
      ) {
        currentStock = el.currentStock;
        requestStock = el.diff;
        modifieProductIndex = i;

        break;
      }
    }

    this.setState({
      currentStock,
      requestStock,
      modifieProductIndex,
    });
  }

  onSubmit = async () => {
    if (window.confirm("Are you sure, want to confirm?")) {
      this.setState({ isConfirming: true });

      let count = 0,
        countSuccess = 0,
        countFailed = 0,
        errorMessage = "";

      for (let el of this.state.data) {
        const { success, failed, error } = await this.handleSubmit(el);
        count += 1;

        if (success) {
          countSuccess += 1;
        }

        if (failed) {
          countFailed += 1;
        }

        errorMessage = error;
      }

      if (count === this.state.data.length) {
        this.setState({ isConfirming: false });
        this.handleCloseModal("showToConfirm");

        if (countSuccess > 0) {
          toastMessage(
            "success",
            countSuccess +
              " " +
              language[this.props.defaultLanguage].add_data_success
          );

          await this.handleNotifyReceiver();

          // window.location.reload();
        }

        if (countFailed > 0) {
          toastMessage("error", countFailed + " " + errorMessage);
        }
      }
    }
  };

  handleSubmit = async (el) => {
    try {
      let requiredBody = {},
        method = "POST";

      requiredBody = {
        type: "bulk",
        request: this.props.request,
        productType: el.productType._id,
        numberOfPackages: el.diff,
        bloodGroup: el.bloodGroup,
        currentStock: el.currentStock,
        toBeRequested: el.diff,
      };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/product-request`,
        data: requiredBody,
      };

      await api(options);

      this.setState({ isSubmitting: false });

      return {
        success: true,
        failed: false,
      };
    } catch (error) {
      this.setState({ isSubmitting: false });
      console.log(error);

      return {
        failed: true,
        success: false,
        error: error?.response?.data?.error,
      };
    }
  };

  handleNotifyReceiver = async () => {
    try {
      let method = "POST";

      this.setState({
        isSendingReminder: true,
      });

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/bloodRequest/notification`,
        data: {
          requestNo: this.props.requestInfo?.requestNo,
          origin: this.props.requestInfo?.hospital?.name,
        },
      };

      await api(options);

      this.setState({
        isSendingReminder: false,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].notification_send_success
      );
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isSendingReminder: false,
      });
    }
  };

  onModifieProduct = async () => {
    this.setState({ isModifying: true });
    const { modifieProductIndex, data, requestStock } = this.state;

    data[modifieProductIndex].diff = requestStock;

    this.setState(
      {
        data,
        requestStock: "",
        currentStock: "",
        productType: null,
        bloodGroup: null,
        isModifying: false,
      },
      () => {
        toastMessage("success", "Stock updated");
      }
    );
  };

  handleRemove(index) {
    const { data } = this.state;

    data.splice(index, 1);

    this.setState(
      {
        data,
        requestStock: "",
        currentStock: "",
        productType: null,
        bloodGroup: null,
        isModifying: false,
      },
      () => {
        toastMessage("success", "Stock removed");
      }
    );
  }

  render() {
    return (
      <>
        <div className="card" style={{ overflowY: "auto", maxHeight: "100vh" }}>
          <div className="card-header">
            <div className="d-flex gap-2">
              {this.props.status === "pending" &&
                this.state.user.stock === this.props.stock && (
                  <>
                    <Button
                      text={
                        language[this.props.defaultLanguage].request_product
                      }
                      isSubmitting={this.state.isSubmitting}
                      onPress={() => this.handleShowModal("showToConfirm")}
                      icon={icons.add}
                    />
                    <Button
                      text={language[this.props.defaultLanguage].send_reminder}
                      isSubmitting={this.state.isSendingReminder}
                      onPress={() => this.handleNotifyReceiver()}
                      icon={icons.send}
                      className="btn-bordered btn-transparent"
                    />
                  </>
                )}
              {/* <Button
                text={
                  language[this.props.defaultLanguage].view_requested_products
                }
                isSubmitting={this.state.isSubmitting}
                onPress={() => this.handleShowModal("showRequestedProduct")}
                className="btn-transparent btn-bordered"
              /> */}

              {this.props.bloodBank?._id === this.state.user.stock && (
                <Button
                  text={language[this.props.defaultLanguage].add_shipment}
                  onPress={() => this.handleShowModal("showAddShipment")}
                  icon={icons.add}
                />
              )}

              {/* {this.props.status === "pending" &&
                this.state.user.stock === this.props.stock && (
                  <Button
                    text={language[this.props.defaultLanguage].request_product}
                    isSubmitting={this.state.isSubmitting}
                    onPress={() => this.handleShowModal("showToConfirm")}
                    icon={icons.add}
                  />
                )} */}
            </div>
          </div>
          <div className="card-body">
            <Products
              request={this.props.request}
              status={this.props.status}
              stock={this.props.stock}
              type={this.props.type}
            />
          </div>
        </div>
        <Modal
          show={this.state.showToConfirm}
          title={language[this.props.defaultLanguage].confirm_request}
          handleClose={() => this.handleCloseModal("showToConfirm")}
          size="xl"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                  <CardHeader title="Current Stock" />
                  {/* <br />
                  <Button
                    className="btn-default"
                    text={language[this.props.defaultLanguage].reset}
                    onPress={() => this.getCurrentStock(true)}
                    isLoading={this.state.isLoading}
                  /> */}
                  <div className="card-body">
                    <Select
                      label={
                        language[this.props.defaultLanguage].choose_product_type
                      }
                      options={this.state.productTypes}
                      onChange={(e) => this.onChangeText("needproductType", e)}
                      value={this.state.needproductType}
                      isMulti
                    />
                    <Table
                      data={this.state.data}
                      headers={[
                        {
                          title: language[this.props.defaultLanguage].actions,
                          key: "multiple-actions",
                          actions: [
                            {
                              icon: icons.delete,
                              title:
                                language[this.props.defaultLanguage].action,
                              onPress: (item) => {
                                const index = copyData.findIndex(
                                  (el) => el._id === item._id
                                );
                                this.handleRemove(index);
                              },
                            },
                            {
                              icon: icons.eye_on,
                              title:
                                language[this.props.defaultLanguage].action,
                              onPress: (item) => {
                                this.setState(
                                  {
                                    currentStock: item.currentStock,
                                    bloodGroup: {
                                      label: item.bloodGroup,
                                      value: item.bloodGroup,
                                    },
                                    productType: {
                                      label:
                                        item.productType.code +
                                        " " +
                                        item.productType.name,
                                      value: item.productType._id,
                                      currentStock: item.currentStock,
                                    },
                                  },
                                  () => {
                                    this.handleSearch();
                                  }
                                );
                              },
                            },
                          ],
                        },
                        {
                          title: language[this.props.defaultLanguage].code,
                          key: "productType.code",
                          styles: {
                            textTransform: "uppercase",
                          },
                        },
                        {
                          title: language[this.props.defaultLanguage].product,
                          key: "productType.name",
                          styles: {
                            textTransform: "uppercase",
                          },
                        },
                        {
                          title:
                            language[this.props.defaultLanguage].blood_group,
                          key: "bloodGroup",
                          styles: {
                            textTransform: "uppercase",
                          },
                        },
                        {
                          title:
                            language[this.props.defaultLanguage].current_stock,
                          key: "currentStock",
                        },
                        {
                          title:
                            language[this.props.defaultLanguage]
                              .to_be_requested,
                          key: "diff",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <CardHeader title="Modifie Stock" />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Select
                          options={this.state.productInStock}
                          label={
                            language[this.props.defaultLanguage].product_type
                          }
                          placeholder={
                            language[this.props.defaultLanguage].select
                          }
                          value={this.state.productType}
                          onChange={(e) => this.onChangeText("productType", e)}
                          error={this.state.error.productType}
                        />
                      </div>
                      <div className="col-md-12">
                        <Select
                          options={bloodGroups}
                          label={
                            language[this.props.defaultLanguage].blood_group
                          }
                          placeholder={
                            language[this.props.defaultLanguage].select
                          }
                          value={this.state.bloodGroup}
                          onChange={(e) => this.onChangeText("bloodGroup", e)}
                          error={this.state.error.bloodGroup}
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          label={
                            language[this.props.defaultLanguage].current_stock
                          }
                          placeholder={
                            language[this.props.defaultLanguage].current_stock
                          }
                          value={this.state?.productType?.currentStock}
                          onChange={(e) => this.onChangeText("currentStock", e)}
                          error={this.state.error.currentStock}
                          disabled
                          type="number"
                        />
                      </div>
                      <div className="col-md-12">
                        <Input
                          label={
                            language[this.props.defaultLanguage].request_stock
                          }
                          placeholder={
                            language[this.props.defaultLanguage].request_stock
                          }
                          value={this.state?.requestStock}
                          onChange={(e) => this.onChangeText("requestStock", e)}
                          error={this.state.error.requestStock}
                          type="number"
                        />
                      </div>

                      <div className="modal-footer">
                        <Button
                          text={language[this.props.defaultLanguage].delete}
                          onPress={() =>
                            this.handleRemove(this.state.modifieProductIndex)
                          }
                          className="btn-default"
                        />
                        <Button
                          text={language[this.props.defaultLanguage].update}
                          onPress={() => this.onModifieProduct()}
                          isSubmitting={this.state.isModifying}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].close}
              onPress={() => this.handleCloseModal("showToConfirm")}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].confirm}
              onPress={() => this.onSubmit()}
              isSubmitting={this.state.isConfirming}
            />
          </div>
        </Modal>

        <Modal
          show={this.state.showAddShipment}
          title={language[this.props.defaultLanguage].shipment}
          handleClose={() => this.handleCloseModal("showAddShipment")}
          size="lg"
        >
          <div className="card-body">
            {this.props?.requestInfo?._id && (
              <RespondRequest
                requestInfo={this.props.requestInfo}
                requestId={this.props.requestInfo?._id}
                requestNo={this.props.requestInfo?.requestNo}
                source={this.props.requestInfo?.bloodBank?._id}
                bloodBank={this.props.requestInfo?.hospital?.bloodBank}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CurrentStock);
