import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import axios from "axios";
import { Table } from "../../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { CentersFilter, DateFilter } from "../../Filters";
import handleFilters from "../../../utils/handleFilters";
import moment from "moment";
import icons from "../../../constants/icons";

let copyData = [];

class AgeGroup extends React.Component {
  state = {
    isLoading: false,
    data: [],
    csvData: [],
    totalDonors: 0,
    filters: {
      startDate: moment().startOf("D").format("YYYY-MM-DD"),
      endDate: moment().endOf("D").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    await this.getData(true, this.state.filters);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getData(true);
    }
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: "18-25",
        key: "18-25Count",
      },
      {
        title: "26-35",
        key: "26-35Count",
      },
      {
        title: "36-45",
        key: "36-45Count",
      },
      {
        title: "46-60",
        key: "46-60Count",
      },
      {
        title: "61 And Above",
        key: "61-AboveCount",
      },
      {
        title: "Unspecified",
        key: "unspecified",
      },
      {
        title: "Total",
        key: "total",
      },
    ];

    return headers;
  }

  getData = async (isLoading, search = {}) => {
    try {
      let totalDonors = 0;
      let { category, identityDocNumberType } = this.props;
      const user = await getStorage();

      this.setState({ isLoading });

      const filters = handleFilters(search);

      const params = {
        ...filters,
        category,
        identityDocNumberType,
      };

      const options = {
        url: process.env.REACT_APP_BBMS_BASE_API + "/donor/age",
        method: "get",
        params,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios(options);

      data.forEach((item) => {
        totalDonors += item.total;
      });

      this.setState({
        data,
        isLoading: false,
        totalDonors,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          fetchData={(isLoading, filters) => this.getData(isLoading, filters)}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true, this.props.filters),
            },
            {
              type: "button",
              title: "Filter",
              button_type: "dropdown",
              filterName: "date_range",
              ...this.state.filters,
            },
          ]}
        />

        <div className="alert alert-info">
          {language[this.props.defaultLanguage].total}:{" "}
          <strong>{this.state.totalDonors}</strong>{" "}
        </div>

        <CSVLink
          ref="csvDownload"
          filename={"Donor Age Group"}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(AgeGroup);
