import React from "react";
import { connect } from "react-redux";
import language from "../../../language";

import {
  fetchAndUpdateCurrentInventoriesStockStatus,
  fetchCurrentInventoriesPerProductType,
} from "../../../utils/queries/production/inventoryQuery";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import { Loading } from "../../Loader";
import CardCount from "../../CardCount/CardCount";
import CardHeader from "../../Header/CardHeader";
import "./styles.css";
import { Empty } from "../../Empty";
import { Modal } from "../../Modal";
import { Select } from "../../Input";
import { fetchBloodBanks } from "../../../utils/queries/bloodBank";
import { Button } from "../../Button";
import toastMessage from "../../../utils/toastMessage";
import ViewProducts from "./ViewProducts";

class DashboardInventories extends React.Component {
  state = {
    isLoading: false,
    data: {},
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    if (user.stock) {
      this.setState({
        stock: {
          label: user.stock,
          value: user.stock,
        },
      });
    }

    this.getData(true, {
      status: "INSTOCK",
    });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    let value = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: value,
      error,
    });

    if (field === "filterStock") {
      this.getData(true, { stock: value?.value });
    }
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchCurrentInventoriesPerProductType(
        defaultLanguage,
        search
      );

      let group = {};

      data.forEach((el) => {
        if (!group[el.productType.name]) {
          group[el.productType.name] = [];
        }

        group[el.productType.name].push(el);
      });

      this.setState({
        data: group,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleInitInventory = async () => {
    await this.validateInitInventory();
    try {
      if (Object.keys(this.state.error).length > 0) return;
      this.setState({
        isInit: true,
      });
      const { defaultLanguage } = this.props;
      const { stock } = this.state;
      await fetchAndUpdateCurrentInventoriesStockStatus(defaultLanguage, {
        stock: stock.value,
      });

      this.setState({
        isInit: false,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].initialize_inventory_success
      );
      this.getData(true);
      this.handleCloseModal("showInitModal");
      window.location.reload();
    } catch (error) {
      console.log(error);
      toastMessage("error", error);
    }
  };

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  validateInitInventory = () => {
    let { error, stock } = this.state;

    if (!stock) {
      error.stock = language[this.props.defaultLanguage].stock_required;
    }

    this.setState({ error });
  };

  getBloodBank = async (q) => {
    try {
      this.setState({
        isFetchingBloodBanks: true,
      });
      const user = await getStorage();

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        type: "dropdown",
        page: 1,
        limit: 50,
        q: q !== "" ? q : undefined,
        _id: user.stock,
      });

      this.setState({
        bloodBanks: data,
        isFetchingBloodBanks: false,
        stock:
          data.length === 1
            ? { ...data[0], label: data[0].name, value: data[0]._id }
            : null,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });

      return [];
    }
  };

  render() {
    return (
      <>
        <div className="row">
          <div style={{ display: "flex" }}>
            <div className="explain-stock-container" style={{ flex: 1 }}>
              <div className="explain-stock-item">
                <div className="color red" />
                <div>Out of stock</div>
              </div>
              <div className="explain-stock-item">
                <div className="color yellow" />
                <div>Insufficient Stock</div>
              </div>
              <div className="explain-stock-item">
                <div className="color green" />
                <div>In stock</div>
              </div>

              <div className="explain-stock-item">
                <div className="color other" />
                <div>Over loaded</div>
              </div>
            </div>

            {/* {Object.keys(this.state.data || {}).length > 0 && (
              <div>
                <Button
                  isSubmitting={this.state.isInit}
                  text={language[this.props.defaultLanguage].refresh}
                  onPress={() =>
                    this.state.user.stock
                      ? this.handleInitInventory(this.state.user.stock)
                      : this.handleShowModal("showInitModal")
                  }
                />
              </div>
            )} */}
          </div>
          <div className="col-md-12">
            <Select
              loadOptions={(e) => this.getBloodBank(e)}
              placeholder={language[this.props.defaultLanguage].select}
              label={language[this.props.defaultLanguage].filter_blood_bank}
              value={this.state.filterStock}
              onChange={(e) => this.onChangeText("filterStock", e)}
              error={this.state.error.filterStock}
            />
          </div>
        </div>
        {this.state.isLoading ? (
          <center>
            <Loading />
          </center>
        ) : Object.keys(this.state.data).length === 0 ? (
          <>
            <Empty
              title={
                language[this.props.defaultLanguage]
                  .initialize_inventory_description
              }
              actionButton={
                language[this.props.defaultLanguage].initialize_inventory
              }
              handleActionButton={() =>
                this.handleShowModal(
                  "showInitModal",
                  language[this.props.defaultLanguage].initialize_inventory
                )
              }
            />
          </>
        ) : (
          Object.keys(this.state.data).length > 0 && (
            <div>
              {Object.keys(this.state.data || {})?.map((k) => {
                return (
                  <div key={k}>
                    <div className="mb-3">
                      <CardHeader title={k} />
                    </div>
                    <div className="row">
                      {this.state.data[k]?.map((el, i) => {
                        const color =
                          el.currentStock === 0
                            ? "red"
                            : el.currentStock <= el.control?.minStock
                            ? "#f0b807"
                            : el.control?.minStock <= el.currentStock &&
                              el.control?.maxStock >= el.currentStock
                            ? "green"
                            : el.control?.maxStock <= el.currentStock &&
                              "purple";
                        return (
                          <div className="col-md-4 mb-3">
                            <CardCount
                              key={i}
                              count={el.currentStock}
                              title={`${
                                language[this.props.defaultLanguage].blood_group
                              }: ${el.bloodGroup}`}
                              descriptionStyles={{
                                fontWeight: "bold",
                              }}
                              color={color}
                              icon={icons.transfer}
                              onPress={() =>
                                this.handleShowModal(
                                  "showProductModal",
                                  el?.productType?.name + " " + el?.bloodGroup,
                                  el
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        )}

        <Modal
          show={this.state.showInitModal}
          title={this.state.modalTitle}
          handleClose={() => this.handleCloseModal("showInitModal")}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <Select
                  loadOptions={(e) => this.getBloodBank(e)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].blood_bank}
                  required
                  value={this.state.stock}
                  onChange={(e) => this.onChangeText("stock", e)}
                  error={this.state.error.stock}
                />
              </div>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].submit}
                  isSubmitting={this.state.isInit}
                  onPress={this.handleInitInventory.bind(this)}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.showProductModal}
          title={this.state.modalTitle}
          handleClose={() => this.handleCloseModal("showProductModal")}
          size="lg"
        >
          {this.state.selectedData && this.state.selectedData.stock && (
            <div
              className="card-body"
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <ViewProducts
                bloodGroup={this.state.selectedData.bloodGroup}
                productIds={[this.state.selectedData.productType._id]}
                limit={10}
              />
            </div>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(DashboardInventories);
