import React from "react";

import { Login } from "../../../components/AdminLogin";
import GuideFloatButton from "../../../components/userGuide/FloatButton";

class LoginScreen extends React.Component {
  render() {
    return (
      <>
        <Login />
        <GuideFloatButton
          title="How to login as Health facility staff"
          videoUrl="https://res.cloudinary.com/djjwt8ewl/video/upload/v1738628980/BBMS/blob_3_hitgzr.mp4"
        />
      </>
    );
  }
}

export default LoginScreen;
