import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input/index.js";
import language from "../../../language/index.js";
import icons from "../../../constants/icons.js";
import { Button } from "../../Button/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import { getStorage } from "../../../utils/storage.js";
import axios from "axios";
import ShippingQueue from "../shipping/ShippingQueue.js";
import { fetchShipmentNumber } from "../../../utils/queries/request/shipping-queue.js";
import { generateShipmentNumber } from "../../../utils/random.js";
import Products from "./product/Products.js";
import Empty from "../../Empty/Empty.js";
import Tabs from "../../Tabs/Tabs.js";

class RespondRequest extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    donationNumber: "",
    donation: {},
    inventories: [],
    sourceStocks: [],
    inventory: "",
    product: "",
    user: {},
    isLoadingShipmentNumber: true,
    requestedProductList: [],
    bloodGroup: "",
    requestedBloodGroups: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getShipmentNumber(true);
    // this.getProductRequested(true);
  };

  // getProductRequested = async (isLoadingProductRequested, request) => {
  //   try {
  //     this.setState({ isLoadingProductRequested });
  //     const { defaultLanguage } = this.props;

  //     const { data, count } = await fetchRequestedProducts(defaultLanguage, {
  //       request: this.props.requestId,
  //       page: 1,
  //       limit: 500,
  //     });

  //     let requestedProductList = [],
  //       products = [];
  //     data.forEach((el) => {
  //       let key =
  //         "(" +
  //         el.bloodGroup +
  //         ") " +
  //         el.productType.code +
  //         " " +
  //         el.productType.name;
  //       if (!products.includes(key)) {
  //         requestedProductList.push({
  //           ...el,
  //           label: key,
  //           value: el.bloodGroup + "_" + el.productType._id,
  //         });
  //         products.push(key);
  //       }
  //     });

  //     this.setState({
  //       isLoadingProductRequested: false,
  //       requestedProductList,
  //       totalPageCount: count,
  //     });
  //   } catch (error) {
  //     this.setState({ isLoadingProductRequested: false });
  //     toastMessage("error", error);
  //   }
  // };

  componentDidUpdate(prevProps) {
    if (
      prevProps.refreshShipmentNumber !== this.props.refreshShipmentNumber &&
      this.props.refreshShipmentNumber
    ) {
      this.getShipmentNumber(true);
    }
  }

  getShipmentNumber = async (isLoadingShipmentNumber) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoadingShipmentNumber });

      const data = await fetchShipmentNumber(defaultLanguage);

      this.setState({
        shipmentNumber: generateShipmentNumber(data.data),
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });
    } catch (error) {
      this.setState({
        isLoadingShipmentNumber: false,
        isSubmitting: false,
        isLoadingShipmentNumber: false,
      });

      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isSearching: false,
    });

    if (field === "productRequested") {
      let requestedBloodGroup = [];
      for (let el of this.state.requestedProductList) {
        if (inputValue.productType._id === el.productType._id) {
          requestedBloodGroup.push({
            label: el.bloodGroup,
            value: el.bloodGroup,
          });
        }
      }

      this.setState({ requestedBloodGroup });
    }
  };

  handleShowModal(modal, title, item = {}) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      ...item,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onReset() {
    this.getShipmentNumber(true);

    this.setState({
      donationNumber: "",
      donation: {},
      product: "",
    });
  }

  validateTransfer = () => {
    const { error, product, shipmentNumber, productRequested } = this.state;

    // if (!productRequested) {
    //   error.productRequested = "Product requested is required";
    // }

    if (!product) {
      error.product = "Product is required";
    }

    if (shipmentNumber === "") {
      error.shipmentNumber = "Shipment number is required";
    }

    this.setState({
      error,
    });
  };

  onSubmit = async () => {
    const { error, product, shipmentNumber, donationNumber, productRequested } =
      this.state;
    const user = await getStorage();

    const { _id, requestNo } = this.props;

    if (!this.state.donation) return;

    await this.validateTransfer();

    if (Object.keys(error).length > 0) return;

    try {
      this.setState({ isSubmitting: true, isRefreshing: false });

      let method = "POST",
        requestBody = {
          shipmentNumber,
          product,
          to: this.props.bloodBank,
          from: this.props.source,
          donationNumber,
          requestNo,
          // bloodGroup: productRequested.bloodGroup,
          // productType: productRequested.productType._id,
        };

      if (_id && _id == "") {
        method = "PUT";
        requestBody.id = _id;
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue`,
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      await axios(options);

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_data_success
      );

      this.setState({
        isSubmitting: false,
        isRefreshing: true,
        donationNumber: "",
        product: "",
      });
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isSubmitting: false,
        isRefreshing: false,
      });
    }
  };

  renderFormRespondRequest = () => {
    return (
      <form>
        <div className="card-body">
          <Input
            placeholder={
              language[this.props.defaultLanguage].shipment_number_placeholder
            }
            label={language[this.props.defaultLanguage].shipment_number}
            value={this.state.shipmentNumber}
            onChange={(e) => this.onChangeText("shipmentNumber", e)}
            error={this.state.error.shipmentNumber}
            disabled
          />
          {/* <Select
        options={this.state.requestedProductList}
        placeholder={language[this.props.defaultLanguage].select}
        label={language[this.props.defaultLanguage].requested_products}
        value={this.state.productRequested}
        onChange={(e) => this.onChangeText("productRequested", e)}
        error={this.state.error.productRequested}
      /> */}
          <Input
            placeholder={
              language[this.props.defaultLanguage].donation_number_placeholder
            }
            label={language[this.props.defaultLanguage].donation_number}
            value={this.state.donationNumber}
            onChange={(e) => this.onChangeText("donationNumber", e)}
            error={this.state.error.donationNumber}
            type="number"
          />
          <div className="col-md-12">
            <Input
              placeholder={
                language[this.props.defaultLanguage].product_placeholder
              }
              label={language[this.props.defaultLanguage].product}
              value={this.state.product}
              onChange={(e) => this.onChangeText("product", e)}
              error={this.state.error.product}
              leftIcon={icons.product}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].add}
            isSubmitting={this.state.isSubmitting}
            onPress={(e) => {
              e.preventDefault();
              this.onSubmit();
            }}
          />
        </div>
      </form>
    );
  };

  render() {
    return (
      <>
        <div className="card-body">
          <p>
            {language[this.props.defaultLanguage].requested_by}
            {": "}
            <span className="text-bold">
              {this.props?.requestInfo?.hospital?.name}
            </span>
          </p>
          <p>
            {language[this.props.defaultLanguage].date}
            {": "}
            <span className="text-bold">
              {this.props?.requestInfo?.createdAt}
            </span>
          </p>
          <p>
            {language[this.props.defaultLanguage].status}
            {": "}
            <div className={`badge badge-${this.props?.requestInfo?.status}`}>
              {this.props?.requestInfo?.status}
            </div>
          </p>{" "}
        </div>
        {/* {this.props?.requestInfo?.status === "pending" && ( */}
        <Tabs
          options={[
            this.props.requestInfo.stock !== this.state.user.stock && {
              title: "Respond Request",
              data: this.renderFormRespondRequest(),
            },
            {
              title: language[this.props.defaultLanguage].products,
              data: this.props.requestInfo ? (
                <div
                  className="card-body"
                  style={{ maxHeight: "60vh", overflowY: "auto" }}
                >
                  <Products
                    request={this.props.requestInfo._id}
                    status={this.props.requestInfo.status}
                    stock={this.props.requestInfo.stock}
                    type={this.props.requestInfo.type}
                  />
                </div>
              ) : (
                <Empty title={language[this.props.defaultLanguage].no_data} />
              ),
            },
          ].filter(Boolean)}
        />
        {/* )} */}
        <ShippingQueue
          shipmentNumber={this.state.shipmentNumber}
          isRefreshing={this.state.isRefreshing}
          addedBy={this.state?.user?._id}
          status="pending"
          requestNo={this.props.requestNo}
          requestInfo={this.props.requestInfo}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { refreshShipmentNumber } = state.ShipmentNumber;
  return {
    defaultLanguage,
    refreshShipmentNumber,
  };
};

export default connect(mapStateToProps)(RespondRequest);
