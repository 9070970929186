import React from "react";
import { Tabs } from "../../components/Tabs";
import { connect } from "react-redux";
import language from "../../language";
import { MyProfile, ChangePassword } from "../../components/Profile";
import ListMessages from "../../components/Template/Message/ListMessages";
import GeneralListConfigurations from "../../components/Configuration/general/ListConfigurations";
import ReportListConfigurations from "../../components/Configuration/report/ListConfigurations";
import StockAccess from "../../components/Profile/StockAccess";
import accessMenu from "../../utils/accessMenu";
import { TwoFAGoogleAuth } from "../../components/2faGoogleAuth";

class SettingScreen extends React.Component {
  render() {
    const { my_profile, change_password } =
      language[this.props.defaultLanguage];

    const { canSwitchInventory } = accessMenu();
    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Tabs
              options={[
                {
                  title: my_profile,
                  data: (
                    <>
                      <MyProfile />
                      <br />
                      {canSwitchInventory && <StockAccess />}
                    </>
                  ),
                },
                {
                  title:
                    language[this.props.defaultLanguage].twofa_authenticator,
                  data: <TwoFAGoogleAuth />,
                },
                {
                  title: language[this.props.defaultLanguage].message_template,
                  data: <ListMessages />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].report_configurations,
                  data: <ReportListConfigurations />,
                },
                {
                  title:
                    language[this.props.defaultLanguage].general_configurations,
                  data: <GeneralListConfigurations />,
                },
                // {
                //   title:
                //     language[this.props.defaultLanguage].roles_and_permissions,
                //   data: <>Working in progress...</>,
                // },
                // {
                //   title:
                //     language[this.props.defaultLanguage].two_factor_tab_name,
                //   data: (
                //     <>
                //       <TwoFactorAuthentication />
                //     </>
                //   ),
                // },
                { title: change_password, data: <ChangePassword /> },
              ]}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SettingScreen);
