import Row from "./Row";

const TableData = ({
  data,
  headers,
  rowPress,
  actions,
  draggable,
  children,
  collapsing,
}) => {
  return (
    <>
      {data &&
        data.map((item, i) => {
          return (
            <>
              <Row
                key={i}
                keyRowIndex={i}
                item={item}
                draggable={draggable}
                headers={headers}
                rowPress={rowPress}
                actions={actions}
                count={i + 1}
              />
              {collapsing === item && children}
            </>
          );
        })}
    </>
  );
};

export default TableData;
