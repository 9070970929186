import toastMessage from "../../../toastMessage";
import { getStorage } from "../../../storage";
import language from "../../../../language";
import { defineRoleCenterSite } from "../../../handleAccessRoles";
import api from "../../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchInventoryControlsData = async (
  lng = "english",
  { page, limit, type, q, bloodGroup, bloodBank }
) => {
  try {
    const user = await getStorage();

    const { stock } = await defineRoleCenterSite();

    let query = {
      page: page,
      limit: limit,
      bloodBank: stock,
      bloodGroup,
    };

    if (q) {
      query.q = q;
    }

    if (bloodBank) {
      query.bloodBank = bloodBank;
    }

    if (query.count) {
      delete query.page;
      delete query.limit;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/inventory-controls`,
      params: query,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: el.productType.name,
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteInventoryControl = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/inventory-controls`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
