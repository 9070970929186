import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { getStorage } from "../../../utils/storage.js";
import toastMessage from "../../../utils/toastMessage.js";

import { fetchShippingInQueueData } from "../../../utils/queries/request/shipping-queue.js";
import { Modal } from "../../Modal/index.js";
import Reception from "./receipion/index.js";
class TransferredProducts extends React.Component {
  state = {
    isLoading: false,
    data: [],
    selected_data: {},
    error: {},
    csvData: [],
    delete_password: "",
    user: {},
    page: 1,
    limit: 500,
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading) => {
    try {
      this.setState({ isLoading });
      const { shipmentNumber, defaultLanguage } = this.props;
      console.log({ shipmentNumber });

      const { data, count } = await fetchShippingInQueueData(defaultLanguage, {
        shipmentNumber,
        page: this.state.page,
        limit: this.state.limit,
      });

      this.setState({ isLoading: false, data, totalPageCount: count });
    } catch (error) {
      this.setState({ isLoading: false });
      toastMessage("error", error);
    }
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "product",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "inventory.bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "from.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "to.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      // {
      //   title: language[this.props.defaultLanguage].actions,
      //   key: "multiple-actions",
      //   actions: [
      //     {
      //       icon: icons.check,
      //       title: language[this.props.defaultLanguage].receive,
      //       onPress: (item) => console.log(item),
      //       className: "btn-primary",
      //     },
      //     {
      //       icon: icons.return,
      //       title: language[this.props.defaultLanguage].return,
      //       onPress: (item) => console.log(item),
      //       className: "btn-default",
      //     },
      //   ],
      // },
    ].filter(Boolean);

    return headers;
  }

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          showAdd
          addButtonText={language[this.props.defaultLanguage].reception}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].reception
          )}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <Reception
            shipmentNumber={this.props.shipmentNumber}
            getData={() => this.getData(true)}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TransferredProducts);
