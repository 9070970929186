import React, { Component } from "react";
import { Input, Switch } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";
import { Table } from "../../../Table";
import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import {
  deleteRequestedProducts,
  fetchRequestedProducts,
} from "../../../../utils/queries/request-product";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { Loading } from "../../../Loader";
import { fetchInventories } from "../../../../utils/queries/production/inventoryQuery";
import { Modal, DeleteModal } from "../../../Modal";
import { CardHeader } from "../../../Header";
import PrintableLabel from "../../../Production/ComponentLabeling/printableLabel";
import api from "../../../../utils/api";
import ApproverABO from "../Approver";
import { defineRole } from "../../../../utils/handleAccessRoles";
import {
  ROLE_SAMPLE_COLLECTED_RESULT,
  ROLE_LABORATORY,
} from "../../../../constants/ROLES";
import { Button } from "../../../Button";
import Forward from "../forward";
import ShippingQueue from "../../shipping/ShippingQueue";
import { fetchShipmentNumber } from "../../../../utils/queries/request/shipping-queue";
import { generateShipmentNumber } from "../../../../utils/random";
import axios from "axios";
import ShippingInQueue from "../../shipping/ShippingInQueue";

class Products extends Component {
  state = {
    error: {},
    donationNumber: "",
    product: "",
    user: {},
    products: [],
    inventoryInfo: {},
    selectedData: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    if (this.props.requestId) {
      this.getProducts(true);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sampleCollected !== this.props.sampleCollected) {
      this.setState({ sampleCollected: this.props.sampleCollected });
    }
  }

  getProducts = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const { defaultLanguage, requestId } = this.props;

      const { data } = await fetchRequestedProducts(defaultLanguage, {
        page: 1,
        limit: 50,
        request: requestId,
      });

      this.setState({
        isLoading: false,
        products: data,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    let inputValue = e && e?.target ? e.target.value : e;

    delete error[field];
    delete error["errorMessage"];

    this.setState({ error: error, [field]: inputValue });
  };

  validateForm() {
    let { error, donationNumber, product, sampleCollected } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (product === "") {
      error.product = language[this.props.defaultLanguage].product_required;
    }

    this.setState({ error });
  }

  getInventory = async () => {
    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length > 0) return;

      this.setState({ isFetchingInventory: true });

      const { defaultLanguage } = this.props;
      const { product, donationNumber, sampleCollected } = this.state;

      const donation = await bloodDonationQuery(defaultLanguage, {
        donationNumber,
      });

      if (donation?.length === 0) {
        this.setState({
          isFetchingInventory: false,
          isCompatibleWithPatient: false,
        });
        toastMessage("error", language[defaultLanguage].no_data);

        return;
      }

      const { data } = await fetchInventories(defaultLanguage, {
        code: product,
        donation: donation[0]._id,
        status: "labelled",
      });

      if (data?.length === 0) {
        this.setState({
          isFetchingInventory: false,
          isCompatibleWithPatient: false,
        });
        toastMessage("error", language[defaultLanguage].no_data_found);

        return;
      }

      let inventoryInfo = {};

      if (data.length > 0) {
        inventoryInfo = data[0];

        this.onServeProduct(inventoryInfo._id);
      }
      this.setState({
        isFetchingInventory: false,
        inventoryInfo,
        isCompatibleWithPatient: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isFetchingInventory: false,
        isCompatibleWithPatient: false,
      });
      toastMessage("error", error);
    }
  };

  onCheck = async (field) => {
    await this.validateForm();

    if (Object.keys(this.state.error).length > 0) return;

    this.setState(
      {
        [field]: !this.state[field],
      },
      () => {
        if (this.state[field]) {
          this.getInventory();
        }
      }
    );
  };

  renderProductHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "inventory.donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "inventory.code",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].number_of_packages,
        key: "numberOfPackages",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];
    return headers;
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false, isCompatibleWithPatient: false });
  }

  handleOpenModal(modal, title, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle: title,
      selectedData: selectedData,
    });
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteRequestedProducts(this.props.defaultLanguage, {
        id: this.state.selectedData._id,
      });

      this.setState({ isDeleting: false });

      this.getProducts(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  onServeProduct = async (inventoryId) => {
    try {
      const { requestId, patientId } = this.props;
      this.setState({ isSubmitting: true });
      let method = "PUT",
        requestBody = {
          inventory: inventoryId,
          request: requestId,
          patient: patientId,
        };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory/patient`,
        data: requestBody,
      };
      await api(options);
      this.setState({
        isSubmitting: false,
        donationNumber: "",
        product: "",
      });

      this.getProducts();
      this.handleOpenModal("showLabelModal");
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  handleShowModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  // <ShippingQueue
  //               shipmentNumber={this.props?.shipmentNumber}
  //               isRefreshing={this.state.isRefreshing}
  //               addedBy={this.state?.user?._id}
  //               status="pending"
  //             />

  getShipmentNumber = async () => {
    const { defaultLanguage } = this.props;

    const data = await fetchShipmentNumber(defaultLanguage);

    return generateShipmentNumber(data.data);
  };

  onTransferProducts = async () => {
    this.setState({ isTransferring: true });

    const user = await getStorage();

    try {
      const shipmentNumber = await this.getShipmentNumber();

      for (let [i, el] of this.state.products.entries()) {
        let method = "POST",
          requestBody = {
            shipmentNumber,
            product: el.inventory?.code,
            to: user.stock,
            from: user.stock,
            donationNumber: el?.inventory?.donation?.donationNumber,
            requestNo: el.request.requestNo,
            status: "transferred",
          };

        const options = {
          method,
          url: `${process.env.REACT_APP_BBMS_BASE_API}/shipping-queue`,
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };

        const data = await axios(options);

        if (i === this.props.products.length - 1) {
          toastMessage(
            "success",
            language[this.props.defaultLanguage].add_data_success
          );
          this.setState(
            {
              isTransferring: false,
              selectedData: data.data,
            },
            () => {
              this.handleOpenModal(
                "showShipmentModal",
                "Shipment Information",
                data.data
              );
              this.props.handleRefresh && this.props.handleRefresh(true);
            }
          );
        }
      }
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isTransferring: false,
      });
    }
  };

  render() {
    const canDelete = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "delete",
    });

    const canAddProduct = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_LABORATORY,
      operation: "create",
    });
    return (
      <div>
        {canAddProduct && (
          <>
            <div className="alert alert-info mb-3">
              <span>
                This request should be forwarded to{" "}
                {this.state.user.allowedBloodBankName} due to the requested
                product(s) being out of stock.
              </span>
              <Button
                text="Forward"
                onPress={() => this.handleOpenModal("showForwardModal")}
              />
            </div>

            <form>
              <div className="card-body">
                {this.state.error.errorMessage && (
                  <div className="alert alert-danger mb-3">
                    {this.state.error.errorMessage}
                  </div>
                )}

                <div className="row">
                  <div className="col-md-4">
                    <Input
                      label={
                        language[this.props.defaultLanguage].donation_number
                      }
                      placeholder={
                        language[this.props.defaultLanguage]
                          .donation_number_placeholder
                      }
                      required
                      onChange={(e) => this.onChangeText("donationNumber", e)}
                      error={this.state.error.donationNumber}
                      value={this.state.donationNumber}
                    />
                  </div>
                  <div className="col-md-4">
                    <Input
                      label={language[this.props.defaultLanguage].product}
                      placeholder={
                        language[this.props.defaultLanguage].product_placeholder
                      }
                      required
                      onChange={(e) => this.onChangeText("product", e)}
                      error={this.state.error.product}
                      value={this.state.product}
                    />
                  </div>
                  <div className="col-md-12">
                    <Switch
                      label={
                        language[this.props.defaultLanguage]
                          .compatible_with_patient
                      }
                      onCheck={() => this.onCheck("isCompatibleWithPatient")}
                      checked={this.state.isCompatibleWithPatient}
                      disabled={this.state.isFetchingInventory}
                    />
                    {this.state.isFetchingInventory && <Loading />}
                  </div>
                </div>
              </div>
            </form>
          </>
        )}

        {(this.state.products?.length > 0 || this.state.isLoading) && (
          <>
            <CardHeader
              title={language[this.props.defaultLanguage].requested_products}
            />
            <br />

            <div className="card-body">
              <Table
                data={this.state.products}
                headers={this.renderProductHeaders()}
                showAdd={this.state.products.length > 0}
                addButtonText={language[this.props.defaultLanguage].transfer}
                handleAddPressed={this.onTransferProducts.bind(this)}
                addButtonLoading={this.state.isTransferring}
                actions={[
                  {
                    canAccess: true,
                    name: language[this.props.defaultLanguage].print_label,
                    onPress: (item) => {
                      this.setState(
                        {
                          donationNumber:
                            item.inventory.donation.donationNumber,
                          product: item.inventory.code,
                        },
                        async () => {
                          this.onCheck("isCompatibleWithPatient");
                        }
                      );
                    },
                  },
                  {
                    canAccess: canDelete,
                    name: language[this.props.defaultLanguage].delete_,
                    onPress: (item) =>
                      this.handleOpenModal("showDeleteModal", item._id, item),
                  },
                ]}
              />
            </div>
          </>
        )}

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showShipmentModal")}
          show={this.state.showShipmentModal}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <ShippingInQueue
            handleCloseModal={() => {
              this.handleCloseModal("showShipmentModal");
            }}
            shipmentNumber={this.state.selectedData.shipmentNumber}
            from={this.state.selectedData.from}
            to={this.state.selectedData.to}
            addedBy={this.state.addedBy}
            status={this.state.selectedData.status || "pending"}
            createdAt={this.state.selectedData.createdAt}
            recepientName={this.state.selectedData.recepientName}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLabelModal")}
          show={this.state.showLabelModal}
          showHeaderBottomBorder={false}
        >
          <PrintableLabel
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showLabelModal"
            )}
            productId={this.state?.inventoryInfo?._id}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showForwardModal")}
          show={this.state.showForwardModal}
          showHeaderBottomBorder={false}
        >
          <Forward
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showForwardModal"
            )}
            {...this.props}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showApproverModal")}
          show={this.state.showApproverModal}
          showHeaderBottomBorder={false}
        >
          <ApproverABO
            sampleBloodGroup={this.state?.sampleCollected?.bloodGroup}
            inventoryBloodGroup={this.state?.inventoryInfo?.bloodGroup}
            inventoryId={this.state?.inventoryInfo?._id}
            productType={this.state?.inventoryInfo?.component?.productType?._id}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showApproverModal"
            )}
            patientId={this.props.patientId}
            requestId={this.props.requestId}
            getProducts={() => this.getProducts(true)}
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state?.selectedData?.code}
            getData={this.getProducts.bind(this)}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Products);
