import React from "react";
import "./styles.css";
import { connect } from "react-redux";
import language from "../../language";
import { Checkbox } from "../Input";
import { Button } from "../Button";
import { Loading } from "../Loader";
import { getStorage, setStorage } from "../../utils/storage";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class TwoFAGoogleAuth extends React.Component {
  state = {
    isLoading: false,
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  setupTFA = async () => {
    this.setState({ isLoading: true });

    let url = `${API_URL}/2fa-google-auth`;
    const user = await getStorage();

    const options = {
      method: "POST",
      url,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(options)
      .then((data) => {
        let { user } = this.state;
        user.isTFAActive = true;
        user.TFASecret = data.data.secret;
        this.setState({ isLoading: false, ...data.data, user });

        toastMessage("success", data.data.message);
      })
      .catch((error) => {
        this.setState({ isSubmitting: false });

        toastMessage("error", error);
      });
  };

  onReset = async () => {
    this.setState({ isRetting: true });

    let url = `${API_URL}/2fa-google-auth`;
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios(options)
      .then(async (data) => {
        this.setState({ isRetting: false, isTFAActive: false });

        let { user } = this.state;

        delete user.TFASecret;
        user.isTFAActive = false;

        await setStorage(user);

        toastMessage("success", data.data.message);
      })
      .catch((error) => {
        this.setState({ isRetting: false });

        toastMessage("error", error);
      });
  };

  render() {
    return (
      <div className="card">
        <div className="card-heading"></div>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className="card-body">
            <h3>Setup TFA</h3>
            <p>Scan the QR or enter the secret key in google authenticator</p>
            <div className="qr-container">
              <img src={this.state.qrImage} />
            </div>
            {!this.state.user.isTFAActive ? (
              <Button
                text={language[this.props.defaultLanguage].enable_2fa}
                onPress={this.setupTFA.bind(this)}
                isSubmitting={this.state.isLoading}
              />
            ) : (
              <>
                {this.state.TFASecret !== "" && (
                  <>
                    <div className="auth-container">
                      <span>Secret key: {this.state.secret}</span>
                    </div>
                  </>
                )}
                <label>
                  <input
                    type="checkbox"
                    checked
                    style={{ marginRight: ".5rem" }}
                  />
                  {language[this.props.defaultLanguage].tfa_enabled}
                </label>
                <br />
                <br />
                <Button
                  text={language[this.props.defaultLanguage].reset_tfa}
                  onPress={this.onReset.bind(this)}
                  isSubmitting={this.state.isRetting}
                />
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(TwoFAGoogleAuth);
