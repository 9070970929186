import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import axios from "axios";
import { Table } from "../../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { CentersFilter, DateFilter } from "../../Filters";
import handleFilters from "../../../utils/handleFilters";
import icons from "../../../constants/icons";
import moment from "moment";
import { Modal } from "../../Modal";
import GenerateSummary from "./GenerateSummary";
import GuideFloatButton from "../../userGuide/FloatButton";

let copyData = [];

class PerCenterSite extends React.Component {
  state = {
    isLoading: false,
    data: [],
    csvData: [],
    total: 0,
    totalPageCount: 0,
    page: 1,
    limit: 10,
    filters: {
      startDate: moment().startOf("D").format("YYYY-MM-DD"),
      endDate: moment().endOf("D").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    await this.getData(true, this.state.filters);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.setState({ filters: this.props.filters });
      // this.getData(true, this.props.filters);
    }
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
        styles: {
          textTransform: "uppercase",
        },
        type: "link",
        onPress: (item) =>
          this.handleShowModal("showModal", item.centerSite.name, item),
      },
      {
        title: "O+",
        key: "O+",
      },
      {
        title: "O-",
        key: "O-",
      },
      {
        title: "A+",
        key: "A+",
      },
      {
        title: "A-",
        key: "A-",
      },
      {
        title: "B+",
        key: "B+",
      },
      {
        title: "B-",
        key: "B-",
      },
      {
        title: "AB+",
        key: "AB+",
      },
      {
        title: "Unspecified",
        key: "unspecified",
      },
      {
        title: "Total",
        key: "total",
      },
    ];

    return headers;
  }

  handleShowModal(modal, modalTitle, selectedData) {
    this.setState({
      [modal]: true,
      modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      let { page, limit } = this.state;
      let total = 0;
      const user = await getStorage();

      this.setState({ isLoading, filters: search });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const filters = handleFilters(search);

      const params = {
        ...filters,
        page,
        limit,
      };

      const options = {
        url:
          process.env.REACT_APP_BBMS_BASE_API +
          "/bloodDonation/bloodGroup/centerSite",
        method: "get",
        params,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios(options);

      // data.data.forEach((item) => {
      //   total += item.total;
      // });

      this.setState({
        data: data.data,
        isLoading: false,
        total,
        totalPageCount: data.count,
      });

      copyData = data.data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true, this.state.filters);
      }
    );
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
      ...this.state.filters,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <div>
        <Table
          page={this.state.page}
          limit={this.state.limit}
          data={this.state.data}
          isSearch
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          totalPageCount={this.state.totalPageCount}
          handlePagination={this.handlePagination.bind(this)}
          fetchData={(isLoading, filters) => this.getData(isLoading, filters)}
          showAdd
          addButtonText={language[this.props.defaultLanguage].generate}
          handleAddPressed={() => {
            this.handleShowModal(
              "showModal",
              language[this.props.defaultLanguage].generate,
              null
            );
          }}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true, this.state.filters),
            },
            {
              type: "button",
              title: "Filter",
              button_type: "dropdown",
              filterName: "date_range",
              ...this.state.filters,
            },
          ]}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
        />

        {/* <div className="alert alert-info">
          {language[this.props.defaultLanguage].total}:{" "}
          <strong>{this.state.total}</strong>{" "}
        </div> */}
        <Modal
          show={this.state.showModal}
          title={this.state.modalTitle}
          handleClose={() => this.handleCloseModal("showModal")}
          size="xl"
        >
          <GenerateSummary
            center={this.state?.selectedData?.centerSite?.center}
            centerSite={this.state?.selectedData?.centerSite?._id}
            startCollectionDate={this.state.filters.startDate}
            endCollectionDate={this.state.filters.endDate}
          />
        </Modal>

        <CSVLink
          ref="csvDownload"
          filename={"Donation per center site blood Group"}
          data={this.state.csvData}
        ></CSVLink>
        <GuideFloatButton
          title="How to generate blood collection report per day"
          videoUrl="https://res.cloudinary.com/djjwt8ewl/video/upload/v1738628819/BBMS/blob_2_wa2pob.mp4"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(PerCenterSite);
