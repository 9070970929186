import React from "react";
import icons from "../../constants/icons";
import { Modal } from "../Modal";

class GuideFloatButton extends React.Component {
  state = {
    showModal: false,
  };

  handleToggleModal(action) {
    this.setState({
      showModal: action,
    });
  }
  render() {
    return (
      <>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            margin: "1rem",
          }}
          onClick={() => this.handleToggleModal("showModal", true)}
        >
          <div
            style={{
              backgroundColor: "var(--primary-color)",
              color: "white",
              padding: ".5rem",
              borderRadius: "1.5rem",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
              border: "2px solid var(--primary-color)",
            }}
          >
            <span
              style={{ color: "white" }}
              className={process.env.REACT_APP_ICONS_TYPE}
            >
              {icons.help}
            </span>
            <span className="text-bold">User Guide</span>
          </div>
        </div>
        <Modal
          show={this.state.showModal}
          title={this.props.title}
          handleClose={() => this.handleToggleModal(false)}
          size="lg"
        >
          <video controls autoPlay>
            <source src={this.props.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      </>
    );
  }
}

export default GuideFloatButton;
