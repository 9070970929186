import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import Patients from "../../../components/Patient/patients";
import { Tabs } from "../../../components/Tabs";
import PatientLabResult from "../../../components/Patient/lab-results";

class PatientScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].patients}
            />
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].patients,
                  data: <Patients />,
                },
                {
                  title: language[this.props.defaultLanguage].lab_results,
                  data: <PatientLabResult />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(PatientScreen));
