import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import axios from "axios";
import { Table } from "../../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../../utils/handleSearch";
import toastMessage from "../../../utils/toastMessage";
import { CentersFilter, DateFilter } from "../../Filters";
import handleFilters from "../../../utils/handleFilters";
import icons from "../../../constants/icons";
import moment from "moment";

let copyData = [];

class PerCenteGender extends React.Component {
  state = {
    isLoading: false,
    data: [],
    csvData: [],
    total: 0,
    totalPageCount: 0,
    page: 1,
    limit: 10,
    filters: {
      startDate: moment().startOf("D").format("YYYY-MM-DD"),
      endDate: moment().endOf("D").format("YYYY-MM-DD"),
    },
  };

  componentDidMount = async () => {
    await this.getData(true, this.state.filters);
  };

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.getData(true);
    }
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "center.name",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: "Male",
        key: "maleCount",
      },
      {
        title: "Female",
        key: "femaleCount",
      },
      {
        title: "Male Percentage",
        key: "malePercentage",
      },
      {
        title: "Female Percentage",
        key: "femalePercentage",
      },
      {
        title: "Total",
        key: "total",
      },
    ];

    return headers;
  }

  getData = async (isLoading, search = {}) => {
    try {
      let { page, limit } = this.state;
      let total = 0;
      let {} = this.props;
      const user = await getStorage();

      this.setState({ isLoading });

      const filters = handleFilters(search);

      const params = {
        ...filters,
        page,
        limit,
      };

      const options = {
        url: process.env.REACT_APP_BBMS_BASE_API + "/bloodDonation/gender",
        method: "get",
        params,
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data, count } = await axios(options);
      console.log(data);

      data.data.forEach((item) => {
        total += item.total;
      });

      this.setState({
        data: data.data,
        isLoading: false,
        total,
        totalPageCount: data.count,
      });

      copyData = data.data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          page={this.state.page}
          limit={this.state.limit}
          data={this.state.data}
          isSearch
          isLoading={this.state.isLoading}
          headers={this.returnTableHeaders()}
          totalPageCount={this.state.totalPageCount}
          handlePagination={this.handlePagination.bind(this)}
          fetchData={(isLoading, filters) => this.getData(isLoading, filters)}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true, this.state.filters),
            },
            {
              type: "button",
              title: "Filter",
              button_type: "dropdown",
              filterName: "date_range",
              ...this.state.filters,
            },
          ]}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
        />

        <div className="alert alert-info">
          {language[this.props.defaultLanguage].total}:{" "}
          <strong>{this.state.total}</strong>{" "}
        </div>

        <CSVLink
          ref="csvDownload"
          filename={"Donation per center PerCenteGender"}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(PerCenteGender);
