import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Loading } from "../../Loader";
import { PrintComponent } from "../../Print";
import rbcLogo from "../../../assets/rbc-logo.png";

class Summary extends React.Component {
  state = {
    csvData: [],
  };

  componentDidMount = async () => {
    let exportName;

    if (this.props.centerSiteName) {
      exportName = `${moment().format("lll")}-${
        this.props.centerSiteName
      }-blood donation report`;
    } else {
      exportName = `${moment().format("lll")}-blood donation report`;
    }

    this.setState({ exportName });
  };

  renderItem = ({ title, total }) => {
    return (
      <tr>
        <td>
          <span>{title}</span>
        </td>
        <td>
          <b>{total}</b>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.props.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            Object.keys(this.props.data || {}).length > 0 && (
              <PrintComponent>
                <div
                  style={{
                    // backgroundColor: "#eee",
                    marginTop: "1rem",
                    borderRadius: 8,
                    padding: "1rem",
                  }}
                >
                  <div>
                    <img
                      src={rbcLogo}
                      style={{
                        aspectRatio: 2 / 2,
                        objectFit: "contain",
                        width: "30%",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "1rem", display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <p className="mb-2">
                        Date:
                        <b>
                          {" "}
                          {moment(this.props.data.startCollectionDate).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          -{" "}
                          {moment(this.props.data.endCollectionDate).format(
                            "DD-MM-YYYY"
                          )}
                        </b>
                      </p>
                      <p className="mb-2">
                        Center:{" "}
                        <b style={{ textTransform: "uppercase" }}>
                          {this.props.data.center?.name}
                        </b>
                      </p>
                      <p className="mb-2">
                        Center Site: <b>{this.props.data.centerSite?.name}</b>
                      </p>
                      <p className="mb-2">
                        Generated By: <b>{this.props.data.account?.pin}</b>
                      </p>
                    </div>
                    <div>
                      <p className="mb-2">
                        Shipment Number: <b>{this.props.data.shipmentNumber}</b>
                      </p>
                      <p className="mb-2">
                        Transportation Temperature:{" "}
                        <b>{this.props.data.transportationTemparature}</b>
                      </p>
                    </div>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderItem({
                        title: "ND",
                        total: this.props.data.newDonor || 0,
                      })}
                      {this.renderItem({
                        title: "RD",
                        total: this.props.data.repeatDonor || 0,
                      })}

                      {this.renderItem({
                        title: "O+",
                        total: this.props.data["O+"] || 0,
                      })}
                      {this.renderItem({
                        title: "O-",
                        total: this.props.data["O-"] || 0,
                      })}
                      {this.renderItem({
                        title: "A+",
                        total: this.props.data["A+"] || 0,
                      })}
                      {this.renderItem({
                        title: "A-",
                        total: this.props.data["A-"] || 0,
                      })}
                      {this.renderItem({
                        title: "B+",
                        total: this.props.data["B+"] || 0,
                      })}
                      {this.renderItem({
                        title: "B-",
                        total: this.props.data["B-"] || 0,
                      })}
                      {this.renderItem({
                        title: "AB+",
                        total: this.props.data["AB+"] || 0,
                      })}
                      {this.renderItem({
                        title: "AB-",
                        total: this.props.data["AB-"] || 0,
                      })}
                    </tbody>
                  </table>
                  <h6 className="mb-2">
                    Total: <b>{this.props.data.total || 0}</b>
                  </h6>
                  <h6>
                    Expected: <b>{this.props.data.expected || 0}</b>
                  </h6>
                  <br />
                  <hr />
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <p>
                        Driver: <b>{this.props.data.driver}</b>
                      </p>
                      <p>
                        Plate Number: <b>{this.props.data.plateNumber}</b>
                      </p>
                    </div>
                    <div>
                      <p>
                        Received by:{" "}
                        <b>
                          {this.props.data?.receivedBy?.name ||
                            ".............................."}
                        </b>
                      </p>
                      <p>
                        Date:{" "}
                        <b>
                          {this.props.data?.receivedDate?.name ||
                            ".............................."}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </PrintComponent>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Summary);
