import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import requestQuery from "../../../utils/queries/requestQuery.js";
import CardHeader from "../../Header/CardHeader.js";
import BulkRequest from "./BulkRequest.js";
import Input from "../../Input/Input.js";
import moment from "moment";
import capitalizeText from "../../../utils/capitalizeText.js";
import { Modal } from "../../Modal/index.js";
import CurrentStock from "../toBeRequested/index.js";
import Loading from "../../Loader/Loading.js";
import ControlledTabs from "../../Tabs/Tabs.js";
import TransferredProducts from "./TransferredProducts.js";

class RequestInfo extends React.Component {
  state = {
    error: {},
    data: [],
    data: [],
    csvData: [],
    packages: [],
    currentStockInformation: [],
  };

  componentDidMount = async () => {
    this.fetchData(true);
  };

  componentDidUpdate(nextProps) {
    if (this.props.isRefreshing !== nextProps.isRefreshing) {
      this.fetchData(true);
    }
  }

  fetchData = async () => {
    await this.getRequestInfo(true);
  };

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  getRequestInfo = async (isLoading) => {
    try {
      const { defaultLanguage, requestNo } = this.props;

      this.setState({ isLoading });

      const { data } = await requestQuery(defaultLanguage, {
        requestNo,
      });

      console.log(data);

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }
  render() {
    console.log(this.state.currentStockInformation);
    console.log("this.state.data[0].hospital.bloodBank", this.state.data[0]);
    return (
      <>
        <div style={{ marginBottom: "1rem" }} />
        {this.state.isLoading ? (
          <Loading className="fullscreen" />
        ) : (
          <>
            {" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1, marginBottom: "1rem" }}>
                {this.state.data.length > 0 && (
                  <>
                    <div
                      className={`mb-3 badge badge-${this.state.data[0].status}`}
                    >
                      {language[this.props.defaultLanguage].status}:{" "}
                      {capitalizeText(this.state.data[0].status)}
                    </div>
                    {this.state.data[0].shipmentNumber && (
                      <p className="text-primary">
                        {language[this.props.defaultLanguage].shipment_number}:{" "}
                        {this.state.data[0].shipmentNumber}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            {this.state?.data?.length > 0 && (
              <div className="row">
                <div className="col-md-3">
                  <div
                    className="card"
                    style={{ maxHeight: "100vh", overflowY: "auto" }}
                  >
                    <div className="card-body">
                      <div>
                        <div className="col-md-12">
                          <div>
                            <Input
                              label={
                                language[this.props.defaultLanguage]
                                  .request_number
                              }
                              value={this.state.data[0].requestNo}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div>
                            <Input
                              label={language[this.props.defaultLanguage].date}
                              value={this.state.data[0].createdAt}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div>
                            <Input
                              label={language[this.props.defaultLanguage].type}
                              value={this.state.data[0].type}
                              disabled
                            />
                          </div>
                        </div>

                        {this.state.data[0].bloodBank && (
                          <div className="col-md-12">
                            <div>
                              <Input
                                label={
                                  language[this.props.defaultLanguage].requestee
                                }
                                value={this.state.data[0].bloodBank.name}
                                disabled
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div>
                            <Input
                              label={
                                language[this.props.defaultLanguage].receiver
                              }
                              value={this.state?.data[0]?.hospital?.name}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.data[0].patient?._id && (
                      <div>
                        <CardHeader
                          title={
                            language[this.props.defaultLanguage]
                              .patient_information
                          }
                        />
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    language[this.props.defaultLanguage].patient
                                  }
                                  value={
                                    this.state.data[0].patient.firstName +
                                    " " +
                                    this.state.data[0].patient.lastName
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    language[this.props.defaultLanguage]
                                      .patient_id
                                  }
                                  value={this.state.data[0].patient.patientId}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    language[this.props.defaultLanguage].gender
                                  }
                                  value={this.state.data[0].patient.gender}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    language[this.props.defaultLanguage]
                                      .date_of_birth
                                  }
                                  value={this.state.data[0].patient.dob}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.data[0].clinicalInformation && (
                      <div>
                        <CardHeader
                          title={
                            language[this.props.defaultLanguage]
                              .clinical_information
                          }
                        />
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                <Input
                                  value={this.state.data[0].clinicalInformation}
                                  textarea
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    "Has the patient received a blood transfusion in the past?"
                                  }
                                  value={
                                    this.state.data[0].previouslyTransfused
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={"Last transfused date"}
                                  value={
                                    this.state.data[0].lastTransfused
                                      ? moment(
                                          this.state.data[0].lastTransfused
                                        ).format("YYYY-MM-DD HH:mm")
                                      : null
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div>
                                <Input
                                  label={
                                    language[this.props.defaultLanguage]
                                      .history_of_pregnancy
                                  }
                                  value={this.state.data[0].historyPregnancy}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              {this.state.data[0].isUrgent && (
                                <div>
                                  <Input
                                    label={
                                      language[this.props.defaultLanguage]
                                        .urgent
                                    }
                                    value={this.state.data[0].isUrgent}
                                    disabled
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-6">
                              {this.state.data[0].requestType === "booking" ? (
                                <div>
                                  <Input
                                    label={
                                      language[this.props.defaultLanguage]
                                        .booking
                                    }
                                    value={this.state.data[0].bookingDate}
                                    disabled
                                  />
                                </div>
                              ) : (
                                this.state.data[0].requestType && (
                                  <div>
                                    <Input
                                      label={
                                        language[this.props.defaultLanguage]
                                          .request_type
                                      }
                                      value={this.state.data[0].requestType}
                                      disabled
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            <div className="col-md-6">
                              {this.state.data[0].isOrdinary && (
                                <div>
                                  <Input
                                    label={
                                      language[this.props.defaultLanguage]
                                        .ordinary
                                    }
                                    value={this.state.data[0].isOrdinary}
                                    disabled
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {this.state.data?.[0]?.hospital?.allowedProducts?.length >
                  0 && (
                  <>
                    <div className="col-md-9">
                      {/* <CardHeader
                        title={
                          language[this.props.defaultLanguage]
                            .request_information
                        }
                      /> */}
                      <ControlledTabs
                        options={[
                          {
                            title:
                              language[this.props.defaultLanguage]
                                .request_information,
                            data: (
                              <CurrentStock
                                productType={
                                  this.state.data[0]?.hospital?.allowedProducts
                                }
                                stock={this.state.data[0].hospital.bloodBank}
                                bloodBank={this.state.data[0].bloodBank}
                                status={this.state.data[0].status}
                                request={this.state.data[0]._id}
                                type={this.state.data[0].type}
                                requestInfo={this.state.data[0]}
                              />
                            ),
                          },
                          this.state.data[0].shipmentNumber && {
                            title:
                              language[this.props.defaultLanguage].transferred,
                            data: (
                              <TransferredProducts
                                shipmentNumber={
                                  this.state.data[0].shipmentNumber
                                }
                              />
                            ),
                          },
                        ].filter(Boolean)}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}

        <Modal
          show={this.state.showBulkRequest}
          handleCloseModal={() => this.handleCloseModal("showBulkRequest")}
        >
          {this.state.data.length > 0 && (
            <BulkRequest {...this.state.data[0]} />
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
