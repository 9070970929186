import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { getStorage } from "../../../utils/storage";
import accessMenu from "../../../utils/accessMenu";
class OverviewScren extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    const {} = accessMenu();

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <Breadcrumb
              homeUrl="/dashboard/home"
              data={[
                {
                  name: language[this.props.defaultLanguage].overview,
                  url: "/dashboard",
                },
              ]}
            />
          </div>
          filters: Dates, center, center site general counts: total donors when
          click show regular and Repeat, donations: click then show from regular
          and Repeat, total: request: norminative and bulk: in stock: show per
          productId
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(withRouter(OverviewScren));
