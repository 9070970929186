import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { DashboardHeader } from "../../../components/Header";
import AgeGroup from "../../../components/Reports/donors/AgeGroup";
import PerCenter from "../../../components/Reports/donation/PerCenter";
import PerCenterSite from "../../../components/Reports/donation/PerCenterSite";
import PerCenterGender from "../../../components/Reports/donation/PerCenterGender";
import Summary from "../../../components/Reports/donation/GenerateSummary";

class DonationReportsScreen extends React.Component {
  render() {
    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div style={{ marginBottom: 16 }}>
              <div style={{ flex: 1 }}>
                <DashboardHeader title="Blood Collection Reports" />
              </div>
            </div>

            <Tabs
              options={[
                // {
                //   title: "Summary",
                //   data: <Summary />,
                // },
                {
                  title: "Center Site",
                  data: <PerCenterSite />,
                },
                {
                  title: "Center",
                  data: <PerCenter />,
                },
                {
                  title: "Center and Gender",
                  data: <PerCenterGender />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(DonationReportsScreen));
