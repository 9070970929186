import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import screened from "../../assets/screened-colored.png";
import giveBlood from "../../assets/giveBlood.png";
import register from "../../assets/register.png";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { setStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import LoginTabs from "../LoginTabs/LoginTabs";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;
class Login extends React.Component {
  state = {
    username: "",
    isSubmitting: false,
    error: {},
    password: "",
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value });
  };

  validateForm = () => {
    let { error, username, password } = this.state;
    if (username === "") {
      error.username = language[this.props.defaultLanguage].email_required;
    } else if (username.includes("@") && !validateEmail(username)) {
      error.username =
        language[this.props.defaultLanguage].invalid_email_required;
    }

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { username, password } = this.state;

      const options = {
        method: "POST",
        url: `${API_URL}/authentication/donor`,
        data: {
          email: username?.replace(/\s+/g, ""),
          password,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          return (window.location.href = `/donor-login/${data.data.token}`);

          let userInfo = data.data;

          if (data.data.firstName) {
            userInfo.displayName =
              data.data.firstName + " " + data.data?.lastName;
          } else {
            userInfo.displayName = username.split("@")[0];
          }

          this.onSuccess(userInfo);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage(
            "error",
            language[this.props.defaultLanguage].wrong_email_password
          );
        });
    }
  };

  onSuccess = async (data) => {
    this.setState({ isSubmitting: false });

    if (data.firstTime) {
      return (window.location.href = `/forgot-password/donor/${data.email}`);
    }

    await setStorage(data);

    window.location.href = "/user/home";
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-sm-12 col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_donation_tracking,
                    description:
                      language[this.props.defaultLanguage]
                        .simplifying_donation_process,
                    image: giveBlood,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage].donation_analytics,
                    description:
                      language[this.props.defaultLanguage]
                        .comprehensive_analytics,
                    image: register,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_testing_eligibility,
                    description:
                      language[this.props.defaultLanguage]
                        .personalized_feedback,
                    image: screened,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              {/* <LoginTabs active="donor" /> */}
              <div className="title">
                <h3
                  className="text-primary"
                  style={{ textTransform: "uppercase" }}
                >
                  {language[this.props.defaultLanguage].welcome_title}
                </h3>
                <p className="text-disabled">
                  {language[this.props.defaultLanguage].login_as_donor}
                </p>
              </div>
              <form>
                <Input
                  label={language[this.props.defaultLanguage].email_address}
                  placeholder={
                    language[this.props.defaultLanguage].email_placeholder
                  }
                  required
                  leftIcon={icons.user}
                  value={this.state.username}
                  error={this.state.error.username}
                  onChange={(e) => this.onChangeText("username", e)}
                  // inputStyles={{ textTransform: "lowercase" }}
                />
                <Input
                  label={language[this.props.defaultLanguage].password}
                  placeholder={
                    language[this.props.defaultLanguage].password_placeholder
                  }
                  required
                  leftIcon={icons.password}
                  value={this.state.password}
                  error={this.state.error.password}
                  onChange={(e) => this.onChangeText("password", e)}
                  type="password"
                />
                <Button
                  text={language[this.props.defaultLanguage].login_text}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
                <br />
                <hr />
                <br />
                <div className="d-flex flex-column gap-3">
                  <Button
                    text="BTD Staff "
                    className="w-100 btn-default"
                    type="link"
                    to="/admin-login"
                  />
                  <Button
                    text="Health Facility Staff"
                    className="w-100 btn-default"
                    type="link"
                    to="/admin-login"
                  />
                </div>
                <br />
                <div className="footer">
                  <span>
                    <Link to="/forgot-password/donor">
                      {language[this.props.defaultLanguage].forgot_password}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Login);
