import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import screened from "../../assets/screened-colored.png";
import giveBlood from "../../assets/giveBlood.png";
import register from "../../assets/register.png";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { setStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class MFAVerify extends React.Component {
  state = {
    isSubmitting: false,
    error: {},
    password: "",
    email: "",
    otpcode: "",
  };

  componentDidMount = async () => {
    const decodedToken = jwtDecode(this.props.token);
    const { email } = decodedToken || {};

    if (!email) return toastMessage("error", "Invalid token");

    this.setState({ email });
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value?.toUpperCase() });
  };

  validateForm = () => {
    let { error, otpcode } = this.state;
    if (otpcode === "") {
      error.otpcode = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { otpcode } = this.state;
      const { token } = this.props;

      const options = {
        method: "POST",
        url: `${API_URL}/authentication/account/mfa-verify`,
        data: { token, otpcode },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });

          if (data.data.status === "error")
            return toastMessage("error", data.data.message);
          toastMessage("success", data.data.message);

          let userInfo = {
            ...data.data,
            token: data?.data?.token,
            role: data.data.role,
          };

          if (data.data?.id) {
            userInfo.id = data.data?.id;
          }

          if (data.data.name) {
            userInfo.displayName = data.data.name;
          } else {
            userInfo.displayName = data.data.email.split("@")[0];
          }

          this.onSuccess(userInfo);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          console.log(error);

          toastMessage("error", error);
        });
    }
  };

  onSuccess = async (data) => {
    this.setState({ isSubmitting: false });

    if (data.firstTime) {
      return (window.location.href = `/forgot-password/account/${this.state.email}`);
    }
    await setStorage(data);
    window.location.href = "/dashboard/donors";
  };

  onResendCode = () => {
    const { token } = this.props;
    this.setState({
      isResending: true,
    });
    const options = {
      method: "POST",
      url: `${API_URL}/authentication/account/resend-mfa-code`,
      data: { token },
    };

    axios(options)
      .then((data) => {
        this.setState({ isResending: false, otpcode: "" });
        toastMessage("success", data.data.message);
      })
      .catch((error) => {
        this.setState({ isResending: false });

        toastMessage("error", error);
      });
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_donation_tracking,
                    description:
                      language[this.props.defaultLanguage]
                        .simplifying_donation_process,
                    image: giveBlood,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage].donation_analytics,
                    description:
                      language[this.props.defaultLanguage]
                        .comprehensive_analytics,
                    image: register,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_testing_eligibility,
                    description:
                      language[this.props.defaultLanguage]
                        .personalized_feedback,
                    image: screened,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              <div className="title">
                <h3
                  className="text-primary"
                  style={{ textTransform: "uppercase" }}
                >
                  {language[this.props.defaultLanguage].mfa_verify}
                </h3>
                <p className="text-disabled">
                  {language[this.props.defaultLanguage]
                    .enter_code_send_to_your_email +
                    " " +
                    this.state.email}
                </p>
              </div>
              <form>
                <Input
                  label={language[this.props.defaultLanguage].code}
                  placeholder={language[this.props.defaultLanguage].code}
                  required
                  leftIcon={icons.pin}
                  value={this.state.otpcode}
                  error={this.state.error.otpcode}
                  inputStyles={{ textTransform: "uppercase" }}
                  onChange={(e) => this.onChangeText("otpcode", e)}
                />
                <Button
                  text={language[this.props.defaultLanguage].verify}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={this.onSubmit.bind(this)}
                />
              </form>
              <hr />
              <div className="d-flex flex-column gap-3">
                <Button
                  text={language[this.props.defaultLanguage].resend_code}
                  className="w-100 btn-default"
                  isSubmitting={this.state.isResending}
                  onPress={this.onResendCode.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MFAVerify);
