import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input/index.js";
import language from "../../language/index.js";
import { Button } from "../Button/index.js";
import api from "../../utils/api/index.js";
import { fetchRejectionStatus } from "../../utils/queries/rejectionStatus.js";
import toastMessage from "../../utils/toastMessage.js";
import { getStorage } from "../../utils/storage.js";
import moment from "moment";

class ReturnRequest extends React.Component {
  state = {
    error: {},
    data: [],
    isDoneSearching: false,
    product: "",
    donationNumber: "",
    requestNo: "",
    shipmentNumber: "",
  };

  validateForm() {
    let {
      error,
      donationNumber,
      product,
      reason,
      action,
      durationOutOfCondition,
    } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (durationOutOfCondition === "") {
      error.durationOutOfCondition =
        language[this.props.defaultLanguage].field_required;
    }

    if (product === "") {
      error.product = language[this.props.defaultLanguage].product_required;
    }

    if (!reason) {
      error.reason = language[this.props.defaultLanguage].reason_required;
    }

    if (!action) {
      error.action = language[this.props.defaultLanguage].action_required;
    }
    console.log(error);
    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    try {
      if (Object.keys(this.state.error).length > 0) return;

      const {
        donationNumber,
        product,
        reason,
        action,
        durationOutOfCondition,
        hasBloodOpen,
      } = this.state;

      this.setState({
        isSubmitting: true,
      });

      let method = "POST",
        requestBody = {
          product,
          donationNumber,
          status: reason?.status,
          reasonId: reason?._id,
          action: action?.value,
          durationOutOfCondition: durationOutOfCondition?.value,
          hasBloodOpen: hasBloodOpen?.value,
        };

      const options = {
        method,
        url: `${process.env.REACT_APP_BBMS_BASE_API}/inventory/manual-return-product`,
        data: requestBody,
      };

      await api(options);

      this.setState({
        isSubmitting: false,
        donationNumber: "",
        product: "",
        reason: null,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].add_data_success
      );
    } catch (error) {
      console.log(error);
      this.setState({ isSubmitting: false });
      toastMessage("error", error);
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      isDoneSearching: false,
    });
  };

  getReturnStatus = async (isLoading, search = {}) => {
    try {
      this.setState({ isLoading });
      const { data } = await fetchRejectionStatus("dropdown", {
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        isLoading: false,
      });

      return data;
    } catch (error) {
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <Input
            placeholder={
              language[this.props.defaultLanguage].donation_number_placeholder
            }
            label={language[this.props.defaultLanguage].donation_number}
            value={this.state.donationNumber}
            onChange={(e) => this.onChangeText("donationNumber", e)}
            error={this.state.error.donationNumber}
          />
        </div>
        <div className="col-md-6">
          <Input
            placeholder={
              language[this.props.defaultLanguage].product_placeholder
            }
            label={language[this.props.defaultLanguage].product}
            value={this.state.product}
            onChange={(e) => this.onChangeText("product", e)}
            error={this.state.error.product}
          />
        </div>
        <div className="col-md-6">
          <Select
            // loadOptions={(e) =>
            //   this.getReturnStatus(true, { q: e, type: "RETURN" })
            // }
            options={[
              {
                label: "Unblock",
                value: "unblock",
              },
              {
                label: "Hold",
                value: "hold",
              },
              {
                label: "Quarantine",
                value: "quarantine",
              },
              {
                label: "Unquarantine",
                value: "unquarantine",
              },
              {
                label: "Reprint label",
                value: "reprint",
              },
              {
                label: "Reject",
                value: "reject",
              },
            ]}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].actions}
            value={this.state.action}
            onChange={(e) => this.onChangeText("action", e)}
            error={this.state.error.action}
          />
        </div>
        <div className="col-md-6">
          <Select
            options={[
              {
                label: "0-30 Min",
                value: "0-30 Min",
              },
              {
                label: "31-60 Min",
                value: "31-60 Min",
              },
              {
                label: ">60 Min",
                value: ">60 Min",
              },
            ]}
            placeholder={language[this.props.defaultLanguage].select}
            label={
              language[this.props.defaultLanguage].how_long_out_of_condition
            }
            value={this.state.durationOutOfCondition}
            onChange={(e) => this.onChangeText("durationOutOfCondition", e)}
            error={this.state.error.durationOutOfCondition}
          />
        </div>
        <div className="col-md-6">
          <Select
            options={[
              {
                label: language[this.props.defaultLanguage].yes,
                value: "yes",
              },
              {
                label: language[this.props.defaultLanguage].no,
                value: "no",
              },
              {
                label: language[this.props.defaultLanguage].not_known,
                value: "Not known",
              },
            ]}
            placeholder={language[this.props.defaultLanguage].select}
            label={language[this.props.defaultLanguage].has_blood_open}
            value={this.state.hasBloodOpen}
            onChange={(e) => this.onChangeText("hasBloodOpen", e)}
            error={this.state.error.hasBloodOpen}
          />
        </div>
        <div className="col-md-6">
          <Select
            loadOptions={(e) =>
              this.getReturnStatus(true, { q: e, type: "RETURN" })
            }
            placeholder={
              language[this.props.defaultLanguage].reason_placeholder
            }
            label={language[this.props.defaultLanguage].reason}
            value={this.state.reason}
            onChange={(e) => this.onChangeText("reason", e)}
            error={this.state.error.reason}
          />
        </div>
        <div>
          {this.props.handleCloseModal && (
            <Button
              className="btn-default"
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
            />
          )}
          <Button
            text={language[this.props.defaultLanguage].submit}
            isSubmitting={this.state.isSubmitting}
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ReturnRequest);
