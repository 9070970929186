import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import hospitalQuery from "../../../utils/queries/hospitalQuery";
import departmentQuery from "../../../utils/queries/departmentQuery";
import icons from "../../../constants/icons";
import { getStorage } from "../../../utils/storage";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../../utils/rwanda";
import { Separator } from "../../Separator";
import nationalities from "../../../constants/nationalities";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewPatient extends React.Component {
  state = {
    requesterName: "",
    requesterPosition: "",
    error: {},
    isSubmitting: false,
    hospitals: [],
    firstName: "",
    lastName: "",
    contact: "",
    identityNumber: "",
    identityNumberTypes: [],
    dob: "",
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    if (this.props._id) {
      this.setState({
        ...this.props,
        hospital: this.props.hospital
          ? {
              label: this.props.hospital.name,
              value: this.props.hospital._id,
            }
          : null,
        service: this.props.service
          ? {
              label: this.props.service.name,
              value: this.props.service._id,
            }
          : null,
        province: this.props.province
          ? {
              label: this.props.province,
              value: this.props.province,
            }
          : null,
        district: this.props.district
          ? {
              label: this.props.district,
              value: this.props.district,
            }
          : null,
        sector: this.props.sector
          ? {
              label: this.props.sector,
              value: this.props.sector,
            }
          : null,
        sector: this.props.sector
          ? {
              label: this.props.sector,
              value: this.props.sector,
            }
          : null,
        cell: this.props.cell
          ? {
              label: this.props.cell,
              value: this.props.cell,
            }
          : null,
        village: this.props.village
          ? {
              label: this.props.village,
              value: this.props.village,
            }
          : null,
        nationality: this.props.nationality
          ? {
              label: this.props.nationality,
              value: this.props.nationality,
            }
          : null,
        gender: this.props.gender
          ? {
              label: this.props.gender,
              value: this.props.gender,
            }
          : null,
      });
    }
  };

  getServices = async (isFetchingServices, search = {}) => {
    try {
      this.setState({
        isFetchingServices,
      });

      const data = await departmentQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 0,
        limit: 50,
        ...search,
      });

      console.log(data);

      this.setState({
        isFetchingServices: false,
        page: 1,
        limit: 50,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingServices: false });
    }
  };

  getHospitals = async (isFetchingHospitals, search = {}) => {
    try {
      this.setState({
        isFetchingHospitals,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        page: 0,
        limit: 50,
        ...search,
      });

      this.setState({
        isFetchingHospitals: false,
        page: 1,
        limit: 50,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingHospitals: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let {
      firstName,
      lastName,
      error,
      identityNumber,
      patientId,
      nationality,
      dob,
      gender,
    } = this.state;

    if (firstName === "") {
      error.firstName =
        language[this.props.defaultLanguage].first_name_required;
    }

    if (lastName === "") {
      error.lastName = language[this.props.defaultLanguage].last_name_required;
    }

    if (dob === "") {
      error.dob = language[this.props.defaultLanguage].dob_required;
    }

    if (!gender) {
      error.gender = language[this.props.defaultLanguage].gender_required;
    }

    if (!patientId || patientId === "") {
      error.patientId =
        language[this.props.defaultLanguage].patient_id_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          firstName,
          lastName,
          contact,
          identityNumber,
          province,
          district,
          cell,
          village,
          sector,
          patientId,
          department,
          roomNumber,
          bedNumber,
          nationality,
          dob,
          gender,
          hospital,
          service,
        } = this.state,
        url = `${API_URL}/patient`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        department,
        roomNumber,
        bedNumber,
        firstName,
        lastName,
        contact,
        identityNumber,
        hospital: user.hospital || hospital?.value,
        addedBy: user.id,
        province: province?.value,
        district: district?.value,
        cell: cell?.value,
        village: village?.value,
        sector: sector?.value,
        patientId: patientId + "",
        nationality: nationality?.value,
        dob,
        gender: gender.value,
        service: service?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            {!this.state.user.hospital && (
              <div className="col-md-12">
                <Select
                  loadOptions={(e) =>
                    this.getHospitals(true, {
                      q: e,
                    })
                  }
                  label={
                    language[this.props.defaultLanguage].entity_or_hospital
                  }
                  required
                  value={this.state.hospital}
                  onChange={(e) => this.onChangeText("hospital", e)}
                  error={this.state.error.hospital}
                  isLoading={this.state.isFetchingHospitals}
                />
              </div>
            )}
            <div className="col-md-12">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].patient_id_placeholder
                }
                label={language[this.props.defaultLanguage].patient_id}
                required
                value={this.state.patientId}
                onChange={(e) => this.onChangeText("patientId", e)}
                error={this.state.error.patientId}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].first_name_placeholder
                }
                label={language[this.props.defaultLanguage].first_name}
                required
                value={this.state.firstName}
                onChange={(e) => this.onChangeText("firstName", e)}
                error={this.state.error.firstName}
              />
            </div>
            <div className="col-md-6">
              <Input
                leftIcon={icons.user}
                placeholder={
                  language[this.props.defaultLanguage].last_name_placeholder
                }
                label={language[this.props.defaultLanguage].last_name}
                required
                value={this.state.lastName}
                onChange={(e) => this.onChangeText("lastName", e)}
                error={this.state.error.lastName}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].date_of_birth}
                required
                value={this.state.dob}
                onChange={(e) => this.onChangeText("dob", e)}
                error={this.state.error.dob}
                type="date"
              />
            </div>
            <div className="col-md-6">
              <Select
                options={[
                  {
                    label: "Male",
                    value: "male",
                  },
                  {
                    label: "Female",
                    value: "female",
                  },
                ]}
                label={language[this.props.defaultLanguage].gender}
                required
                value={this.state.gender}
                onChange={(e) => this.onChangeText("gender", e)}
                error={this.state.error.gender}
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.phone}
                placeholder={
                  language[this.props.defaultLanguage].contact_placeholder
                }
                label={language[this.props.defaultLanguage].contact}
                value={this.state.contact}
                onChange={(e) => this.onChangeText("contact", e)}
                error={this.state.error.contact}
              />
            </div>
            <div className="col-md-12">
              <Input
                leftIcon={icons.identification}
                placeholder={
                  language[this.props.defaultLanguage]
                    .identity_number_placeholder
                }
                label={language[this.props.defaultLanguage].identity_number}
                value={this.state.identityNumber}
                onChange={(e) => this.onChangeText("identityNumber", e)}
                error={this.state.error.identityNumber}
                min={16}
              />
            </div>

            <div className="col-md-6">
              <Select
                options={nationalities}
                label={language[this.props.defaultLanguage].nationality}
                value={this.state.nationality}
                onChange={(e) => this.onChangeText("nationality", e)}
                error={this.state.error.nationality}
              />
            </div>
            <Separator
              title={language[this.props.defaultLanguage].hospitalize_service}
            />
            <div className="col-md-6">
              <Select
                loadOptions={(e) =>
                  this.getServices(true, {
                    q: e,
                  })
                }
                label={language[this.props.defaultLanguage].service}
                value={this.state.service}
                onChange={(e) => this.onChangeText("service", e)}
                error={this.state.error.service}
                isLoading={this.state.isFetchingServices}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].room_number}
                value={this.state.roomNumber}
                onChange={(e) => this.onChangeText("roomNumber", e)}
                error={this.state.error.roomNumber}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].bed_number}
                value={this.state.bedNumber}
                onChange={(e) => this.onChangeText("bedNumber", e)}
                error={this.state.error.bedNumber}
              />
            </div>
            <Separator
              title={language[this.props.defaultLanguage].residential_address}
            />
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewPatient);
