import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import language from "../../language";
import { ROLE_BLOOD_BANK } from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchBloodBanks = async (
  lng = "english",
  { page, limit, type, _id, q, code, source, name }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_BANK,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodBank`,
      params: {
        page: page,
        limit: limit,
        _id,
        q: q ? q : undefined,
        code,
        name,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      const formate = (queryRes?.data?.data || queryRes.data)
        ?.map((el) => {
          if (source === el._id) return;
          return {
            ...el,
            label: el?.code + "-" + el?.name,
            value: el?._id,
          };
        })
        .filter(Boolean);

      return formate;
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteBloodBank = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodBank`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_data);
  } catch (error) {
    toastMessage("error", error);
  }
};
