import React, { Component } from "react";
import { Input, Switch } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";
import { Table } from "../../../Table";
import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import {
  deleteRequestedProducts,
  fetchRequestedProducts,
} from "../../../../utils/queries/request-product";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { Loading } from "../../../Loader";
import { fetchInventories } from "../../../../utils/queries/production/inventoryQuery";
import { CardHeader } from "../../../Header";
import api from "../../../../utils/api";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import { Button } from "../../../Button";
import icons from "../../../../constants/icons";

class ForwardedRequestProducts extends Component {
  state = {
    error: {},
    user: {},
    data: [],
    selectedData: {},
    page: 1,
    limit: 50,
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    if (this.props.requestId) {
      this.getProducts(true);
    }
  };

  getProducts = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const { defaultLanguage, requestId } = this.props;

      const { data, count } = await fetchRequestedProducts(defaultLanguage, {
        page: 1,
        limit: 50,
        forwadedRequest: requestId,
      });

      console.log({
        data,
        requestId,
      });

      this.setState({
        isLoading: false,
        data: data,
        totalPageCount: count,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  renderProductHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].request_number,
        key: "request.requestNo",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/request/bulk/${item.request.requestNo}`),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "productType.name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].number_of_packages,
        key: "numberOfPackages",
      },
      {
        title: language[this.props.defaultLanguage].type,
        key: "type",
      },
    ];
    return headers;
  }

  render() {
    return (
      <div>
        <>
          <Table
            isLoading={this.state.isLoading}
            data={this.state.data}
            headers={this.renderProductHeaders()}
            page={this.state.page}
            limit={this.state.limit}
            totalPageCount={this.state.totalPageCount}
            filters={[
              {
                type: "button",
                title: language[this.props.defaultLanguage].refresh,
                button_type: "button",
                icon: icons.refresh,
                onPress: (item) => this.getProducts(),
              },
            ]}
          />
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ForwardedRequestProducts);
