import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewStatus extends React.Component {
  state = {
    label: "",
    value: "",
    error: {},
    isSubmitting: false,
  };

  componentDidMount = async () => {
    if (this.props._id) {
      this.setState({
        ...this.props,
        type: this.props.type
          ? {
              label: this.props.type,
              value: this.props.type,
            }
          : null,
        shouldAppearError: this.props.shouldAppearError
          ? {
              label:
                this.props.shouldAppearError === true
                  ? language[this.props.defaultLanguage].yes
                  : language[this.props.defaultLanguage].no,
              value: this.props.shouldAppearError,
            }
          : null,
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e && e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { value, label, error, type, shouldAppearError } = this.state;

    if (value === "") {
      error.value = language[this.props.defaultLanguage].value_required;
    }

    if (label === "") {
      error.label = language[this.props.defaultLanguage].label_required;
    }

    if (!type) {
      error.type = language[this.props.defaultLanguage].type_required;
    }

    if (!shouldAppearError) {
      error.shouldAppearError =
        language[this.props.defaultLanguage].field_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { label, value, type, status, code, shouldAppearError } = this.state,
        url = `${API_URL}/rejectionStatus`,
        { _id } = this.props,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        value,
        label,
        type: type.value,
        status,
        code,
        shouldAppearError: shouldAppearError.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].code_placeholder
                }
                label={language[this.props.defaultLanguage].code}
                required
                value={this.state.code}
                onChange={(e) => this.onChangeText("code", e)}
                error={this.state.error.code}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].label_placeholder
                }
                label={language[this.props.defaultLanguage].label}
                required
                value={this.state.label}
                onChange={(e) => this.onChangeText("label", e)}
                error={this.state.error.label}
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].value_placeholder
                }
                label={language[this.props.defaultLanguage].value}
                required
                value={this.state.value}
                onChange={(e) => this.onChangeText("value", e)}
                error={this.state.error.value}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: "REVERSAL",
                    value: "REVERSAL",
                  },
                  {
                    label: "REJECTION",
                    value: "REJECTION",
                  },
                  {
                    label: "RETURN",
                    value: "RETURN",
                  },
                  {
                    label: "SEPARATION",
                    value: "SEPARATION",
                  },
                  {
                    label: "RECEPTION",
                    value: "RECEPTION",
                  },
                  {
                    label: "TRANSFUSION REACTION",
                    value: "TRANSFUSION_REACTION",
                  },
                  {
                    label: "WHOLE BLOOD DONATION",
                    value: "DONATION",
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].type}
                label={language[this.props.defaultLanguage].type}
                value={this.state.type}
                onChange={(e) => this.onChangeText("type", e)}
                error={this.state.error.type}
                required
              />
            </div>
            <div className="col-md-12">
              <Input
                placeholder={language[this.props.defaultLanguage].status}
                label={language[this.props.defaultLanguage].status}
                value={this.state.status}
                onChange={(e) => this.onChangeText("status", e)}
                error={this.state.error.status}
                styles={{ textTransform: "lowercase" }}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={[
                  {
                    label: language[this.props.defaultLanguage].yes,
                    value: true,
                  },
                  {
                    label: language[this.props.defaultLanguage].no,
                    value: false,
                  },
                ]}
                placeholder={language[this.props.defaultLanguage].select}
                label={
                  language[this.props.defaultLanguage]
                    .appears_to_label_as_an_error
                }
                value={this.state.shouldAppearError}
                onChange={(e) => this.onChangeText("shouldAppearError", e)}
                error={this.state.error.shouldAppearError}
                required
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewStatus);
