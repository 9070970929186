import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { DeleteModal, Modal } from "../../Modal/index.js";
import { defineRole } from "../../../utils/handleAccessRoles.js";
import icons from "../../../constants/icons.js";
import { ROLE_DISTRIBUTION, ROLE_REQUEST } from "../../../constants/ROLES.js";
import { Empty } from "../../Empty/index.js";
import handleSearch from "../../../utils/handleSearch.js";
import UpdateShipping from "./UpdateShipping.js";
import ShippingInQueue from "./ShippingInQueue.js";
import Button from "../../Button/Button.js";
import { deleteQueue } from "../../../utils/queries/request/shipping-queue.js";
import { getStorage } from "../../../utils/storage.js";
import { onGenerateShipmentNumber } from "../../../actions/ShipmentNumber.js";
import api from "../../../utils/api";
import toastMessage from "../../../utils/toastMessage";
import moment from "moment";
let copyData = [];

class ListShippingQueue extends React.Component {
  state = {
    user: {},
    modalTitle: "",
    selectedData: {},
    error: "",
    delete_password: "",
    delete_value: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({
      user,
    });
  };
  returnTableHeaders() {
    let headers = [
      {
        title: "#",
        type: "numbering",
      },
      {
        title: language[this.props.defaultLanguage].shipment_number,
        key: "shipmentNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "product",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "inventory.bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].source,
        key: "from.name",
      },
      {
        title: language[this.props.defaultLanguage].destination,
        key: "to.name",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });

    if (selectedData?.donation) {
      this.setState({ donation: selectedData.donation });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
    this.props.dispatch(onGenerateShipmentNumber(false));
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteQueue(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.props.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.props.getData(true);
      }
    );
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  handleUpdateRequest = async (
    isLoading,
    { id, status, shipmentNumber, from, to, donation, inventory, requestNo }
  ) => {
    if (!id)
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].no_data
      );
    try {
      this.setState({
        [isLoading]: true,
      });

      this.props.dispatch(onGenerateShipmentNumber(true));

      const user = await getStorage();

      const API_URL = process.env.REACT_APP_BBMS_BASE_API;

      const options = {
        method: "PUT",
        url: `${API_URL}/shipping-queue`,
        data: {
          id,
          shipmentInfo: {
            status,
            user: user.id,
            date: moment().format("YYYY-MM-DD HH:mm"),
          },
          shipmentNumber,
          to,
          from,
          requestNo,
        },
        headers: {
          Authorization: `Bearer ${this.state.user.token}`,
        },
      };
      await api(options);

      this.setState({
        [isLoading]: false,
      });

      this.props.dispatch(onGenerateShipmentNumber(false));

      toastMessage("success", `Product has been ${status} successfully`);

      // this.handleCloseModal("showPrint");
    } catch (error) {
      console.log(error);
      this.setState({
        [isLoading]: false,
      });
    }
  };

  render() {
    if (this.props.data.length === 0) return;
    return (
      <>
        <Table
          data={this.props.data}
          isSearch
          page={this.props.page}
          limit={this.props.limit}
          search_text={this.props.search_text}
          handleSearch={(e) =>
            handleSearch(this, { copyData, value: e.target.value })
          }
          showAdd
          addButtonText={language[this.props.defaultLanguage].create}
          handleAddPressed={() => {
            this.handleShowModal("showPrint");
          }}
          searching={this.props.searching}
          totalPageCount={this.props.totalPageCount}
          isLoading={this.props.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          actions={[
            // {
            //   canAccess: defineRole({
            //     roles: this.state.user.accessRole,
            //     menu: ROLE_REQUEST,
            //     operation: "update",
            //   }),
            //   name: language[this.props.defaultLanguage].edit,
            //   icon: icons.edit,
            //   onPress: (item) =>
            //     this.handleShowModal("showModal", item.code, item),
            // },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_REQUEST,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          {this.props.selectedData && (
            <UpdateShipping
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              getData={this.props.getData}
              {...this.props.selectedData}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showPrint")}
          show={this.state.showPrint}
          // title="Shipment In Queue"
          showHeaderBottomBorder={false}
          size="lg"
          backdrop="static"
        >
          {this.props.selectedData && (
            <>
              <div className="modal-footer">
                <Button
                  text={language[this.props.defaultLanguage].close}
                  onPress={() => {
                    this.handleCloseModal("showPrint");
                  }}
                  className="btn-default"
                />

                {this.props.data.filter((el) => el.status === "pending")
                  ?.length > 0 && (
                  <Button
                    text={language[this.props.defaultLanguage].transfer}
                    onPress={() => {
                      this.handleUpdateRequest("isTransferring", {
                        ...this.props.selectedData,
                        id: this.props.selectedData._id,
                        to: this.props.selectedData.to._id,
                        from: this.props.selectedData.from._id,
                        status: "transferred",
                      });
                    }}
                    isSubmitting={this.state.isTransferring}
                  />
                )}
              </div>
              <ShippingInQueue
                handleCloseModal={() => {
                  this.handleCloseModal("showPrint");
                }}
                shipmentNumber={this.props.selectedData.shipmentNumber}
                from={this.props.selectedData.from}
                to={this.props.selectedData.to}
                addedBy={this.props.addedBy}
                status={this.props.selectedData.status || "pending"}
                createdAt={this.props.selectedData.createdAt}
                recepientName={this.props.selectedData.recepientName}
              />
            </>
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          {this.state.selectedData.status === "pending" ||
          defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_DISTRIBUTION,
            operation: "admin",
          }) ? (
            <DeleteModal
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showDeleteModal"
              )}
              error={this.state.error.delete_password}
              value={this.state.delete_value}
              onDelete={this.onDelete.bind(this)}
              onChange={(e) => this.onChangeText("delete_value", e)}
              isDeleting={this.props.isDeleting}
              name={this.props.selectedData._id}
              getData={() => this.props.getData(true)}
            />
          ) : (
            <Empty
              title={`You can't delete data with ${this.state.selectedData.status} status`}
            />
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ListShippingQueue);
