import SettingScreen from "../screens/AdminDashboard/SettingScreen";
import AccountScreen from "../screens/AdminDashboard/AccountScreen";
import language from "../language";
import icons from "./icons";
import DonorsScreen from "../screens/AdminDashboard/Donors/DonorsScreen";
import MobilizeScreen from "../screens/AdminDashboard/MobilizeScreen";
import HemovigilanceScreen from "../screens/AdminDashboard/HemovigilanceScreen";
import CentersScreen from "../screens/AdminDashboard/centers/CentersScreen";
import FeedbackScreen from "../screens/AdminDashboard/FeedbackScreen";
import DepartmentsScreen from "../screens/AdminDashboard/DepartmentsScreen";
import HospitalsScreen from "../screens/AdminDashboard/HospitalsScreen";
import DonationsScreen from "../screens/AdminDashboard/Donations/DonationsScreen";
import ClubsScreen from "../screens/AdminDashboard/Clubs/ClubsScreen";
import InventoriesScreen from "../screens/AdminDashboard/Inventory";
import ProductionScreen from "../screens/AdminDashboard/Production";
import ControlPanelScreen from "../screens/AdminDashboard/ControlPanelScreen";
import BloodTestResultsScreen from "../screens/AdminDashboard/Testing/BloodTestResultsScreen";
import {
  ROLE_ALERT,
  ROLE_ANALYTICS,
  ROLE_BLOOD_RECIPIENT,
  ROLE_BLOOD_TEST,
  ROLE_BLOOD_USAGE,
  ROLE_CENTER,
  ROLE_CLUB,
  ROLE_COLLECTIONS,
  ROLE_COMPONENT_SEPARATION,
  ROLE_COMPONENT_TRANSFORMATION,
  ROLE_CONTROL_PANEL,
  ROLE_DONORS,
  ROLE_FEEDBACK,
  ROLE_HOEMOVIGILANCE,
  ROLE_HOME,
  ROLE_HOSPITAL,
  ROLE_INVENTORY,
  ROLE_MAP_INSIGHTS,
  ROLE_MOBILIZATION,
  ROLE_PRODUCTION,
  ROLE_REPORTS,
  ROLE_REQUEST,
  ROLE_SERVICE,
  ROLE_ACCOUNT,
  ROLE_PATIENT,
  ROLE_BLOOD_BANK,
  ROLE_EXCEPTIONAL_CASES,
  ROLE_RETURN,
  ROLE_DISTRIBUTION,
  ROLE_RECEPTION,
} from "./ROLES";
import PatientScreen from "../screens/AdminDashboard/Patient/PatientScreen";
import BloodBankScreen from "../screens/AdminDashboard/BloodBankScreen";
import DonationWithWrongVitalSign from "../screens/AdminDashboard/ExceptionalCases/DonationWithWrongVitalSign";
import ShipmentScreen from "../screens/AdminDashboard/Requests/ShipmentScreen";
import RequestScreen from "../screens/AdminDashboard/Requests/RequestScreen";
import { requestApi } from "./APIS";
import DonorReportsScreen from "../screens/AdminDashboard/Reports/DonorReportScreen";
import DonationReportScreen from "../screens/AdminDashboard/Reports/DonationReportScreen";
import OverviewScreen from "../screens/AdminDashboard/Overview/OverviewScreen";
import ReceptionScreen from "../screens/AdminDashboard/Requests/ReceptionScreen";
import ReturnScreen from "../screens/AdminDashboard/ReturnProduct/ReturnScreen";

const API_ACCESS = "/api/access";

const sidebarMenus = (lng = "english") => {
  try {
    const userLoggedIn = JSON.parse(
      sessionStorage.getItem(process.env.REACT_APP_NAME) || {}
    );

    const { accessRole = [], role } = userLoggedIn;

    const sidebarList = [
      // {
      //   parentPath: "/dashboard",
      //   path: "/overview",
      //   permissionKey: ROLE_REPORTS,
      //   name: language[lng].overview,
      //   icon: icons.home,
      //   element: <OverviewScreen />,
      //   apiActions: {
      //     _POST: [],
      //     _GET: [],
      //     _PUT: [],
      //     _DELETE: [],
      //   },
      //   operations: [{ label: "Read", value: "read" }],
      // },
      {
        parentPath: "/dashboard",
        path: "/reports",
        permissionKey: ROLE_REPORTS,
        name: language[lng].reports,
        icon: icons.reports,
        element: <DonorReportsScreen />,
        apiActions: {
          _POST: [],
          _GET: [],
          _PUT: [],
          _DELETE: [],
        },
        operations: [{ label: "Read", value: "read" }],
        menus: [
          {
            path: "/donors age group",
            name: "Donors Age Group",
            component: <DonorReportsScreen />,
            permissionKey: ROLE_DONORS,
          },
          {
            path: "/blood collection",
            name: "Blood Collection",
            component: <DonationReportScreen />,
            permissionKey: ROLE_DONORS,
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/mobilization",
        name: language[lng].mobilization,
        icon: icons.mobilize,
        permissionKey: ROLE_MOBILIZATION,
        element: <MobilizeScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/donors",
        permissionKey: ROLE_DONORS,
        name: language[lng].donors,
        icon: icons.donors,
        element: <DonorsScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/phlebotomy",
        name: language[lng].phlebotomy,
        icon: icons.donation,
        permissionKey: ROLE_COLLECTIONS,
        element: <DonationsScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/blood testing",
        name: language[lng].blood_testing,
        permissionKey: ROLE_BLOOD_TEST,
        icon: icons.blood_test,
        element: <BloodTestResultsScreen />,
        // apiActions: {
        //   _POST: [bloodTestApi],
        //   _GET: [bloodTestApi],
        //   _PUT: [bloodTestApi],
        //   _DELETE: [bloodTestApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/component production",
        name: language[lng].component_production,
        permissionKey: ROLE_PRODUCTION,
        icon: icons.production,
        element: <ProductionScreen />,
        // apiActions: {
        //   _POST: [inventoryApi],
        //   _GET: [inventoryApi],
        //   _PUT: [inventoryApi],
        //   _DELETE: [inventoryApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
        // menus: [
        //   {
        //     path: "/inventory",
        //     name: language[lng].inventory,
        //     component: <InventoriesScreen />,
        //     type: "inventory",
        //     permissionKey: ROLE_INVENTORY,
        //   },
        //   {
        //     path: "/component separation",
        //     name: language[lng].component_separation,
        //     permissionKey: ROLE_COMPONENT_SEPARATION,
        //     component: <BloodSeparationsScreen />,
        //   },
        //   {
        //     path: "/component transformation",
        //     name: language[lng].component_transformation,
        //     permissionKey: ROLE_COMPONENT_TRANSFORMATION,
        //     component: <BloodTransformationScreen />,
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/inventory",
        name: language[lng].inventory,
        icon: icons.inventory,
        permissionKey: ROLE_INVENTORY,
        element: <InventoriesScreen />,
        // apiActions: {
        //   _POST: [requestApi],
        //   _GET: [requestApi],
        //   _PUT: [requestApi],
        //   _DELETE: [requestApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/distribution",
        name: language[lng].distribution,
        icon: icons.request,
        permissionKey: ROLE_DISTRIBUTION,
        element: <ShipmentScreen />,
        // apiActions: {
        //   _POST: [requestApi],
        //   _GET: [requestApi],
        //   _PUT: [requestApi],
        //   _DELETE: [requestApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/requests",
        name: language[lng].requests,
        icon: "package_2",
        permissionKey: ROLE_REQUEST,
        element: <RequestScreen />,
        apiActions: {
          _POST: [requestApi],
          _GET: [requestApi],
          _PUT: [requestApi],
          _DELETE: [requestApi],
        },
        operations: [
          { label: "Create", value: "create" },
          { label: "Read", value: "read" },
          { label: "Update", value: "update" },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/reception",
        name: language[lng].reception,
        icon: "check",
        permissionKey: ROLE_RECEPTION,
        element: <ReceptionScreen />,
        apiActions: {
          _POST: [requestApi],
          _GET: [requestApi],
          _PUT: [requestApi],
          _DELETE: [requestApi],
        },
        operations: [
          { label: "Create", value: "create" },
          { label: "Read", value: "read" },
          { label: "Update", value: "update" },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/return product",
        name: language[lng].return_product,
        icon: "undo",
        permissionKey: ROLE_RETURN,
        element: <ReturnScreen />,
        apiActions: {
          _POST: [requestApi],
          _GET: [requestApi],
          _PUT: [requestApi],
          _DELETE: [requestApi],
        },
        operations: [
          { label: "Create", value: "create" },
          { label: "Read", value: "read" },
          { label: "Update", value: "update" },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      {
        parentPath: "/dashboard",
        path: "/haemovigilance",
        name: language[lng].haemovigilance,
        permissionKey: ROLE_HOEMOVIGILANCE,
        icon: icons.warehouse,
        element: <HemovigilanceScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
        // menus: [
        //   {
        //     path: "/blood recipients",
        //     name: language[lng].blood_recipient,
        //     component: <RecipientsScreen />,
        //     permissionKey: ROLE_BLOOD_RECIPIENT,
        //   },
        //   {
        //     path: "/blood usage",
        //     name: language[lng].blood_usage,
        //     permissionKey: ROLE_BLOOD_USAGE,
        //     component: <BloodUsageScreen />,
        //   },
        // ],
      },
      // {
      // parentPath: "/dashboard",
      // path: "/home",
      // name: language[lng].home,
      // icon: icons.home,
      // permissionKey: ROLE_HOME,
      // element: <HomeScreen />,
      // apiActions: {
      //   _POST: [accountApi],
      //   _GET: [accessRoleApi, accountApi, departmentApi],
      //   _PUT: [accountApi],
      //   _DELETE: [accountApi],
      // },
      // operations: [
      //   { label: "Create", value: "create" },
      //   { label: "Read", value: "read" },
      //   { label: "Update", value: "update" },
      //   {
      //     label: "Delete",
      //     value: "delete",
      //   },
      // ],
      // },
      // {
      //   parentPath: "/dashboard",
      //   path: "/analytics",
      //   permissionKey: ROLE_ANALYTICS,
      //   name: language[lng].analytics,
      //   icon: icons.analytics,
      //   element: <AnalyticsScreen />,
      //   // apiActions: {
      //   //   _POST: [accountApi],
      //   //   _GET: [accessRoleApi, accountApi, departmentApi],
      //   //   _PUT: [accountApi],
      //   //   _DELETE: [accountApi],
      //   // },
      //   // operations: [
      //   //   { label: "Create", value: "create" },
      //   //   { label: "Read", value: "read" },
      //   //   { label: "Update", value: "update" },
      //   //   {
      //   //     label: "Delete",
      //   //     value: "delete",
      //   //   },
      //   // ],
      // },
      // {
      //   parentPath: "/dashboard",
      //   path: "/map_insights",
      //   name: language[lng].map_insights,
      //   icon: icons.pin_drop,
      //   permissionKey: ROLE_MAP_INSIGHTS,
      //   element: <MapInsightsScreen />,
      //   // apiActions: {
      //   //   _POST: [accountApi],
      //   //   _GET: [accessRoleApi, accountApi, departmentApi],
      //   //   _PUT: [accountApi],
      //   //   _DELETE: [accountApi],
      //   // },
      //   // operations: [
      //   //   { label: "Create", value: "create" },
      //   //   { label: "Read", value: "read" },
      //   //   { label: "Update", value: "update" },
      //   //   {
      //   //     label: "Delete",
      //   //     value: "delete",
      //   //   },
      //   // ],
      // },

      // {
      //   parentPath: "/dashboard",
      //   path: "/alerts",
      //   name: language[lng].alerts,
      //   icon: icons.alerts,
      //   element: <AlertsScreen />,
      //   permissionKey: ROLE_ALERT,
      //   // apiActions: {
      //   //   _POST: [accountApi],
      //   //   _GET: [accessRoleApi, accountApi, departmentApi],
      //   //   _PUT: [accountApi],
      //   //   _DELETE: [accountApi],
      //   // },
      //   // operations: [
      //   //   { label: "Create", value: "create" },
      //   //   { label: "Read", value: "read" },
      //   //   { label: "Update", value: "update" },
      //   //   {
      //   //     label: "Delete",
      //   //     value: "delete",
      //   //   },
      //   // ],
      // },
      {
        parentPath: "/dashboard",
        path: "/centers",
        name: language[lng].centers,
        icon: icons.site,
        permissionKey: ROLE_CENTER,
        element: <CentersScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/services",
        name: language[lng].services,
        icon: icons.department,
        permissionKey: ROLE_SERVICE,
        element: <DepartmentsScreen />,
      },
      {
        parentPath: "/dashboard",
        path: "/entities or hospitals",
        name: language[lng].hospitals,
        icon: icons.hospitals,
        permissionKey: ROLE_HOSPITAL,
        element: <HospitalsScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/feedback",
        name: language[lng].feedback,
        permissionKey: ROLE_FEEDBACK,
        icon: icons.feedback,
        element: <FeedbackScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/accounts",
        name: language[lng].accounts,
        icon: icons.users,
        permissionKey: ROLE_ACCOUNT,
        element: <AccountScreen />,
        // apiActions: {
        //   _POST: [accountApi],
        //   _GET: [accessRoleApi, accountApi, departmentApi],
        //   _PUT: [accountApi],
        //   _DELETE: [accountApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/clubs",
        name: language[lng].clubs,
        permissionKey: ROLE_CLUB,
        icon: icons.clubs,
        element: <ClubsScreen />,
        // apiActions: {
        //   _POST: [clubApi],
        //   _GET: [clubApi],
        //   _PUT: [clubApi],
        //   _DELETE: [clubApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/control panel",
        name: language[lng].control_panel,
        permissionKey: ROLE_CONTROL_PANEL,
        icon: icons.control_panel,
        element: <ControlPanelScreen />,
        // apiActions: {
        //   _POST: [collectionTypeApi, equipmentApi],
        //   _GET: [collectionTypeApi, equipmentApi],
        //   _PUT: [collectionTypeApi, equipmentApi],
        //   _DELETE: [collectionTypeApi, equipmentApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/patient",
        name: language[lng].patients,
        permissionKey: ROLE_PATIENT,
        icon: icons.users,
        element: <PatientScreen />,
        // apiActions: {
        //   _POST: [collectionTypeApi, equipmentApi],
        //   _GET: [collectionTypeApi, equipmentApi],
        //   _PUT: [collectionTypeApi, equipmentApi],
        //   _DELETE: [collectionTypeApi, equipmentApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/bloodBank",
        name: language[lng].blood_banks,
        permissionKey: ROLE_BLOOD_BANK,
        icon: icons.blood_test,
        element: <BloodBankScreen />,
        // apiActions: {
        //   _POST: [collectionTypeApi, equipmentApi],
        //   _GET: [collectionTypeApi, equipmentApi],
        //   _PUT: [collectionTypeApi, equipmentApi],
        //   _DELETE: [collectionTypeApi, equipmentApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
      {
        parentPath: "/dashboard",
        path: "/exceptional_cases",
        name: language[lng].exceptional_cases,
        permissionKey: ROLE_EXCEPTIONAL_CASES,
        icon: icons.help,
        element: <DonationWithWrongVitalSign />,
        // apiActions: {
        //   _POST: [collectionTypeApi, equipmentApi],
        //   _GET: [collectionTypeApi, equipmentApi],
        //   _PUT: [collectionTypeApi, equipmentApi],
        //   _DELETE: [collectionTypeApi, equipmentApi],
        // },
        // operations: [
        //   { label: "Create", value: "create" },
        //   { label: "Read", value: "read" },
        //   { label: "Update", value: "update" },
        //   {
        //     label: "Delete",
        //     value: "delete",
        //   },
        // ],
      },
    ].filter((el) => {
      if (role === "superAdmin") return el;

      let accessedMenus = [];

      accessRole?.map((aRole) => {
        const { permissions = {} } = aRole;
        const { menus = {} } = permissions;

        accessedMenus = accessedMenus.concat(menus);
      });

      if (accessedMenus.includes(el.permissionKey)) return el;
    });

    return [
      ...sidebarList,
      {
        parentPath: "/dashboard",
        path: "/settings",
        name: language[lng].settings,
        icon: icons.setting,
        element: <SettingScreen />,
      },
    ];
  } catch (error) {}
};

export default sidebarMenus;
