import React, { Component } from "react";
import language from "../../../../language";
import { connect } from "react-redux";
import { Table } from "../../../Table";
import { getStorage } from "../../../../utils/storage";
import { Modal, DeleteModal } from "../../../Modal";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import {
  deleteSampleResult,
  fetchSampleCollectedResult,
} from "../../../../utils/queries/sample-collected-result";
import toastMessage from "../../../../utils/toastMessage";
import ABO from "../ABO";
import { Select } from "../../../Input";
import OtherTest from "../OtherTest";

const allowedTestRequired = [
  {
    label: "ABO-Rh",
    value: "ABO-Rh",
  },
  {
    label: "HCT",
    value: "HCT",
  },
  {
    label: "HgB",
    value: "HgB",
  },
  {
    label: "PLT Count",
    value: "PLT Count",
  },
];

class SampleCollected extends Component {
  state = {
    error: {},
    user: {},
    data: [],
    inventoryInfo: {},
    selectedData: {},
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    if (this.props.request) {
      this.getData(true);
    }
  };

  getData = async (isLoading, search = {}) => {
    try {
      this.setState({ isLoading });

      const { defaultLanguage, request } = this.props;

      const { data, count } = await fetchSampleCollectedResult(
        defaultLanguage,
        {
          page: 1,
          limit: 50,
          request,
          ...search,
        }
      );

      this.setState({
        isLoading: false,
        data,
        totalPageCount: count,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  renderTable() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].type,
        key: "type",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].result,
        key: "result",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];
    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteSampleResult(this.props.defaultLanguage, {
        id: this.state.selectedData._id,
      });

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });

    if (modal === "showModal") {
      const type = allowedTestRequired.find(
        (el) => el.value?.toUpperCase() === selectedData?.type?.toUpperCase()
      );
      this.setState({
        allowedTest: type,
      });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(field, e) {
    let { error } = this.state;
    let inputValue = e?.target ? e?.target?.value : e;

    delete error[field];

    this.setState({
      [field]: inputValue,
      error,
    });
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      type: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };
  render() {
    const canDelete = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "delete",
    });

    const canUpdate = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "update",
    });
    return (
      <div className="card-body">
        <Table
          data={this.state.data}
          headers={this.renderTable()}
          showAdd
          isSearch
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].sample_collection_result
          )}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.type, item)
          }
          actions={[
            {
              canAccess: canUpdate,
              name: language[this.props.defaultLanguage].edit,
              onPress: (item) => {
                this.handleShowModal("showModal", item.type, item);
              },
            },
            {
              canAccess: canDelete,
              name: language[this.props.defaultLanguage].delete_,
              onPress: (item) =>
                this.handleShowModal("showDeleteModal", item._id, item),
            },
          ]}
        />

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          showHeaderBottomBorder={false}
          title={
            this.state.modalTitle ||
            language[this.props.defaultLanguage].sample_collection_result
          }
        >
          <div className="card-body">
            <Select
              options={allowedTestRequired}
              value={this.state.allowedTest}
              onChange={(e) => this.onChangeText("allowedTest", e)}
            />
          </div>
          <hr />
          {this.state.allowedTest?.value === "ABO-Rh" ? (
            <ABO
              exam={this.state.allowedTest?.value}
              requestId={this.props.request}
              patientId={this.props.patientId}
              getData={() => this.getData(true)}
              handleCloseModal={() => this.handleCloseModal("showModal")}
              {...this.state.selectedData}
            />
          ) : (
            this.state.allowedTest?.value && (
              <OtherTest
                exam={this.state.allowedTest?.value}
                requestId={this.props.request}
                patientId={this.props.patientId}
                getData={() => this.getData(true)}
                handleCloseModal={() => this.handleCloseModal("showModal")}
                {...this.state.selectedData}
              />
            )
          )}
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state?.selectedData?.code}
            getData={this.getData.bind(this, true)}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(SampleCollected);
