import React from "react";
import { connect } from "react-redux";
import { fetchReception } from "../../utils/queries/reception/receptionQuery.js";
import { ROLE_RECEPTION } from "../../constants/ROLES.js";
import Table from "../Table/Table.js";
import { getStorage } from "../../utils/storage.js";
import language from "../../language";
import { defineRole } from "../../utils/handleAccessRoles.js";
import icons from "../../constants/icons.js";

class Reception extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchReception(defaultLanguage, {
        page,
        limit,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle) {
    this.setState({
      [modal]: true,
      modalTitle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].product,
        key: "inventory.code",
        styles: {
          textTransform: "lowercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "inventory.bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].source_stock,
        key: "inventory.sourceStock.name",
      },
      {
        title: language[this.props.defaultLanguage].current_stock,
        key: "inventory.stock.name",
      },
      {
        title: language[this.props.defaultLanguage].received,
        key: "addedBy.pin",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true, this.state.filters),
            },
          ]}
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_RECEPTION,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: this.handleShowModal.bind(
                this,
                "showModal",
                language[this.props.defaultLanguage].edit
              ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_RECEPTION,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Reception);
