import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import requestQuery from "../../../utils/queries/requestQuery.js";
import CardHeader from "../../Header/CardHeader.js";
import { Separator } from "../../Separator";
import { Tabs } from "../../Tabs";
import moment from "moment";
import Products from "./products";
import capitalizeText from "../../../utils/capitalizeText.js";
import ABO from "./ABO/index.js";
import { fetchSampleCollectedResult } from "../../../utils/queries/sample-collected-result.js";
import toastMessage from "../../../utils/toastMessage.js";
import Select from "../../Input/Select.js";
import formatSelectData from "../../../utils/formatSelectData.js";
import LabResults from "../../Patient/lab-results/index.js";
import SampleCollected from "./SampleCollected/index.js";
import { getStorage } from "../../../utils/storage.js";
import ForwardedList from "./forward/List";
import Input from "../../Input/Input.js";
import Button from "../../Button/Button.js";
import { defineRole } from "../../../utils/handleAccessRoles.js";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../constants/ROLES.js";
import api from "../../../utils/api/index.js";

class RequestInfo extends React.Component {
  state = {
    error: {},
    distributors: [],
    data: [],
    csvData: [],
    sampleCollected: {},
    testedRequired: [],
    user: {},
    sampleCollectionDate: "",
    sampleCollectedBy: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    this.getRequestInfo(true);
  };

  componentDidUpdate(nextProps) {
    if (this.props.isRefreshing !== nextProps.isRefreshing) {
      this.getRequestInfo(true);
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];
    this.setState({ [field]: e.target.value });
  }

  returnFilters() {
    const { requestId } = this.props;
    return {
      id: requestId,
    };
  }

  getRequestInfo = async (isLoading) => {
    try {
      const { defaultLanguage, requestNo } = this.props;

      this.setState({ isLoading });

      const { data } = await requestQuery(defaultLanguage, {
        requestNo,
      });
      console.log("reques info", data);

      const allowedTestedRequired = [];
      data?.[0]?.products?.forEach((el) => {
        for (let pEl of el.allowedTestRequired || []) {
          if (!allowedTestedRequired.includes(pEl)) {
            allowedTestedRequired.push(pEl);
          }
        }
      });

      this.setState({
        data,
        isLoading: false,
        allowedTestedRequired,
        sampleCollectionDate:
          data?.length > 0
            ? moment(data[0].sampleCollectionDate).format("YYYY-MM-DD")
            : "",
        sampleCollectedBy: data?.length > 0 ? data[0].sampleCollectedBy : "",
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  renderInfoPlainText(key, value) {
    return (
      <div className="d-flex">
        <span style={{ marginRight: "0.3rem" }}>{key}: </span>{" "}
        <span>
          <b>{value}</b>
        </span>
      </div>
    );
  }

  renderProductRequested() {
    const requestedProducts = formatSelectData(
      this.state.data?.[0]?.products || [],
      "name",
      "_id"
    );
    return (
      <Select
        options={requestedProducts}
        label={language[this.props.defaultLanguage].requested_products}
        value={requestedProducts}
        isMulti
      />
    );
  }

  handleUpdateRequest = async () => {
    try {
      this.setState({ isSubmitting: true });

      const { data, sampleCollectionDate, user } = this.state;

      const API_URL = process.env.REACT_APP_BBMS_BASE_API;

      const options = {
        method: "PUT",
        url: `${API_URL}/bloodrequest`,
        data: {
          id: data[0]._id,
          sampleCollectionDate,
          sampleCollectedBy: user.id,
          status: "sampleCollected",
        },
      };

      await api(options);
      this.setState({
        isSubmitting: false,
      });

      toastMessage(
        "success",
        language[this.props.defaultLanguage].update_data_success
      );

      this.getRequestInfo(true);
    } catch (error) {
      toastMessage("error", error);
      this.setState({
        isLoading: false,
      });
    }
  };
  render() {
    const canAddSample = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "create",
    });
    return (
      <>
        <div style={{ marginBottom: "1rem" }} />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, marginBottom: "1rem" }}>
            {this.state.data.length > 0 && (
              <>
                <div className={`badge badge-${this.state.data[0].status}`}>
                  {language[this.props.defaultLanguage].status}:{" "}
                  {capitalizeText(this.state.data[0].status)}
                </div>
                {this.state.data[0].shipmentNumber && (
                  <p className="text-primary">
                    {language[this.props.defaultLanguage].shipment_number}:{" "}
                    {this.state.data[0].shipmentNumber}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        {this.state.data.length > 0 && (
          <>
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      {this.state.data[0].patient && (
                        <>
                          <Separator
                            title="Patient Information"
                            titleClassName="text-primary"
                          />
                          <div className="col-md-3">
                            <Input
                              value={
                                this.state.data[0].patient.firstName +
                                " " +
                                this.state.data[0].patient.lastName
                              }
                              label={
                                language[this.props.defaultLanguage].patient
                              }
                              disabled
                            />
                          </div>
                          <div className="col-md-3">
                            <Input
                              value={this.state.data[0].patient.patientId}
                              label={
                                language[this.props.defaultLanguage].patient_id
                              }
                              disabled
                            />
                          </div>
                          <div className="col-md-3">
                            <Input
                              value={this.state.data[0].patient.gender}
                              label={
                                language[this.props.defaultLanguage].gender
                              }
                              disabled
                            />
                          </div>
                          <div className="col-md-3">
                            <Input
                              value={this.state.data[0].patient.dob}
                              label={
                                language[this.props.defaultLanguage]
                                  .date_of_birth
                              }
                              disabled
                            />
                          </div>
                        </>
                      )}

                      <>
                        <Separator
                          title="Request Information"
                          titleClassName="text-primary"
                        />
                        <div className="col-md-3">
                          <Input
                            value={this.state.data[0].requestNo}
                            label={
                              language[this.props.defaultLanguage]
                                .request_number
                            }
                            disabled
                          />
                        </div>
                        <div className="col-md-3">
                          <Input
                            value={
                              this.state.data[0].requestType ? "YES" : "NO"
                            }
                            label={language[this.props.defaultLanguage].urgent}
                            disabled
                          />
                        </div>
                        <div className="col-md-3">
                          <Input
                            value={this.state.data[0].account?.name}
                            label={
                              language[this.props.defaultLanguage].requested_by
                            }
                            disabled
                          />
                        </div>
                      </>

                      <>
                        <Separator
                          title="Sample Collection"
                          titleClassName="text-primary"
                        />
                        <div className="col-md-3">
                          <Input
                            value={this.state.sampleCollectionDate}
                            label={language[this.props.defaultLanguage].date}
                            disabled={!canAddSample}
                            type="date"
                            onChange={(e) =>
                              this.onChangeText("sampleCollectionDate", e)
                            }
                            error={this.state.error.sampleCollectionDate}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                        {this.state.data[0].sampleCollectedBy !== "" && (
                          <div className="col-md-3">
                            <Input
                              value={this.state.data[0].sampleCollectedBy?.pin}
                              label={
                                language[this.props.defaultLanguage]
                                  .sample_collected_by
                              }
                              disabled
                            />
                          </div>
                        )}
                        {canAddSample && (
                          <div className="col-md-12">
                            <Button
                              text={language[this.props.defaultLanguage].save}
                              onPress={this.handleUpdateRequest.bind(this)}
                              isSubmitting={this.state.isSubmitting}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {this.state.sampleCollected?.bloodGroup && (
                      <h1
                        style={{ color: "var(--primary-color)", fontSize: 60 }}
                      >
                        {this.state.sampleCollected?.bloodGroup}
                      </h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body">
                <div className="col-md-12">{this.renderProductRequested()}</div>
              </div>
            </div>
          </>
        )}
        {this.state.data.length > 0 && (
          <div className="card">
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].products,
                  data: (
                    <Products
                      from={this.state.data[0]?.hospital}
                      to={this.state.user.allowedBloodBank}
                      allowedBloodBankName={
                        this.state.user.allowedBloodBankName
                      }
                      requestNo={this.state.data[0].requestNo}
                      products={this.state.data[0].products}
                      requestId={this.state.data[0]._id}
                      patientId={this.state.data?.[0].patient?._id}
                      sampleCollected={this.state.sampleCollected}
                      shipmentNumber={this.data?.[0].shipmentNumber}
                      handleRefresh={(b) => {
                        if (b) {
                          this.getRequestInfo(true);
                        }
                      }}
                    />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].lab_results,
                  data: (
                    <LabResults
                      patientId={this.state.data?.[0].patient?._id}
                      isFromRequest
                    />
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].forwarded,
                  data: <ForwardedList requestId={this.state.data?.[0]?._id} />,
                },
              ]}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(RequestInfo);
