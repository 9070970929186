import React, { Component } from "react";
import { Input, Select, Switch } from "../../../Input";
import language from "../../../../language";
import { connect } from "react-redux";
import { Table } from "../../../Table";
import { getStorage } from "../../../../utils/storage";
import toastMessage from "../../../../utils/toastMessage";
import {
  deleteRequestedProducts,
  fetchRequestedProducts,
} from "../../../../utils/queries/request-product";
import bloodDonationQuery from "../../../../utils/queries/bloodDonationQuery";
import { Loading } from "../../../Loader";
import { fetchInventories } from "../../../../utils/queries/production/inventoryQuery";
import { Modal, DeleteModal } from "../../../Modal";
import { CardHeader } from "../../../Header";
import PrintableLabel from "../../../Production/ComponentLabeling/printableLabel";
import api from "../../../../utils/api";
import ApproverABO from "../Approver";
import { defineRole } from "../../../../utils/handleAccessRoles";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../../../constants/ROLES";
import { Button } from "../../../Button";
import formatSelectData from "../../../../utils/formatSelectData";
import {
  generateRandomNumber,
  generateRandomString,
} from "../../../../utils/random";

class ForwardRequest extends Component {
  state = {
    error: {},
    donationNumber: "",
    product: "",
    user: {},
    products: [],
    inventoryInfo: {},
    selectedData: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    if (this.props.requestId) {
      this.getProducts(true);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sampleCollected !== this.props.sampleCollected) {
      this.setState({ sampleCollected: this.props.sampleCollected });
    }
  }

  getProducts = async (isLoading) => {
    try {
      this.setState({ isLoading });

      const { defaultLanguage, requestId } = this.props;

      const { data } = await fetchRequestedProducts(defaultLanguage, {
        page: 1,
        limit: 50,
        request: requestId,
      });

      this.setState({
        isLoading: false,
        products: data,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    let inputValue = e && e?.target ? e.target.value : e;

    delete error[field];
    delete error["errorMessage"];

    this.setState({ error: error, [field]: inputValue });
  };

  validateForm() {
    let { error } = this.state;
    const { sampleCollected, requestId, requestedProducts } = this.props;

    if (requestId === "") {
      error.errorMessage = "Request is missing";
    }

    if (requestedProducts === "") {
      error.requestedProducts =
        language[this.props.defaultLanguage].product_required;
    }

    this.setState({ error });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      await this.validateForm();

      if (Object.keys(this.state.error).length > 0) return;

      this.setState({ isSubmitting: true });

      let { sampleCollected, from, to, requestId } = this.props,
        { requestedProducts } = this.state,
        user = await getStorage(),
        url = `${process.env.REACT_APP_BBMS_BASE_API}/bloodrequest`,
        method = "POST";

      let requestNo =
        generateRandomString(4).toUpperCase() +
        "-" +
        generateRandomNumber(0, 1000000);

      let bulkBody = {
        requestNo,
        hospital: from._id,
        bloodBank: to._id,
        account: user.id,
        type: "bulk",
      };

      const { data } = await api({
        method,
        url,
        data: bulkBody,
      });

      let requiredBody = {};

      for (let [i, el] of requestedProducts.entries()) {
        requiredBody = {
          type: "bulk",
          request: data._id,
          productType: el.value,
          numberOfPackages: 1,
          bloodGroup: sampleCollected.result,
          forwadedRequest: requestId,
        };

        const options = {
          method,
          url: `${process.env.REACT_APP_BBMS_BASE_API}/product-request`,
          data: requiredBody,
        };

        await api(options);

        if (i === requestedProducts.length - 1) {
          this.setState({ isSubmitting: false });
          this.props.handleCloseModal();
        }
      }
    } catch (error) {
      this.setState({ isSubmitting: false });
      console.log(error);

      return {
        failed: true,
        success: false,
        error: error?.response?.data?.error,
      };
    }
  };

  render() {
    return (
      <div>
        <div className="card-body">
          {this.state.errorMessage && (
            <div className="alert alert-danger mb-3">
              {this.state.errorMessage}
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <Input
                label={language[this.props.defaultLanguage].request_no}
                value={this.props.requestNo}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].source}
                value={this.props?.from?.name}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                label={language[this.props.defaultLanguage].destination}
                value={this.props.to?.name}
                disabled
              />
            </div>
            <div className="col-md-12">
              <Select
                options={formatSelectData(this.props.products, "name", "_id")}
                label={language[this.props.defaultLanguage].request_product}
                value={this.state.requestedProducts}
                isMulti
                onChange={(e) => this.onChangeText("requestedProducts", e)}
              />
            </div>
            <div className="col-md-12">
              <Button
                text={language[this.props.defaultLanguage].submit}
                onPress={this.onSubmit.bind(this)}
                isSubmitting={this.state.isSubmitting}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ForwardRequest);
