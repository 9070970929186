import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { CSVLink } from "react-csv";
import {
  fetchPatients,
  deletePatient,
} from "../../../utils/queries/patient.js";
import NewRecipient from "./NewResult.js";
import { DeleteModal, Modal } from "../../Modal/index.js";
import { defineRole } from "../../../utils/handleAccessRoles.js";
import icons from "../../../constants/icons.js";
import { getStorage } from "../../../utils/storage.js";
import {
  ROLE_PATIENT,
  ROLE_PATIENT_LAB_RESULT,
} from "../../../constants/ROLES.js";
import {
  deletePatientLabResult,
  fetchPatientLabResult,
} from "../../../utils/queries/patient-lab-result.js";

class PatientLabResults extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, patientId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchPatientLabResult(defaultLanguage, {
        page,
        limit,
        patient: patientId,
        ...search,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].hospital,
        key: "hospital.name",
      },
      {
        title: language[this.props.defaultLanguage].patient_id,
        key: "patient.patientId",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/patients/${item.patient._id}/${item.patient.identityNumber}`),
      },
      {
        title: language[this.props.defaultLanguage].exam,
        key: "exam",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].result,
        key: "result",
      },
      {
        title: language[this.props.defaultLanguage].added_by,
        key: "addedBy.name",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      !this.props.isFromRequest && {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ].filter(Boolean);

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deletePatientLabResult(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      console.log(error);
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    return (
      <>
        <Table
          data={this.state.data}
          isSearch={!this.props.isFromRequest}
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          showAdd={
            !this.props.isFromRequest &&
            defineRole({
              roles: this.state.user.accessRole,
              menu: ROLE_PATIENT_LAB_RESULT,
              operation: "create",
            })
          }
          addButtonText={language[this.props.defaultLanguage].add}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            language[this.props.defaultLanguage].add
          )}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PATIENT_LAB_RESULT,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              onPress: this.handleShowModal.bind(
                this,
                "showModal",
                language[this.props.defaultLanguage].edit
              ),
              icon: icons.edit,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_PATIENT_LAB_RESULT,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewRecipient
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.firstName}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Patient Lab Result" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PatientLabResults);
