import React from "react";
import { connect } from "react-redux";
// import "./styles.css";
import toastMessage from "../../utils/toastMessage";
import language from "../../language";
import { getStorage } from "../../utils/storage";
import UserImg from "../../assets/userPhotoPlaceholder.png";
import { Loading } from "../Loader";
import { getDonorBadgeInfo } from "../../utils/bloodGroupInfo";
import { Input } from "../Input";
import { fetchPatients } from "../../utils/queries/patient";
import { Separator } from "../Separator";

class PatientProfile extends React.Component {
  state = {
    isLoading: true,
    data: {},
  };

  componentDidMount() {
    this.getData(true);
  }

  getData = async (isLoading) => {
    try {
      const user = await getStorage();
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const data = await fetchPatients(defaultLanguage, {
        page,
        limit,
        id: this.props.patientId || user.id,
      });

      let res = {};

      if (data?.length > 0) {
        res = data[0];
      }

      this.setState({
        data: res,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    }
  };

  renderProfileInfo() {
    const data = this.state.data;

    if (!data._id) return <></>;
    return (
      <div className="donor-profile-info-container">
        <div className="row">
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.patientId}
              label={language[this.props.defaultLanguage].patient_id}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.firstName}
              label={language[this.props.defaultLanguage].first_name}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.lastName}
              label={language[this.props.defaultLanguage].last_name}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.gender}
              label={language[this.props.defaultLanguage].gender}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.dob}
              label={language[this.props.defaultLanguage].date_of_birth}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.nationality}
              label={language[this.props.defaultLanguage].nationality}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.identityNumber}
              label={language[this.props.defaultLanguage].id_number}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.email}
              label={language[this.props.defaultLanguage].email_address}
            />
          </div>
          <div className="col-md-4">
            <Input
              disabled
              value={this.state.data.phoneNumber}
              label={language[this.props.defaultLanguage].phone_number}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Input
              disabled
              value={`${this.state.data.province} ${this.state.data.district} ${this.state.data.sector} ${this.state.data.cell} ${this.state.data.village}`}
              label={language[this.props.defaultLanguage].residential_address}
              textarea
            />
          </div>
        </div>
      </div>
    );
  }

  renderBloodGroup() {
    const { data } = this.state;

    const { color, bloodGroup } = getDonorBadgeInfo(data.bloodGroup);
    return <span style={{ color }}>{bloodGroup}</span>;
  }

  handleShowModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  renderProfile = () => {
    return (
      <div className="donor-profile-container mb-3">
        <div className="card-body">
          {this.state.isLoading ? (
            <center>
              <Loading />
            </center>
          ) : (
            <div className="row">
              <div
                className={this.props.imageCol || "col-md-2"}
                style={{
                  marginBottom: "1.5rem",
                }}
              >
                <div className="donor-profile-image-container">
                  <img
                    src={
                      this?.state?.data.imageUrl
                        ? `data:image/png;base64,${this?.state?.data.imageUrl}`
                        : UserImg
                    }
                  />
                </div>
              </div>
              <div className={this.props.infoCol || "col-md-8"}>
                {this.renderProfileInfo()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return <>{this.renderProfile()}</>;
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PatientProfile);
