import React from "react";
import { connect } from "react-redux";
import { Input } from "../Input";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import language from "../../language";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
class MyProfile extends React.Component {
  state = {
    email: "",
    displayName: "",
    phoneNumber: "",
    error: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();
    this.setState({ ...user, centerRole, centerSiteRole });
  };

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].name}
                required
                value={this.state.displayName}
                onChange={(e) => this.onChangeText("displayName", e)}
                error={this.state.error.displayName}
                leftIcon={icons.user}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].username_placeholder
                }
                label={language[this.props.defaultLanguage].username}
                required
                value={this.state.username}
                onChange={(e) => this.onChangeText("username", e)}
                error={this.state.error.username}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                placeholder="Enter email address"
                label="Email Address"
                required
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].pin}
                required
                value={this.state.pin}
                onChange={(e) => this.onChangeText("pin", e)}
                error={this.state.error.pin}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                placeholder={
                  language[this.props.defaultLanguage].name_placeholder
                }
                label={language[this.props.defaultLanguage].role}
                required
                value={this.state.role}
                onChange={(e) => this.onChangeText("role", e)}
                error={this.state.error.role}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                label={language[this.props.defaultLanguage].stock}
                required
                value={this.state.stockName}
                onChange={(e) => this.onChangeText("stockName", e)}
                error={this.state.error.stockName}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                label={language[this.props.defaultLanguage].facility}
                required
                value={this.state.hospitalName}
                onChange={(e) => this.onChangeText("hospitalName", e)}
                error={this.state.error.hospitalName}
                disabled
              />
            </div>
            <div className="col-md-4">
              <Input
                label={
                  language[this.props.defaultLanguage]
                    .should_request_product_from
                }
                required
                value={this.state.allowedBloodBankName}
                onChange={(e) => this.onChangeText("allowedBloodBankName", e)}
                error={this.state.error.allowedBloodBankName}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(MyProfile);
