import { Route, Routes, Outlet } from "react-router-dom";
import ChangePasswordScreen from "../screens/Authentication/ChangePasswordScreen";
import ResetPasswordScreen from "../screens/Authentication/ResetPasswordScreen";
import VerifyPhoneEmailScreen from "../screens/Authentication/VerifyPhoneEmailScreen";
import HomeDashboardScreen from "../screens/AdminDashboard";
import LoginScreen from "../screens/Authentication/Admin/LoginScreen";
import MFAVerifyScreen from "../screens/Authentication/Admin/MFAVerifyScreen";

const routes = [
  {
    path: "/admin-login",
    element: <LoginScreen />,
  },
  {
    path: "/admin-login/mfa/:token",
    element: <MFAVerifyScreen />,
  },
  {
    path: "/forgot-password",
    element: <ChangePasswordScreen />,
  },
  {
    path: "/forgot-password/:type",
    element: <ChangePasswordScreen />,
  },
  {
    path: "/forgot-password/:type/:email",
    element: <ChangePasswordScreen />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/reset-password/:type/:token",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/verify_phone_email",
    element: <VerifyPhoneEmailScreen />,
  },
  {
    path: "/dashboard/*",
    element: <HomeDashboardScreen />,
  },
];

export default () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Outlet />
    </>
  );
};
