import React from "react";

import { connect } from "react-redux";
import AuthFormLayout from "../../Layout/AuthFormLayout";
import { Input } from "../Input";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { Slides } from "../Slides";
import screened from "../../assets/screened-colored.png";
import giveBlood from "../../assets/giveBlood.png";
import register from "../../assets/register.png";
import language from "../../language";
import validateEmail from "../../utils/validateEmail";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import icons from "../../constants/icons";
import { setStorage } from "../../utils/storage";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class Login extends React.Component {
  state = {
    username: "",
    isSubmitting: false,
    error: {},
    password: "",
    tfaCode: "",
    isTFAActive: false,
    token: "",
  };

  onChangeText = (field, e) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: e?.target?.value });

    if (field === "username" || field === "password") {
      this.setState({ isTFAActive: false });
    }
  };

  validateForm = () => {
    let { error, username, password } = this.state;
    if (username === "") {
      error.username = language[this.props.defaultLanguage].email_required;
    } else if (username.includes("@") && !validateEmail(username)) {
      error.username =
        language[this.props.defaultLanguage].invalid_email_required;
    }

    if (password === "") {
      error.password = language[this.props.defaultLanguage].password_required;
    }

    this.setState({ error });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { username, password } = this.state;

      let url = `${API_URL}/authentication/account`;

      if (username === "info@huzalabs.com") {
        url = `${API_URL}/authentication/admin`;
      }

      const options = {
        method: "POST",
        url,
        data: {
          email: username?.replace(/\s+/g, ""),
          password,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });
          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          toastMessage("success", data.data.message);

          if (data.data.isTFAActive) {
            return this.setState({ isTFAActive: true, token: data.data.token });
          }

          if (data.data.role === "superAdmin") {
            return this.handleDirectLogin(data.data);
          }

          return (window.location.href = `/admin-login/mfa/${data.data.token}`);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isSubmitting: false });

          toastMessage("error", error);
        });
    }
  };

  handleLoginWithEmail = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isLoginEmail: true });

      const { username, password } = this.state;

      let url = `${API_URL}/authentication/account`;

      if (username === "info@huzalabs.com") {
        url = `${API_URL}/authentication/admin`;
      }

      const options = {
        method: "POST",
        url,
        data: {
          email: username?.replace(/\s+/g, ""),
          password,
          skipTFA: true,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isLoginEmail: false });
          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          toastMessage("success", data.data.message);

          if (data.data.role === "superAdmin") {
            return this.handleDirectLogin(data.data);
          }

          return (window.location.href = `/admin-login/mfa/${data.data.token}`);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoginEmail: false });

          toastMessage("error", error);
        });
    }
  };

  validateTFAForm = () => {
    let { error, tfaCode } = this.state;
    if (tfaCode === "") {
      error.tfaCode = language[this.props.defaultLanguage].code_required;
    }

    this.setState({ error });
  };

  handleDirectLogin = async (data) => {
    const { username } = this.state;
    let userInfo = {
      ...data,
      token: data?.token,
      role: data.role,
      email: username,
    };

    if (data?.id) {
      userInfo.id = data?.id;
    }

    if (data.name) {
      userInfo.displayName = data.name;
    } else {
      userInfo.displayName = username.split("@")[0];
    }

    await setStorage(userInfo);
    window.location.href = "/dashboard/donors";
  };

  onVerifyTFA = async (e) => {
    e.preventDefault();
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({ isSubmitting: true });

      const { tfaCode, token } = this.state;

      let url = `${API_URL}/2fa-google-auth`;

      const options = {
        method: "PUT",
        url,
        data: {
          code: tfaCode?.replace(/\s+/g, ""),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({ isSubmitting: false });
          if (data.data.status === "error")
            return toastMessage("error", data.data.message);

          toastMessage("success", data.data.message);

          this.handleDirectLogin(data.data);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <AuthFormLayout>
        <div className="row">
          <div className="col-md-7 hidden-sm hidden-xs">
            <div className="auth-form-desc">
              <Slides
                data={[
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_donation_tracking,
                    description:
                      language[this.props.defaultLanguage]
                        .simplifying_donation_process,
                    image: giveBlood,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage].donation_analytics,
                    description:
                      language[this.props.defaultLanguage]
                        .comprehensive_analytics,
                    image: register,
                  },
                  {
                    title:
                      language[this.props.defaultLanguage]
                        .blood_testing_eligibility,
                    description:
                      language[this.props.defaultLanguage]
                        .personalized_feedback,
                    image: screened,
                  },
                ]}
                viewSize={1}
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="auth-form">
              {/* <LoginTabs active="admin" /> */}
              <div className="title">
                <h3
                  className="text-primary"
                  style={{ textTransform: "uppercase" }}
                >
                  {language[this.props.defaultLanguage].welcome_title}
                </h3>
                <p className="text-disabled">
                  {language[this.props.defaultLanguage].login_as_admin}
                </p>
              </div>
              <form>
                <Input
                  label={
                    language[this.props.defaultLanguage].email_address +
                    " / " +
                    language[this.props.defaultLanguage].badge
                  }
                  placeholder={
                    language[this.props.defaultLanguage].email_placeholder +
                    " / " +
                    language[this.props.defaultLanguage].badge
                  }
                  required
                  leftIcon={icons.user}
                  value={this.state.username}
                  error={this.state.error.username}
                  onChange={(e) => this.onChangeText("username", e)}
                />
                <Input
                  label={language[this.props.defaultLanguage].password}
                  placeholder={
                    language[this.props.defaultLanguage].password_placeholder
                  }
                  required
                  leftIcon={icons.password}
                  value={this.state.password}
                  error={this.state.error.password}
                  onChange={(e) => this.onChangeText("password", e)}
                  type="password"
                />
                {this.state.isTFAActive && (
                  <Input
                    label={language[this.props.defaultLanguage].tfa_code}
                    placeholder={
                      language[this.props.defaultLanguage].tfa_code_placeholder
                    }
                    required
                    leftIcon={icons.key}
                    value={this.state.tfaCode}
                    error={this.state.error.tfaCode}
                    onChange={(e) => this.onChangeText("tfaCode", e)}
                    type="number"
                    maxLength={6}
                  />
                )}
                <Button
                  text={language[this.props.defaultLanguage].login_text}
                  className="w-100"
                  isSubmitting={this.state.isSubmitting}
                  onPress={
                    this.state.isTFAActive
                      ? this.onVerifyTFA.bind(this)
                      : this.onSubmit.bind(this)
                  }
                />
                <br />
                {this.state.isTFAActive && (
                  <Button
                    text={
                      language[this.props.defaultLanguage].send_otp_to_email
                    }
                    className="w-100 btn-bordered btn-transparent"
                    isSubmitting={this.state.isLoginEmail}
                    onPress={this.handleLoginWithEmail.bind(this)}
                  />
                )}
                <hr />
                <div className="d-flex flex-column gap-3">
                  <Button
                    text={language[this.props.defaultLanguage].donor}
                    className="w-100 btn-default"
                    type="link"
                    to="/login"
                  />
                  <Button
                    text="BTD Staff "
                    className="w-100 btn-default"
                    type="link"
                    to="/admin-login"
                  />
                  <Button
                    text="Health Facility Staff"
                    className="w-100 btn-default"
                    type="link"
                    to="/admin-login"
                  />
                </div>
                <br />
                <hr />
                <div className="footer">
                  <span>
                    <Link to="/forgot-password/account">
                      {language[this.props.defaultLanguage].forgot_password}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AuthFormLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Login);
