import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import MyRequests from "../../../components/Requests/MyRequests";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import ReceptionSingle from "../../../components/Requests/bulk/receipion/ReceptionSingle";
import ReceptionBulk from "../../../components/Requests/bulk/receipion/ReceptionBulk";
import { Modal } from "../../../components/Modal";
import NominativeRequest from "../../../components/Requests/nominative/NominativeRequest";
import BulkRequest from "../../../components/Requests/bulk/BulkRequest";
import requestQuery from "../../../utils/queries/requestQuery";
import { Button } from "../../../components/Button";
import CheckboxQuestionnaire from "../../../components/Input/CheckboxQuestionnaire";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_REQUEST } from "../../../constants/ROLES";
import { Reception } from "../../../components/Reception";

class ReceptionManagementScreen extends React.Component {
  state = {
    user: {},
    data: [],
    type: "bulk",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        await this.returnFilters(search)
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    let tabOptions = [];
    let { canReceive, canReadReception } = accessMenu();

    if (canReceive) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].new_record,
        data: (
          <div className="card">
            <div className="card-body">
              <div className="col-md-6">
                <CheckboxQuestionnaire
                  answers={[
                    {
                      key: "type",
                      value: "bulk",
                      onPress: () => this.handleCheck("type", "bulk"),
                    },
                    {
                      key: "type",
                      value: "product",
                      onPress: () => this.handleCheck("type", "product"),
                    },
                  ]}
                  checked={this.state.type}
                />
                {this.state.type === "bulk" && (
                  <>
                    <ReceptionBulk />
                  </>
                )}
                {this.state.type === "product" && <ReceptionSingle />}
              </div>
            </div>
          </div>
        ),
      });
    }

    if (canReadReception) {
      tabOptions.push({
        title: language[this.props.defaultLanguage].historical_data,
        data: <Reception />,
      });
    }

    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div>
              <DashboardHeader
                title={language[this.props.defaultLanguage].reception}
              />
            </div>
            <Tabs options={tabOptions} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ReceptionManagementScreen));
