import React from "react";
import { connect } from "react-redux";

import { fetchInventories } from "../../../utils/queries/production/inventoryQuery";
import { getStorage } from "../../../utils/storage";
import "./styles.css";
import { Table } from "../../Table";
import language from "../../../language";

class ViewProducts extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    error: {},
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    this.getData(true, {});
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donation_number,
        key: "donation.donationNumber",
      },
      {
        title: language[this.props.defaultLanguage].product_type,
        key: "component.productType.name",
      },
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].product,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].concentration,
        key: "concentrationValue",
      },
      {
        title: language[this.props.defaultLanguage].phlebotomy_date,
        key: "donation.collectionDate",
        formatTime: "ll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].expired_date,
        key: "expiredDate",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].labelled_by,
        key: "verifiedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].stock,
        key: "stock.name",
      },

      {
        title: language[this.props.defaultLanguage].source,
        key: "sourceStock.name",
      },

      {
        title: language[this.props.defaultLanguage].reason,
        key: "reason",
      },
      {
        title: language[this.props.defaultLanguage].shipment_status,
        key: "shipmentInfo.status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].transferred_date,
        key: "shipmentInfo.date",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].transferred_by,
        key: "shipmentInfo.user.pin",
      },
      {
        title: language[this.props.defaultLanguage].reception,
        key: "receptionInfo.status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].reception_date,
        key: "receptionInfo.date",
        formatTime: "lll",
        isMoment: true,
      },
      {
        title: language[this.props.defaultLanguage].received_by,
        key: "receptionInfo.user.pin",
      },
      {
        title: language[this.props.defaultLanguage].inventory_status,
        key: "inventoryStatus",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;
      const { page, limit } = this.state;
      const user = await getStorage();

      this.setState({ isLoading });

      let params = {
        page,
        ...search,
        stock: user.stock,
        bloodGroup: this.props.bloodGroup,
        productType: this.props.productIds,
        limit: this.props.limit || limit,
        inventoryStatus: "INSTOCK",
        status: "labelled",
      };

      if (user.hospital) {
        params.countStatusParams = {
          "shipmentInfo.status": "transferred",
        };

        params.countStatusParams = {
          "receptionInfo.status": "received",
        };
      } else {
        params.countStatusParams = {
          "shipmentInfo.status": "pending",
        };
      }
      const { data, count } = await fetchInventories(defaultLanguage, params);

      this.setState({
        data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData(true, {
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true, { page });
      }
    );
  }

  render() {
    return (
      <>
        <Table
          isLoading={this.state.isLoading}
          search_text={this.state.search_text}
          addSearchButton
          searching={this.state.searching}
          handleSearch={this.handleSearch.bind(this)}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          isSearch
          data={this.state.data}
          headers={this.returnTableHeaders()}
          totalPageCount={this.state.totalPageCount}
          handlePagination={this.handlePagination.bind(this)}
          page={this.state.page}
          limit={this.state.limit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ViewProducts);
