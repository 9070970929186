import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import language from "../../language";
import { ROLE_PATIENT } from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchReturnedProducts = async (
  lng = "english",
  { page, limit, id, q, donationNumber }
) => {
  try {
    const user = await getStorage();

    const options = {
      method: "GET",
      url: `${API_URL}/return-product`,
      params: {
        page,
        limit,
        id,
        q,
        destination: user.stock,
        donationNumber,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};
