import toastMessage from "../toastMessage";
import formatSelectData from "../formatSelectData";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole, defineRoleCenterSite } from "../handleAccessRoles";
import { ROLE_HOSPITAL } from "../../constants/ROLES";
import api from "../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (lng = "english", params) => {
  let { page, limit, type, code, q, id, bloodBank, allowedBloodBank } =
    params || {};
  try {
    const user = await getStorage();
    const { hospitalRole } = await defineRoleCenterSite();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_HOSPITAL,
      operation: "read",
    });

    if (!canAccess) return;

    let body = {
      page: page,
      limit: limit,
      id: id || hospitalRole,
      code,
      q: q && q !== "" ? q : undefined,
      bloodBank,
      allowedBloodBank,
    };

    if (params.isDirectTransfer) {
      delete body.id;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/hospital`,
      params: body,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(
        queryRes.data.data || queryRes.data,
        "name",
        "_id"
      );
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    console.log(error);
    toastMessage("error", error);
  }
};

export default fetchData;

export const deleteHospital = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_HOSPITAL,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/hospital`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].success_delete_hospitals);
  } catch (error) {
    toastMessage("error", language[lng].error_delete_hospitals);
  }
};
