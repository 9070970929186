import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import MyRequests from "../../../components/Requests/MyRequests";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import { Modal } from "../../../components/Modal";
import NominativeRequest from "../../../components/Requests/nominative/NominativeRequest";
import BulkRequest from "../../../components/Requests/bulk/BulkRequest";
import requestQuery from "../../../utils/queries/requestQuery";
import { Button } from "../../../components/Button";
import { defineRole } from "../../../utils/handleAccessRoles";
import { ROLE_REQUEST } from "../../../constants/ROLES";
import { Tabs } from "../../../components/Tabs";
import SearchRequest from "../../../components/Requests/SearchRequest";

class RequestManagementScreen extends React.Component {
  state = {
    user: {},
    data: [],
    type: "bulk",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        await this.returnFilters(search)
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    let tabOptions = [];
    let { canReadBloodRequestAccess } = accessMenu();

    const canRequest = defineRole({
      roles: this.state.user.accessRole,
      menu: ROLE_REQUEST,
      operation: "create",
    });

    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            {this.state.user.hospital && canRequest && (
              <>
                <div className="d-flex gap-2 mt-2">
                  <Button
                    text={language[this.props.defaultLanguage].bulk_request}
                    className="btn btn-primary"
                    onPress={() => this.handleShowModal("showBulkRequest")}
                  />

                  <Button
                    text={
                      language[this.props.defaultLanguage].nominative_request
                    }
                    className=" btn-transparent btn-bordered primary"
                    onPress={() => this.handleShowModal("showNominativeModal")}
                  />
                </div>
                <hr />
              </>
            )}

            <div>
              <DashboardHeader
                title={language[this.props.defaultLanguage].requests}
              />
            </div>
            <>
              <Tabs
                options={[
                  {
                    title: language[this.props.defaultLanguage].search,
                    data: <SearchRequest />,
                  },
                  {
                    title: language[this.props.defaultLanguage].requests,
                    data: <MyRequests showAdd />,
                  },
                ]}
              />
              {/* <GuideFloatButton
              title="How to create a norminative request?"
              videoUrl="https://drive.google.com/file/d/1HUtXRxyestPy5urkABL9oZWN-LApjF3W/view?usp=sharing"
            /> */}
            </>
          </div>
        </div>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showNominativeModal")}
          show={this.state.showNominativeModal}
          title={language[this.props.defaultLanguage].nominative_request}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div>
            <NominativeRequest
              getData={this.getData.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showNominativeModal"
              )}
              {...this.state.selected_data}
            />
          </div>
        </Modal>

        <Modal
          handleClose={() => this.handleCloseModal("showBulkRequest")}
          show={this.state.showBulkRequest}
          title={language[this.props.defaultLanguage].bulk_request}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <div>
            <BulkRequest
              getData={this.getData.bind(this)}
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showBulkRequest"
              )}
              {...this.state.selected_data}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestManagementScreen));
