export default {
  home: "home",
  dashboard: "grid_view",
  request: "contract",
  service: "article",
  notification: "notifications",
  alert: "warning",
  department: "corporate_fare",
  level: "filter_list",
  users: "group",
  setting: "settings",
  request_service: "dvr",
  time_loading: "avg_pace",
  schedule: "schedule",
  menu: "menu",
  user: "person",
  password: "lock",
  eye_on: "visibility",
  eye_off: "visibility_off",
  identification: "badge",
  list: "article",
  email: "mail",
  phone: "phone",
  key: "key",
  search: "search",
  position: "person_apron",
  status: "sensors_krx",
  role: "settings_account_box",
  category: "category",
  description: "edit_note",
  type: "table_rows",
  institution: "corporate_fare",
  time: "schedule",
  delete: "delete",
  remove: "remove",
  add: "add",
  calendar: "calendar_month",
  close: "close",
  chevronDown: "expand_more",
  refresh: "refresh",
  arrowRightAlt: "arrow_right_alt",
  arrowLeft: "west",
  chevronRight: "chevron_right",
  chevronUp: "expand_less",
  close: "close",
  chat: "chat",
  pin_drop: "pin_drop",
  analytics: "health_metrics",
  reports: "export_notes",
  donors: "groups",
  requests: "upcoming",
  dispatch: "local_shipping",
  pickup: "local_shipping",
  warehouse: "warehouse",
  mobilize: "communication",
  hospitals: "local_hospital",
  alerts: "warning",
  feedback: "campaign",
  site: "holiday_village",
  address: "pin_drop",
  mapPin: "location_on",
  center: "home_health",
  reason: "edit_note",
  bloodtype: "bloodtype",
  success: "check",
  fail: "close",
  donation: "glucose",
  health: "monitor_heart",
  location: "location_on",
  refresh: "refresh",
  clinicalNote: "clinical_notes",
  clubs: "groups_2",
  questionnaire: "quiz",
  barcode: "barcode_scanner",
  edit: "edit",
  approve: "check",
  block: "block",
  export: "download",
  cancel: "close",
  caretUp: "arrow_drop_up",
  caretDown: "arrow_drop_down",
  production: "dataset",
  control_panel: "admin_panel_settings",
  blood_test: "labs",
  separation: "dataset",
  transformation: "autorenew",
  labeling: "barcode_scanner",
  package: "package_2",
  regular: "how_to_reg",
  irregular: "location_away",
  dropped: "person_off",
  processing: "account_tree",
  back: "arrowLeft",
  assessment: "help",
  vital_sign: "medical_information",
  reception: "receipt",
  return: "assignment_return",
  check: "check",
  preparation: "stacks",
  transfer: "local_shipping",
  help: "help",
  inventory: "warehouse",
  send: "send",
};
