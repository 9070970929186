import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { fetchPatients } from "../../../utils/queries/patient.js";
import { getStorage } from "../../../utils/storage.js";
import Profile from "../Profile.js";
import { Tabs } from "../../Tabs/index.js";
import LabResults from "../lab-results/index.js";

class PatientInfo extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, patientId } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchPatients(defaultLanguage, {
        page,
        limit,
        id: patientId,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle) {
    this.setState({
      [modal]: true,
      modalTitle,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <>
        <Profile patientId={this.props.patientId} />

        <Tabs
          options={[
            {
              title: language[this.props.defaultLanguage].lab_results,
              data: <LabResults patientId={this.props.patientId} />,
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(PatientInfo);
