import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { getStorage } from "../../../utils/storage.js";
import { fetchShippingInQueueData } from "../../../utils/queries/request/shipping-queue.js";
import { Empty } from "../../Empty/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import moment from "moment";
import Loading from "../../Loader/Loading.js";
import PrintComponent from "../../Print/PrintComponent.js";
import CardHeader from "../../Header/CardHeader.js";
import rbcLogo from "../../../assets/rbc-logo.png";
import "./styles.css";
import capitalizeText from "../../../utils/capitalizeText.js";
// import bootstrap icons

let copyData = [];
class ShippingQueue extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: false,
      data: [],
      totalPageCount: 0,
      page: 1,
      limit: 1000,
      search_text: "",
      selectedData: {},
      delete_value: "",
      error: {},
      csvData: [],
      user: {},
      shipmentNumber: "",
      addedBy: "",
      summary: [],
      isPrinting: false,
      recepientName: "",
    };
  }

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);
    console.log({ props: this.props });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.refreshShipmentNumber !== this.props.refreshShipmentNumber) {
      this.getData(true);
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e && e?.target ? e?.target?.value : e,
      error,
    });
  }

  hideEditIconPrint = () => {
    this.setState({ isPrinting: true }, () => {
      setTimeout(() => {
        this.setState({ isPrinting: false });
      }, 500);
    });
  };

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, shipmentNumber, addedBy, inventory } =
        this.props;

      if (
        (!shipmentNumber || shipmentNumber === "") &&
        (!inventory || inventory === "")
      )
        return;

      this.setState({ isLoading });

      const requestBody = {
        page,
        limit,
        addedBy,
        shipmentNumber,
        inventory,
      };

      const { data, count } = await fetchShippingInQueueData(
        defaultLanguage,
        requestBody
      );

      const results = this.formatDataForDisplay(this.formatShipmentData(data));

      if (results.length > 0) {
        this.setState({
          data: results,
          totalPageCount: count,
          isLoading: false,
          shipmentNumber: data[0].shipmentNumber,
          product: data[0].product,
          id: data[0]._id,
          requestNo: data[0].requestNo,
          addedBy: `${data[0].addedBy?.name} (${data[0].addedBy?.pin})`,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });

    if (selectedData?.donation) {
      this.setState({ donation: selectedData.donation });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "No",
        key: "number",
      },
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "Blood Group",
        key: "bloodGroup",
      },
      {
        title: "Qty",
        key: "quantity",
      },
      {
        title: "Expires on",
        key: "expiredDate",
      },
      {
        title: "Volume",
        key: "totalVolume",
      },
    ];

    return headers;
  }

  formatShipmentData(data) {
    const result = {};

    for (const { inventory, donation } of data) {
      const {
        name: product,
        bloodGroup,
        expiredDate,
        volume,
        code,
      } = inventory;
      const { donationNumber } = donation;

      // Initialize nested structure if missing using nullish coalescing (??)
      const donationEntry = (result[product + "(" + code + ")"] ??= {});

      const bloodEntry = (donationEntry[donationNumber] ??= {});

      const groupEntry = (bloodEntry[bloodGroup] ??= {
        totalVolume: 0,
        expiredDate,
        quantity: 0,
      });

      // Accumulate volume
      groupEntry.totalVolume += volume;
      groupEntry.quantity += 1;
    }

    return result;
  }

  formatDataForDisplay(summary) {
    const result = [];

    for (const product in summary) {
      const donations = [];
      let donationCount = 1;

      for (const donationNumber in summary[product]) {
        for (const bloodGroup in summary[product][donationNumber]) {
          const { totalVolume, expiredDate, quantity } =
            summary[product][donationNumber][bloodGroup];

          donations.push({
            number: donationCount,
            donationNumber,
            bloodGroup,
            totalVolume,
            expiredDate,
            quantity,
          });

          donationCount++;
        }
      }

      result.push({ product, donations });
    }

    return result;
  }

  reformatData(data) {
    const summary = {};

    // Aggregate data by product and blood group
    data.forEach(({ product, donations }) => {
      // Initialize product summary if it doesn't exist
      if (!summary[product]) {
        summary[product] = {};
      }

      donations.forEach(({ bloodGroup, totalVolume, quantity }) => {
        // Initialize blood group summary if it doesn't exist
        if (!summary[product][bloodGroup]) {
          summary[product][bloodGroup] = {
            bloodGroup,
            totalVolume: 0,
            totalQuantity: 0,
          };
        }

        // Update total volume and quantity for the blood group
        summary[product][bloodGroup].totalVolume += totalVolume;
        summary[product][bloodGroup].totalQuantity += quantity;
      });
    });

    // Convert the summary object into the desired array format
    const result = Object.entries(summary).flatMap(([product, groups]) =>
      Object.values(groups).map(
        ({ bloodGroup, totalVolume, totalQuantity }) => ({
          product,
          bloodGroup,
          totalVolume,
          totalQuantity,
        })
      )
    );

    return result;
  }

  showDispatchTime = () => {
    if (!this.state.distributor?.label) {
      return;
    }
    return moment().format("DD-MM-YYYY HH:mm");
  };

  renderContent = () => {
    return (
      <div className="printable-content">
        <div>
          <div className="card-header">
            <div className="shipping-slip-header">
              <div className="left">
                <div className="shipping-slip-logo">
                  <img src={rbcLogo} />
                </div>
                <div style={{ flex: 1 }}>
                  <p className="mb-2">
                    Shipment NO:{" "}
                    <span className="text-bold">
                      {this.props.shipmentNumber}
                    </span>
                  </p>
                  {this.state.requestNo && (
                    <p className="mb-2">
                      Request NO:{" "}
                      <span className="text-bold">{this.state.requestNo}</span>
                    </p>
                  )}
                  <p className="mb-2">
                    Status:{" "}
                    <span
                      className={`text-bold badge badge-${this.props.status}`}
                    >
                      {this.props.status && this.props.status !== ""
                        ? capitalizeText(this.props.status)
                        : ""}
                    </span>
                  </p>
                </div>
              </div>
              <div className="right">
                <div>
                  <p className="shipping-slip-name">BTD Rwanda, kigali</p>
                  <br />
                  <p className="mb-2">
                    Date:{" "}
                    <span className="text-bold">
                      {moment(this.props.createdAt).format("DD-MM-YYYY HH:mm")}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="mb-2">
                    Source:{" "}
                    <span
                      className="text-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {this.props.source || this.props.from?.name}
                    </span>
                  </p>
                  <p className="mb-2">
                    Destination:{" "}
                    <span
                      className="text-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {this.props.destination || this.props.to?.name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card-body"
            style={{ border: 0, marginBottom: "1rem" }}
          >
            {this.state.data.map((el, i) => {
              return (
                <div key={i}>
                  <CardHeader title={el.product} />
                  <Table
                    data={el.donations}
                    searching={this.state.searching}
                    isLoading={this.state.isLoading}
                    handlePagination={this.handlePagination.bind(this)}
                    headers={this.returnTableHeaders()}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="card-body"
            style={{ border: 0, marginBottom: "1rem" }}
          >
            <CardHeader title="Summary" />
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Blood Group</th>
                  <th>Qty</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                {this.reformatData(this.state.data)?.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-bold">{el.product}</td>
                      <td className="text-bold">{el.bloodGroup}</td>
                      <td className="text-bold">{el.totalQuantity}</td>
                      <td className="text-bold">{el.totalVolume}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer" style={{ display: "flex" }}>
          <div>
            <p className="text-bold mb-2">
              {language[this.props.defaultLanguage].product_verified_shipped_by}
            </p>
            {this.state.addedBy?._id ? (
              <>
                <p>
                  Name:{" "}
                  <span className="text-bold">{this.state.addedBy?.name}</span>{" "}
                </p>
                <p>
                  Date:{" "}
                  <span className="text-bold">{this.props.createdAt}</span>
                </p>
              </>
            ) : (
              <>
                <p>
                  Name: <span className="text-bold">{this.state.addedBy}</span>{" "}
                </p>
                <p>
                  Date:{" "}
                  {moment(this.props.createdAt).format("DD-MM-YYYY HH:mm")}
                </p>
              </>
            )}

            <div style={{ margin: "1rem 0" }}>
              <p>Signature: ................................</p>
            </div>
          </div>
          <div style={{ flex: 0.7 }} />
          <div>
            <p className="text-bold mb-2">Recipient</p>
            <p>
              Name:
              <span
                className="text-bold"
                style={{
                  marginLeft: 4,
                }}
              >
                {this.state.recepientName}
              </span>
            </p>
            <div>
              <p>
                Date & Time: <span>{this.showDispatchTime()}</span>
              </p>
            </div>
            <div style={{ margin: "1rem 0" }}>
              <p>Signature: ..............................................</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    {
      console.log(this.props);
    }
    if (this.state.isLoading)
      return (
        <center>
          <Loading />
        </center>
      );
    if (this.state.data.length === 0)
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    return (
      <div style={{ marginTop: "1rem" }}>
        {this.state.status === "transferred" ||
        this.props.status === "transferred" ? (
          <PrintComponent>{this.renderContent()}</PrintComponent>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { refreshShipmentNumber } = state.ShipmentNumber;
  return {
    defaultLanguage,
    refreshShipmentNumber,
  };
};

export default connect(mapStateToProps)(ShippingQueue);
