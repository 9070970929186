import React from "react";
import { connect } from "react-redux";
import { Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { activeOptions } from "../../constants/account";
import { getStorage, setStorage } from "../../utils/storage";
import hospitalQuery from "../../utils/queries/hospitalQuery";
import { defineRole } from "../../utils/handleAccessRoles";
import { ROLE_ACCOUNT } from "../../constants/ROLES";
import { fetchBloodBanks } from "../../utils/queries/bloodBank";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class StockAccess extends React.Component {
  state = {
    email: "",
    username: "",
    error: {},
    isSubmitting: false,
    departments: [],
    accessRoles: [],
    jobPositions: [],
    roles: [],
    status: activeOptions[0],
    showAccessRoles: false,
    password: "",
    name: "",
    pin: "",
    user: {},
    hospitals: [],
    phone: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });
    this.getHospitals(true);
    this.getBloodBanks(true);

    if (this.props._id) {
      this.setState({
        ...this.props,

        stock: this.props.stock
          ? {
              label: this.props.stock.name,
              value: this.props.stock._id,
            }
          : null,
      });
    }
  };

  getHospitals = async (isFetchingHospital, search = {}) => {
    try {
      this.setState({
        isFetchingHospital,
      });

      const data = await hospitalQuery(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
      });

      this.setState({
        hospitals: data,
        isFetchingHospital: false,
      });

      return data;
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingHospital: false });
    }
  };

  getBloodBanks = async (isFetchingBloodBanks, search = {}) => {
    try {
      this.setState({
        isFetchingBloodBanks,
      });

      const data = await fetchBloodBanks(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
      });

      this.setState({
        isFetchingBloodBanks: false,
        bloodBanks: data,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBloodBanks: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e?.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });
  };

  validateForm() {
    let { stock, error } = this.state;

    if (stock === "") {
      error.stock = language[this.props.defaultLanguage].stock_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { hospital, stock } = this.state,
        url = `${API_URL}/account/switch`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        newStock: stock?.value,
      };

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };

      axios(options)
        .then(async (data) => {
          this.setState({
            isSubmitting: false,
          });

          console.log(data);

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_account_success"
                : "add_account_success"
            ]
          );

          user.stock = data.data.stock;
          user.stockName = data.data.stockName;
          user.token = data.data.token;

          await setStorage(user);

          window.location.reload();
        })
        .catch((error) => {
          toastMessage("error", error);

          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                defaultOptions={this.state.bloodBanks}
                loadOptions={(e) => this.getBloodBanks(true, { q: e })}
                label={language[this.props.defaultLanguage].inventory}
                placeholder={language[this.props.defaultLanguage].select}
                value={this.state.stock}
                onChange={(e) => this.onChangeText("stock", e)}
                error={this.state.error.stock}
                isLoading={this.state.isFetchingStock}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          {defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_ACCOUNT,
            operation: "create,update",
          }) && (
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(StockAccess);
