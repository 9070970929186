import language from "../../../language";
import toastMessage from "../../toastMessage";
import api from "../../api";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchProductTypes = async (lng = "english", params) => {
  try {
    if (!params.q || params.q === "") {
      delete params.q;
    }
    const options = {
      method: "GET",
      url: `${API_URL}/product-type`,
      params,
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    if (params.inputType === "dropdown") {
      return (queryRes?.data?.data || queryRes?.data).map((el) => {
        return {
          ...el,
          label: el.code + " - " + el.name,
          value: el._id,
        };
      });
    }

    return !params.page && !params.limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const addProductType = async (data) => {
  try {
    let method = "POST";

    if (data.id) {
      method = "PUT";
    }
    const options = {
      method,
      url: `${API_URL}/product-type`,
      data,
    };

    const queryRes = await api(options);

    return queryRes.data;
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteProductType = async (lng = "english", params) => {
  try {
    const options = {
      method: "DELETE",
      url: `${API_URL}/product-type`,
      data: params,
    };

    await api(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    console.log(error);
    toastMessage("error", error);
  }
};
