import React from "react";
import { Button } from "../Button";
import CenterReceiver from "../Donors/Pre-assessment/CenterReceiver";
import language from "../../language";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import moment from "moment";
import formatSelectData from "../../utils/formatSelectData";
import donorQuery from "../../utils/queries/donorsQuery";
import icons from "../../constants/icons";
import Separator from "../Separator/Separator";
import {
  getCells,
  getDistricts,
  getProvinces,
  getSectors,
  getVillages,
} from "../../utils/rwanda";
import CheckboxQuestionnaire from "../Input/CheckboxQuestionnaire";
import { generateRandomNumber, generateRandomString } from "../../utils/random";
class BookAppointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionnaires: {},
      error: {},
      requestedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      status: "pending",
      donor: props.donorId,
      appointmentCategory: "",
      appointmentNumber:
        new Date().getTime() +
        "-" +
        generateRandomString(6).toUpperCase() +
        "-" +
        generateRandomNumber(0, 10000),
      donorInfo: {},
    };
  }

  componentDidMount = async () => {
    let { error, donor } = this.state;

    this.getDonor();
    this.defaultCategory();

    if (this.props._id) {
      let province, district, cell, village, sector;

      this.props.healthQuestionnaire.forEach((el) => {
        if (el.answerType === "province") {
          province = {
            label: el.answer,
            value: el.answer,
          };
        }

        if (el.answerType === "district") {
          district = {
            label: el.answer,
            value: el.answer,
          };
        }

        if (el.answerType === "sector") {
          sector = {
            label: el.answer,
            value: el.answer,
          };
        }

        if (el.answerType === "cell") {
          cell = {
            label: el.answer,
            value: el.answer,
          };
        }

        if (el.answerType === "village") {
          village = {
            label: el.answer,
            value: el.answer,
          };
        }
      });

      await this.setState({
        ...this.props,
        center: formatSelectData(this.props.center, "name", "_id"),
        centerSite: formatSelectData(this.props.centerSite, "name", "_id"),
        requestedDate: moment(this.props.requestedDate).format(
          "YYYY-MM-DD HH:mm"
        ),
        status: this.props.status,
        donor: this.props.donor ? this.props.donor._id : donor,
        province,
        district,
        cell,
        sector,
        village,
        appointmentCategory: this.props.category,
      });
    }

    if (!this.state.donor) {
      error.errorMessage = language[this.props.defaultLanguage].donor_required;
    } else {
      delete error.errorMessage;
    }

    this.setState({ error });
  };

  defaultCategory = async () => {
    const user = await getStorage();

    let appointmentCategory = "walkInDonor";

    if (user.role === "donor") {
      appointmentCategory = "onlineRequest";
    }
    this.setState({ appointmentCategory });
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];
    delete error.errorMessage;

    let value = e && e?.target ? e?.target?.value : e;

    this.setState({ error, [field]: value });
  }

  validateForm() {
    let {
      centerSite,
      center,
      error,
      requestedDate,
      donor,
      province,
      district,
      sector,
      cell,
      village,
      appointmentCategory,
    } = this.state;

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!centerSite) {
      error.centerSite =
        language[this.props.defaultLanguage].center_site_required;
    }

    if (!requestedDate) {
      error.requestedDate = language[this.props.defaultLanguage].date_required;
    }

    if (!donor) {
      error.errorMessage = language[this.props.defaultLanguage].donor_required;
    }

    if (!province) {
      error.province = language[this.props.defaultLanguage].province_required;
    }

    if (!district) {
      error.district = language[this.props.defaultLanguage].district_required;
    }

    if (!sector) {
      error.sector = language[this.props.defaultLanguage].sector_required;
    }

    if (!village) {
      error.village = language[this.props.defaultLanguage].village_required;
    }

    if (!cell) {
      error.cell = language[this.props.defaultLanguage].cell_required;
    }

    if (!appointmentCategory || appointmentCategory === "") {
      error.cell =
        language[this.props.defaultLanguage].appointment_category_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let {
          centerSite,
          center,
          requestedDate,
          _id,
          province,
          district,
          cell,
          village,
          sector,
          healthQuestionnaire,
          comment,
          appointmentCategory,
          appointmentNumber,
          donorInfo,
        } = this.state,
        url = `${process.env.REACT_APP_BBMS_BASE_API}/preDonationAssessment`,
        method = "POST",
        user = await getStorage();

      if (_id) {
        method = "PUT";
      }

      let modifiedQuestions = [];

      if (!healthQuestionnaire) {
        modifiedQuestions = [
          {
            questionText: "Province",
            answerType: "province",
            answer: province.value,
          },
          {
            questionText: "District",
            answerType: "district",
            answer: district.value,
          },
          {
            questionText: "Sector",
            answerType: "sector",
            answer: sector.value,
          },
          {
            questionText: "Cell",
            answerType: "cell",
            answer: cell.value,
          },
          {
            questionText: "Village",
            answerType: "village",
            answer: village.value,
          },
        ];
      } else {
        modifiedQuestions = healthQuestionnaire?.map((el) => {
          if (el.answerType === "province") {
            el.answer = province.value;
          }

          if (el.answerType === "district") {
            el.answer = district.value;
          }

          if (el.answerType === "sector") {
            el.answer = sector.value;
          }

          if (el.answerType === "cell") {
            el.answer = cell.value;
          }

          if (el.answerType === "village") {
            el.answer = village.value;
          }

          return el;
        });
      }

      const options = {
        method,
        url,
        data: {
          center: center.value,
          centerSite: centerSite.value,
          requestedDate,
          donor: this.props.donorId || this.state.donor,
          id: _id,
          status: this.state.status,
          healthQuestionnaire: modifiedQuestions,
          comment,
          category: appointmentCategory,
          appointmentNumber,
          status: user.role === "donor" ? "pending" : "approved",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then(async (data) => {
          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              _id ? "update_data_success" : "add_data_success"
            ]
          );

          // const donorInfo = await donorQuery(this.props.defaultLanguage, {
          //   id: data.data.donor,
          // });

          this.props.handleCloseModal && this.props.handleCloseModal();
          this.props.getData && this.props.getData(true);

          this.setState({
            isSubmitting: false,
          });

          if (donorInfo._id && this.props.navigationUrl) {
            return (window.location.href = `${this.props.navigationUrl}/appointments`);
          } else if (donorInfo._id && this.props.navigationUrl) {
            window.location.href = `${this.props.navigationUrl}/donors/questionnaire/${donorInfo._id}/${donorInfo.donorNumber}/${donorInfo.firstName} ${donorInfo.lastName}/${data.data._id}/${data.data.requestedDate}`;
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  handleDonorSearch = async (isSearching) => {
    let { error } = this.state;

    if (this.state.donorNumber === "") {
      error.donorNumber =
        language[this.props.defaultLanguage].donor_number_required;

      this.setState({ error });

      return;
    }

    try {
      if (Object.keys(this.state.error).length > 0) return;

      this.setState({
        isSearching: true,
      });

      const data = await donorQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donorNumber: this.state.donorNumber,
      });

      if (data.length === 0) {
        error.errorMessage = language[this.props.defaultLanguage].not_found;

        this.setState({
          isSearching: false,
          error,
        });

        return;
      }

      this.setState({
        donor: data[0]._id,
        isSearching: false,
        error,
      });
    } catch (error) {
      this.setState({ isSearching: false });
    }
  };

  handleCheck(field, v) {
    this.setState({
      [field]: v,
    });
  }

  getDonor = async () => {
    const { donorId } = this.props;

    if (!donorId) return;

    try {
      const donorInfo = await donorQuery(this.props.defaultLanguage, {
        id: donorId,
      });

      const formatDonorInfo = donorInfo?.length > 0 ? donorInfo[0] : {};

      console.log("====================================");
      console.log("formatDonorInfo", formatDonorInfo);
      console.log("====================================");

      this.setState({
        donorInfo: donorInfo?.length > 0 ? donorInfo[0] : {},
        province: formatDonorInfo?.province
          ? {
              label: formatDonorInfo?.province,
            }
          : this.state.province,
        district: formatDonorInfo?.district
          ? {
              label: formatDonorInfo?.district,
            }
          : this.state.district,
        sector: formatDonorInfo?.sector
          ? {
              label: formatDonorInfo?.sector,
            }
          : this.state.sector,
        cell: formatDonorInfo?.cell
          ? {
              label: formatDonorInfo?.cell,
            }
          : this.state.cell,
        village: formatDonorInfo?.village
          ? {
              label: formatDonorInfo?.village,
            }
          : this.state.village,

        center: formatDonorInfo.center
          ? {
              label:
                formatDonorInfo?.center?.code +
                " - " +
                formatDonorInfo?.center?.name,
              value: formatDonorInfo?.center?._id,
            }
          : null,
        centerSite: formatDonorInfo.centerSite
          ? {
              label:
                formatDonorInfo?.centerSite?.code +
                " - " +
                formatDonorInfo?.centerSite?.name,
              value: formatDonorInfo?.centerSite?._id,
            }
          : null,
      });
    } catch (error) {
      toastMessage("error", error);
    }
  };

  render() {
    return (
      <>
        <div className="card-body">
          {this.state.error.errorMessage && (
            <div className="alert alert-danger">
              <span>{this.state.error.errorMessage}</span>
            </div>
          )}
          <Input
            label={language[this.props.defaultLanguage].appointment_number}
            placeholder={
              language[this.props.defaultLanguage]
                .appointment_number_placeholder
            }
            value={this.state.appointmentNumber}
            onChange={(e) => this.onChangeText("appointmentNumber", e)}
            error={this.state.error.appointmentNumber}
          />
          <CheckboxQuestionnaire
            required
            questionText={language[this.props.defaultLanguage].category}
            answers={[
              {
                key: "onlineRequest",
                value: language[this.props.defaultLanguage].online_request,
                onPress: () =>
                  this.handleCheck("appointmentCategory", "onlineRequest"),
              },
              {
                key: "walkInDonor",
                value: language[this.props.defaultLanguage].walk_in_donor,
                onPress: () =>
                  this.handleCheck("appointmentCategory", "walkInDonor"),
              },
              {
                key: "calledToCameAndDonate",
                value:
                  language[this.props.defaultLanguage]
                    .called_to_came_and_donate,
                onPress: () =>
                  this.handleCheck(
                    "appointmentCategory",
                    "calledToCameAndDonate"
                  ),
              },
            ]}
            checked={this.state.appointmentCategory}
            error={this.state.error.appointmentCategory}
          />
          {!this.state.donor && (
            <Input
              label={language[this.props.defaultLanguage].donor_number}
              placeholder={
                language[this.props.defaultLanguage].donor_number_placeholder
              }
              value={this.state.donorNumber}
              onChange={(e) => this.onChangeText("donorNumber", e)}
              error={this.state.error.donorNumber}
              rightButtonIcon={icons.search}
              rightButtonSubmitting={this.state.isSearching}
              rightButtonPressed={this.handleDonorSearch.bind(this)}
            />
          )}
          <CenterReceiver
            center={this.state.center}
            centerSite={this.state.centerSite}
            onChangeText={this.onChangeText.bind(this)}
            error={this.state.error}
            size={6}
          />
          <hr />
          <Separator
            title={language[this.props.defaultLanguage].donor_current_address}
          />
          <div className="row">
            <div className="col-md-6">
              <Select
                options={getProvinces(this.state.province)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].province}
                required
                value={this.state.province}
                onChange={(e) => this.onChangeText("province", e)}
                error={this.state.error.province}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getDistricts(this.state.province?.value)}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].district}
                required
                value={this.state.district}
                onChange={(e) => this.onChangeText("district", e)}
                error={this.state.error.district}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getSectors(
                  this.state.province?.value,
                  this.state.district?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].sector}
                required
                value={this.state.sector}
                onChange={(e) => this.onChangeText("sector", e)}
                error={this.state.error.sector}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getCells(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].cell}
                required
                value={this.state.cell}
                onChange={(e) => this.onChangeText("cell", e)}
                error={this.state.error.cell}
              />
            </div>
            <div className="col-md-6">
              <Select
                options={getVillages(
                  this.state.province?.value,
                  this.state.district?.value,
                  this.state.sector?.value,
                  this.state.cell?.value
                )}
                placeholder={language[this.props.defaultLanguage].select}
                label={language[this.props.defaultLanguage].village}
                required
                value={this.state.village}
                onChange={(e) => this.onChangeText("village", e)}
                error={this.state.error.village}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <Input
                label={language[this.props.defaultLanguage].request_date}
                onChange={(e) => this.onChangeText("requestedDate", e)}
                error={this.state.error.requestedDate}
                value={moment(this.state.requestedDate).format(
                  "YYYY-MM-DD HH:mm"
                )}
                type="datetime-local"
                min={moment().format("YYYY-MM-DD HH:mm")}
                // max={moment().format("YYYY-MM-DD HH:mm")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                label={language[this.props.defaultLanguage].comment}
                onChange={(e) => this.onChangeText("comment", e)}
                error={this.state.error.comment}
                value={this.state.comment}
                textarea
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            text={language[this.props.defaultLanguage].submit}
            isSubmitting={this.state.isSubmitting}
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(BookAppointment);
