import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
import ReturnProduct from "../../../components/return/Return";
import HistoricalData from "../../../components/return/HistoricalData";
import requestQuery from "../../../utils/queries/requestQuery";

class ReturnManagementScreen extends React.Component {
  state = {
    user: {},
    data: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  handleShowModal(modal, modalTitle, selected_data = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_data,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await requestQuery(
        defaultLanguage,
        await this.returnFilters(search)
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleCheck(type, value) {
    this.setState({ [type]: value });
  }

  render() {
    let tabOptions = [];
    let { canPostReturn } = accessMenu();

    if (canPostReturn) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].new_record,
          data: (
            <div className="card">
              <div className="card-body">
                <div className="col-md-8">
                  <ReturnProduct />
                </div>
              </div>
            </div>
          ),
        },
        {
          title: language[this.props.defaultLanguage].historical_data,
          data: (
            <div className="card">
              <div className="card-body">
                <div className="col-md-12">
                  <HistoricalData />
                </div>
              </div>
            </div>
          ),
        }
      );
    }

    return (
      <>
        <div className="card-dashboard-content">
          <div className="card-body">
            <div>
              <DashboardHeader
                title={language[this.props.defaultLanguage].return_product}
              />
            </div>
            <Tabs options={tabOptions} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(ReturnManagementScreen));
