import React from "react";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import language from "../../language";
import "./styles.css";
import { Button } from "../Button";

class PrintComponent extends React.PureComponent {
  handleButtonClick = async (event) => {
    event.preventDefault(); // Prevent default behavior of anchor tag

    if (this.props.handlePrint) {
      // If an onButtonClick prop is passed, call it
      await this.props.handlePrint();
      this.printTriggerRef.click();
    } else {
      // If no onButtonClick prop, trigger the print action
      this.printTriggerRef.click();
    }
  };

  render() {
    return (
      <div
        className={
          this.props.orientation === "landscape"
            ? "landscape-content"
            : "portrait-content"
        }
      >
        {this.props.header && <>{this.props.header}</>}
        <ReactToPrint
          trigger={() => {
            // Store the actual print trigger in a reference to use programmatically
            return (
              <div
                ref={(el) => (this.printTriggerRef = el)}
                style={{ display: "none" }} // Hide the default trigger
              />
            );
          }}
          content={() => this.componentRef}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          {this.props.actionButtons}
          <Button
            className="btn btn-primary"
            onPress={this.handleButtonClick}
            isSubmitting={this.props.isPrinting}
            text={
              this.props.buttonText ||
              language[this.props.defaultLanguage].print_this_out
            }
          />
        </div>
        <div
          ref={(el) => (this.componentRef = el)}
          style={{ marginRight: "1rem" }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;

  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  React.forwardRef((props, ref) => {
    return <PrintComponent {...props} ref={ref} />;
  })
);
