import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import { ROLE_SAMPLE_COLLECTED_RESULT } from "../../constants/ROLES";
import { defineRole } from "../handleAccessRoles";
import api from "../api";
import language from "../../language";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchSampleCollectedResult = async (
  lng = "english",
  { page, limit, request, type }
) => {
  try {
    const user = await getStorage();

    // const canAccess = defineRole({
    //   roles: user.accessRole,
    //   menu: ROLE_SAMPLE_COLLECTED_RESULT,
    //   operation: "read",
    // });

    // if (!canAccess)
    //   return toastMessage(
    //     "error",
    //     "You don't have permission to read sample lab result, if persist contact your administrator"
    //   );

    const options = {
      method: "GET",
      url: `${API_URL}/sample-collected-result`,
      params: {
        page: page,
        limit: limit,
        request,
        type,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await api(options);

    let { data, count } = queryRes.data;

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteSampleResult = async (lng = "english", params) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_SAMPLE_COLLECTED_RESULT,
      operation: "delete",
    });

    if (!canAccess) return;

    const options = {
      method: "DELETE",
      url: `${API_URL}/sample-collected-result`,
      data: params,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await api(options);

    toastMessage("success", language[lng].data_deleted_success);
  } catch (error) {
    toastMessage("error", error);
  }
};
