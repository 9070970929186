import React from "react";
import { connect } from "react-redux";
import { fetchShippingInQueueData } from "../../utils/queries/request/shipping-queue";
import toastMessage from "../../utils/toastMessage";
import language from "../../language";
import { Table } from "../Table";

class ShipmentInformation extends React.Component {
  state = {
    page: 1,
    limit: 10,
    data: [],
  };

  componentDidMount() {
    if (this.props.inventory) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, inventory } = this.props;

      if (!inventory || inventory === "") return;

      this.setState({ isLoading });

      const requestBody = {
        page,
        limit,
        inventory,
      };

      const { data, count } = await fetchShippingInQueueData(
        defaultLanguage,
        requestBody
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].shipment_number,
        key: "shipmentNumber",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].from,
        key: "from.name",
      },
      {
        title: language[this.props.defaultLanguage].to,
        key: "to.name",
      },
      {
        title: language[this.props.defaultLanguage].shipped_by,
        key: "addedBy.pin",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].status,
        type: "status",
        key: "status",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }
  render() {
    console.log(this.state.data);
    return (
      <div className="card-body">
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ShipmentInformation);
