import React, { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import { clearStorage, getStorage } from "../utils/storage";

const InactivityTracker = () => {
  const [open, setOpen] = useState(false); // Modal visibility
  const timeoutDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  let timeoutId;

  const resetTimer = () => {
    if (!open) {
      // Reset the timer only if the modal is not open
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (!open) {
          requestRenewSession();
        }

        setOpen(true); // Show modal after inactivity
      }, timeoutDuration);
    }
  };

  const handleActivity = () => {
    if (!open) {
      // Only reset timer if the modal is not open
      resetTimer();
    }
  };

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);

    // Initialize the inactivity timer
    resetTimer();

    return () => {
      // Cleanup event listeners and timer on component unmount
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
    };
  }, []);

  const onLogout = async () => {
    const user = await getStorage();

    if (user?.id) {
      await clearStorage();

      window.location.href = user.role === "donor" ? "/login" : "/admin-login";
    }
  };

  const requestRenewSession = async () => {
    onLogout();
  };

  return <div></div>;
};

export default InactivityTracker;
