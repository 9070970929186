import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { getStorage } from "../../../utils/storage";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodCenterQuery from "../../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../../utils/queries/bloodCenterSiteQuery";
import moment from "moment";
import { Button } from "../../Button";
import { Input, Select } from "../../Input";
import Summary from "./Summary";

class GenerateSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: {},
      data: [],
      startCollectionDate:
        props.startCollectionDate || moment().startOf("D").format("YYYY-MM-DD"),
      endCollectionDate:
        props.endCollectionDate || moment().endOf("D").format("YYYY-MM-DD"),
      centers: {},
      centerSites: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.startCollectionDate) {
      this.setState({ startCollectionDate: this.props.startCollectionDate });
    }

    if (this.props.endCollectionDate) {
      this.setState({ endCollectionDate: this.props.endCollectionDate });
    }

    if (!this.props.center) {
      await this.getCenters(true);
    }

    if (this.props.center && this.props.centerSite) {
      this.handleGenerateReport(true);
    }
  };

  getCenters = async (isFetchingCenter, search = {}) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        search,
      });

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });
    }
  };

  getCenterSites = async (isFetchingCenterSite, search = {}) => {
    try {
      this.setState({
        isFetchingCenterSite,
        centerSite: null,
        centerSites: [],
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        type: "dropdown",
        ...search,
      });

      this.setState({
        centerSites: data,
        isFetchingCenterSite: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenterSite: false });
    }
  };

  onChangeText = (field, e) => {
    const value = e?.target ? e?.target?.value : e;
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: value,
    });

    if (field === "center") {
      this.getCenterSites(true, { center: value?.value });
    }
  };

  handleGenerateReport = async (isLoading) => {
    try {
      const user = await getStorage();

      this.setState({ isLoading });

      const options = {
        url: process.env.REACT_APP_BBMS_BASE_API + "/bloodDonationReport",
        method: "post",
        data: {
          startCollectionDate: this.state.startCollectionDate,
          endCollectionDate: this.state.endCollectionDate,
          center: this.state?.center?.value || this.props.center,
          centerSite: this.state?.centerSite?.value || this.props.centerSite,
          transportationTemparature: this.state.transportationTemparature,
          driver: this.state.driver,
          plateNumber: this.state?.plateNumber?.toUpperCase(),
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios(options);

      if (data.total > 0) {
        this.setState({
          transportationTemparature: data.transportationTemparature,
          driver: data.driver,
          plateNumber: data.plateNumber,
        });
      }

      this.setState({
        data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-4 mb-3">
          <div
            className="card-body"
            style={{
              backgroundColor: "#f2f2f2",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <Input
                  label={
                    language[this.props.defaultLanguage].start_collection_date
                  }
                  required
                  value={this.state.startCollectionDate}
                  onChange={(e) => this.onChangeText("startCollectionDate", e)}
                  error={this.state.error.startCollectionDate}
                  type="date"
                />
              </div>
              <div className="col-md-12">
                <Input
                  label={
                    language[this.props.defaultLanguage].end_collection_date
                  }
                  required
                  value={this.state.endCollectionDate}
                  onChange={(e) => this.onChangeText("endCollectionDate", e)}
                  error={this.state.error.endCollectionDate}
                  type="date"
                />
              </div>
              {!this.props.center && (
                <div className="col-md-12">
                  <Select
                    defaultOptions={this.state.centers}
                    loadOptions={(e) => this.getCenters(true, { name: e })}
                    label={language[this.props.defaultLanguage].center}
                    value={this.state.center}
                    onChange={(e) => this.onChangeText("center", e)}
                    error={this.state.error.center}
                    isLoading={this.state.isFetchingCenter}
                  />
                </div>
              )}
              {!this.props.centerSite && (
                <div className="col-md-12">
                  <Select
                    defaultOptions={this.state.centerSites}
                    loadOptions={(e) =>
                      this.getCenterSites(true, {
                        center: this.state?.center?.value,
                        q: e,
                      })
                    }
                    label={language[this.props.defaultLanguage].center_site}
                    value={this.state.centerSite}
                    onChange={(e) => this.onChangeText("centerSite", e)}
                    error={this.state.error.centerSite}
                    isLoading={this.state.isFetchingCenterSite}
                  />
                </div>
              )}
              {this.state.data.total > 0 && (
                <>
                  <div className="col-md-12">
                    <Input
                      label={
                        language[this.props.defaultLanguage]
                          .transporation_temperature
                      }
                      value={this.state.transportationTemparature}
                      onChange={(e) =>
                        this.onChangeText("transportationTemparature", e)
                      }
                      error={this.state.error.transportationTemparature}
                      type="number"
                      disabled={this.state.data.status !== "pending"}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      label={language[this.props.defaultLanguage].driver}
                      value={this.state.driver}
                      onChange={(e) => this.onChangeText("driver", e)}
                      error={this.state.error.driver}
                      disabled={this.state.data.status !== "pending"}
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      label={language[this.props.defaultLanguage].plate_number}
                      value={this.state.plateNumber}
                      onChange={(e) => this.onChangeText("plateNumber", e)}
                      error={this.state.error.plateNumber}
                      inputStyles={{ textTransform: "uppercase" }}
                      disabled={this.state.data.status !== "pending"}
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <Button
                text={
                  this.state.data.total > 0
                    ? language[this.props.defaultLanguage].submit
                    : language[this.props.defaultLanguage].search
                }
                onPress={this.handleGenerateReport.bind(this)}
                isSubmitting={this.state.isLoading}
              />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          {(this.state?.center?.value || this.props.center) &&
            (this.state.centerSite?.value || this.props.centerSite) && (
              <div
                style={{
                  height: "100vh",
                  overflowY: "auto",
                }}
              >
                <br />
                <Summary
                  data={this.state.data}
                  isLoading={this.state.isLoading}
                  centerSite={
                    this.state?.centerSite?.value || this.props.centerSite
                  }
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(GenerateSummary);
