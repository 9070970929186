import React from "react";

import { withRouter } from "../../../utils/withRouter";
import MFAVerify from "../../../components/AdminLogin/MFAVerify";

class MFAVerifyScreen extends React.Component {
  render() {
    return (
      <>
        <MFAVerify {...this.props.routeParams} />
      </>
    );
  }
}

export default withRouter(MFAVerifyScreen);
